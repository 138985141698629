import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { GridOptions } from "ag-grid-community/main";
import { DataService } from './../data.service';

@Component({
  selector: 'app-discount',
  templateUrl: './discount-component.component.html',
  styleUrls: ['./discount-component.component.css']
})
export class DiscountComponentComponent implements OnInit {
    branch_weight: string;
    branch_currency: string;
    numSelRows: number = 0;
    disc: { phone: string, email: string, code: string };
    @Output() onDiscount = new EventEmitter<number>();
    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    safariPattern = '\\d*';

  private gridDiscOptions: GridOptions;
  public rowDiscData: any[];
  private columnDiscDefs: any[];
    public defaultColDef;
  private gridDiscApi;
  private gridDiscColumnApi;

  constructor(private dataService: DataService) {
    this.gridDiscOptions = <GridOptions>{
    };

    this.columnDiscDefs = [
      { headerName: "Sender Name", field: "SenderName" },
      { headerName: "Discount Name", field: "DiscountName" },
      { headerName: "Discount Type", field: "DiscountType" },
      { headerName: "Delivery Time", field: "DeliveryTime" },
      {
        headerName: "Discount Value", field: "DiscountValue",
        valueGetter: (params) => {
          var unit = params.data.DiscountType == 'WEIGHT' ? this.branch_weight : this.branch_currency;
          return params.data.DiscountValue + " " + unit;
        }
      },
      { headerName: "Discount Code", field: "DiscountCode" },
    ];

      this.defaultColDef = {
          resizable: true
      };

    this.rowDiscData = [];
  }

  ngOnInit() {
    this.branch_currency = this.dataService.userData.Branch.currency;
    this.branch_weight = this.dataService.userData.Branch.weight;

    this.disc = { phone: '', email: '', code: '' };
  }

  onGridDiscReady(params) {
    this.gridDiscApi = params.api;
    this.gridDiscColumnApi = params.columnApi;
  }

  autoSizeAll(colapi: any) {
    var allColumnIds = [];
    colapi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId);
    });
    colapi.autoSizeColumns(allColumnIds);
  }

  onSelectionChanged(event) {
    this.numSelRows = event.api.getSelectedNodes().length;
  }

  onRowDataChanged(params) {
    this.autoSizeAll(params.columnApi)
  }

  search() {
    this.dataService.getDiscounts(this.disc.phone, this.disc.email, this.disc.code).subscribe((ui: any[]) => {
      this.rowDiscData = ui;
      this.gridDiscApi.setRowData(this.rowDiscData);
    });
  }

  setDiscount() {
    var id = this.gridDiscApi.getSelectedRows()[0].id;
    this.onDiscount.emit(id);
  }
}
