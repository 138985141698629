import { Component, Input, Output, EventEmitter, OnInit, AfterViewChecked, OnChanges, SimpleChange, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { QuestionBase, TextboxQuestion } from './../question-base';
import { QuestionControlService } from './../question-control.service';
import { DynamicFormQuestionComponent } from './dynamic-form-question.component';

@Component({
    selector: 'app-dynamic-form',
    providers: [QuestionControlService],
  template: `
      <div class="form-height">
        <form *ngIf="questions" [ngClass]="{'form-horizontal': horizontal}" (ngSubmit)="onSubmit()" [formGroup]="form">

          <div [ngClass]="classLeft">
            <div *ngFor="let question of questions" [id]="'q-container-'+question.key">
              <app-question *ngIf="question.active && question.controlType != 'calendar'" #qList [question]="question" [form]="form" [horizontal]="horizontal" [noGroup]="noGroup" (oncheckChange)="checkChanged($event)" (oninputChange)="oninputChange.emit($event)"></app-question>
            </div>
 
            <div class="col-sm-12">
              <button class="btn btn-primary" [ngClass]="txtOKClass" type="submit" [disabled]="!form.valid || butDisabled">{{txtOK}}</button>
              <button *ngIf="txtButton" class="btn btn-primary" type="button" (click)="onButtonClicked.emit()">{{txtButton}}</button>
              <button *ngIf="txtCancel" class="btn" type="button" attr.data-cancelbut="{{txtCancel}}" (click)="onSubmitted.emit('')">{{txtCancel}}</button>

              <div class="pull-right">
                <button *ngFor="let but of auxButtons; index as ix" class="btn btn-primary" [ngClass]="but.class ? but.class : ''" type="button" style="margin-left:8px" (click)="onAuxButtonClicked.emit(ix)">{{but.text}}</button>
              </div>
            </div>
          </div>

          <div *ngIf="questionsCol2" [ngClass]="classRight">
            <div *ngFor="let question of questionsCol2">
              <app-question *ngIf="question.active" #qList2 [question]="question" [form]="form" [horizontal]="horizontal" [noGroup]="noGroup" (oncheckChange)="checkChanged($event)" (oninputChange)="oninputChange.emit($event)"></app-question>
            </div>
          </div>
        </form>
 
 <!--       <div *ngIf="payLoad" class="form-row">
          <strong>Saved the following values</strong><br>{{payLoad}}
        </div>  -->
      </div>
  `,
  styles: [`
      .btn { margin-right: 7px }
      .form-height { overflow-y: auto }
      .m00 { margin-right: 0; margin-left: 0; padding-left: 0 }
  `]
})
export class DynamicFormComponent implements OnInit, AfterViewChecked {

    @Input() questions: QuestionBase<any>[] = [];
    @Input() questionsCol2: QuestionBase<any>[];
    @Input() col2Width: number = 0;
    @Input() horizontal: boolean = false;
    @Input() noGroup: boolean = false;
    form: FormGroup;
    payLoad = '';
    butDisabled: boolean = false;
    classLeft: string;
    classRight: string;
    @Input() txtOK = 'OK';
    @Input() txtOKClass = '';
    @Input() txtCancel = 'Cancel';
    @Input() txtButton: string = null;
    @Input() auxButtons: any[];
    @Output() onInited = new EventEmitter<string>();
    @Output() onSubmitted = new EventEmitter<string>();
    @Output() onButtonClicked = new EventEmitter();
    @Output() onAuxButtonClicked = new EventEmitter<number>();
    @Output() onCheckChange = new EventEmitter<any>();
    @Output() oninputChange = new EventEmitter<any>();
    changesDetected: boolean = false;

    @ViewChildren('qList') private qList: QueryList<DynamicFormQuestionComponent>;
    @ViewChildren('qList2') private qList2: QueryList<DynamicFormQuestionComponent>;

    constructor(private qcs: QuestionControlService, private ref: ChangeDetectorRef) { }

    ngOnInit() {
        if (!this.auxButtons) this.auxButtons = [];
        if (!this.txtOKClass) this.txtOKClass = '';
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        let changedProp = changes['questions'];
        this.questions = changedProp.currentValue;
        if (this.questions) {
            this.changesDetected = true;
            this.form = this.qcs.toFormGroup(this.questions);
            this.classLeft = '';
            this.classRight = '';
            if (this.noGroup)
                this.classLeft = 'form-group form-height';

            if (this.questionsCol2) {
                this.qcs.addFormGroup(this.questionsCol2, this.form);

                this.classLeft += ' m00';
                if (this.noGroup)
                    this.classRight = 'form-group form-height';
                this.classRight += ' m00';

                if (this.col2Width && this.col2Width > 0 && this.col2Width < 12) {
                    var lw = 12 - this.col2Width;
                    this.classLeft += ` col-sm-${lw}`;
                    this.classRight += ` col-sm-${this.col2Width}`;
                }
                else {
                    this.classLeft += ' col-sm-6';
                    this.classRight += ' col-sm-6';
                }
            }
        }
    }

    ngAfterViewChecked() {
      if (!this.changesDetected)
        return;

      this.changesDetected = false;

        if (this.questions && this.questions.length > 0) {
            this.ref.detectChanges();

            setTimeout(() => {
                this.onInited.emit(this.questions[0].page);

                if (this.questionsCol2 && this.questionsCol2.length > 0)
                this.onInited.emit(this.questionsCol2[0].page);
            });
        }
    }

    onSubmit() {
        this.payLoad = JSON.stringify(this.form.value);
        this.onSubmitted.emit(this.payLoad);
    }

    checkChanged(arg: any) {
      if (arg.type == 'checkbox')
        this.form.controls[arg.key].setValue(arg.value === true ? '1' : '0');

      this.onCheckChange.emit(arg);
    }

    setDisabled(val: boolean) {
        this.butDisabled = val;
    }

    externalChanges() {
        if (this.qList) for (var q of this.qList.toArray())
            if (q.question.controlType != 'calendar') q.externalChanges();
        if (this.qList2) for (var q of this.qList2.toArray())
            if (q.question.controlType != 'calendar') q.externalChanges();
    }

    updateQuestions(keys: string[]) {
      for (var q of this.qList.toArray()) {
        let key = keys.find(x => x === q.question.key);
        if (key) {
          q.init();
          q.externalChanges();
        }
      }
    }
}
