import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewEncapsulation, NgZone } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, zip } from 'rxjs';

declare var $: any;
import '../../../node_modules/jquery/dist/jquery.min.js';
import '../../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import "../../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js";

declare var gtag: any;
import intlTelInput from 'intl-tel-input';
import * as moment from 'moment';
import { QuestionService } from './../question.service';
import { QuestionBase } from './../question-base';
import { DataService } from './../data.service';

import { initAutocomplete, initAutocomplete_s, restrictCountry, buildSenderAddress, getAddressComponent, restrictCountry_s } from '../new-parcel/autocompleteAddress';

@Component({
  selector: 'app-registration',
    templateUrl: './registration-new.component.html',
    styleUrls: ['./registration-new.component.css'],
    //encapsulation: ViewEncapsulation.None
})
export class RegistrationComponent implements OnInit {
    questions: QuestionBase<any>[] = [];
    questions_user: QuestionBase<any>[];
    profile: any = {};
    address: any = {};
    //questions_bill: QuestionBase<any>[];
    txtOK = 'Submit';
    txtCancel = null;
    isMobile: boolean;
    @Input() baComp: boolean = false;
    @Output() onInited = new EventEmitter<any>();
    @ViewChild('form', { static: false }) formComp;
    countryStates: any[];
    errorMessage: string;
    activated: boolean;
    activateid: number;
    isProfile: boolean = false;
    oblastOpt: any[] = [];
    oblastOpt_bill: any[] = [];
    auxButtons: any;
    @ViewChild('profileConfirm', { static: false }) profileConfirm;
    monthNames: string[] = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    years: number[] = [];
    days: number[] = [];
    form1: FormGroup;
    form2: FormGroup;
    form2b: FormGroup;
    form3: FormGroup;

    accordionMenu: boolean[] = [true, false, false];

    //passwordGood: boolean;
    badPassword: boolean;
    errorText: string;

    rules = [
        {
        Pattern: "",
        Target: "Length",
        Message: "Must be at least 8 charcters",
        PasswordFlag: false
    },
    {
        Pattern: "[A-Z]",
        Target: "UpperCase",
        Message: "Must have at least 1 upper case character",
        PasswordFlag: false
    },
    {
        Pattern: "[a-z]",
        Target: "LowerCase",
        Message: "Must have at least 1 lower case character",
        PasswordFlag: false
    },
    {
        Pattern: "[0-9]",
        Target: "Numbers",
        Message: "Must have at least 1 numeric character",
        PasswordFlag: false
    },
    {
        Pattern: "[!@@#$%^&*]",
        Target: "Symbols",
        Message: "Must have at least 1 special character",
        PasswordFlag: false
    }
];

    constructor(private service: QuestionService, private dataService: DataService, private router: Router, private route: ActivatedRoute, public zone: NgZone, private fb: FormBuilder) {
        this.activateid = 0;

        for (var y = 1920; y <= 2020; y++)
            this.years.push(y);

        this.isMobile = this.dataService.isMobileDevice;
        if (!this.isMobile && window.innerWidth < 992)
            this.isMobile = true;
    }

    ngOnInit() {
        if (this.router.url == '/profile')
            this.isProfile = true;

        this.route.paramMap.subscribe(params => {
            console.log(params.get('id'));
            this.activateid = Number(params.get('id'));
            if (!this.activateid)
                return;

            this.service.getAllQuestions('MeestParcels').subscribe((res: any[]) => {
                this.service.initQuestions(res);
                this.questions = this.service.getQuestionsCompleteReg();
            });
        });

        if (this.baComp)
            this.isProfile = true;

        this.form1 = this.fb.group({
            phone: ['', Validators.required], email: ['', Validators.required], fname: ['', Validators.required], lname: ['', Validators.required],
            year: ['', Validators.required], month: ['', Validators.required], day: ['', Validators.required],
            language: ['', Validators.required], password: ['', Validators.required], password2: ['', Validators.required]
        });

        this.form2 = this.fb.group({country: ['', Validators.required], province: ['', Validators.required], zip: ['', Validators.required], city: ['', Validators.required],
            streetn: ['', Validators.required], aptn: [''], street: ['', Validators.required], isNoBillingAddress: [true], isManualAddress: [false]
        }, {
            validators: [(form: FormGroup) => {
                return form.get("country").value && form.get("province").value && form.get("zip").value && form.get("city").value && form.get("streetn").value && form.get("street").value ? null : { required: true };
            }]
        });

        this.form2b = this.fb.group({
            bill_country: ['', Validators.required], bill_province: ['', Validators.required], bill_zip: ['', Validators.required], bill_city: ['', Validators.required],
            bill_streetn: ['', Validators.required], bill_aptn: [''], bill_street: ['', Validators.required], isManualAddress: [false]
        });

        this.form3 = this.fb.group({
            year: ['', Validators.required], month: ['', Validators.required], day: ['', Validators.required],
        });

        this.init();
    }

    ngAfterViewInit() {
        if (this.activateid == 0 && !this.baComp) {
            initAutocomplete_s(this.updateStateList.bind(this));
            restrictCountry_s(['us', 'ca']);

            const input = document.querySelector("#phone1");
            intlTelInput(input, {
                allowDropdown: true,
                initialCountry: "us",
                separateDialCode: true,
                preferredCountries: ["ca", "us"],
            });
        }

        if (this.isProfile) {
            this.passval.clearValidators();
            this.passval.updateValueAndValidity();
            this.pass2val.clearValidators();
            this.pass2val.updateValueAndValidity();
            this.rules.forEach(x => x.PasswordFlag = true);
        }
    }

    get yearval() { return this.form1.get('year');}
    get monthval() { return this.form1.get('month'); }
    get passval() { return this.form1.get('password'); }
    get pass2val() { return this.form1.get('password2'); }
    get isNoBill() { return this.form2.get('isNoBillingAddress'); }
    get isManualAddr() { return this.form2.get('isManualAddress'); }

    disableAddress(e, pref: string) {
        let form = pref == 'bill_' ? this.form2b : this.form2;
        let ctl = pref == 'bill_' ? '#b' : '#a';
        let disable = !e || !e.target.checked;
        if (disable) {
            form.get(pref + 'country').disable();
            form.get(pref + 'province').disable();
            form.get(pref + 'zip').disable();
            form.get(pref + 'streetn').disable();
            form.get(pref + 'street').disable();
            form.get(pref + 'city').disable();
        }
        else {
            form.get(pref + 'country').enable();
            form.get(pref + 'province').enable();
            form.get(pref + 'zip').enable();
            form.get(pref + 'streetn').enable();
            form.get(pref + 'street').enable();
            form.get(pref + 'city').enable();
        }
        $(ctl+'country').selectpicker('refresh');
        $(ctl+'province').selectpicker('refresh');
    }

    validAddress(pref: string) {
        let form = pref == 'bill_' ? this.form2b : this.form2;
        return form.get(pref + 'country').value
            && form.get(pref + 'province').value
            && form.get(pref + 'zip').value
            && form.get(pref + 'streetn').value
            && form.get(pref + 'street').value
            && form.get(pref + 'city').value;
    }

    init() {
        zip(this.dataService.getCountryState()).subscribe((res: any[]) => {
            this.countryStates = res[0];
            this.address.country = '';
            this.address.province = '';

            //setTimeout(() => {
                $('#acountry').selectpicker();
                $('#aprovince').selectpicker({container: 'body'});
                $('#alanguage').selectpicker();
            //});

            if (this.isProfile)
                this.onFormInited(null);
        });
/*
        this.questions_user = this.service.getQuestionsRegistration();

        this.questions_user.find(x => x.key == 'dobMonth')["options"] = moment.months().map((v, i) => { return { key: ("0" + (i+1)).slice(-2), value: v } });
        this.questions_user.find(x => x.key == 'dobMonth')["options"].splice(0, 0, { key: '0', value: 'Select Month' });
        //this.questions_user.find(x => x.key == 'dobMonth').value = '0';

        this.questions_user.find(x => x.key == 'dobYear')["options"] = [{ key: '0', value: 'Select Year' }];
        for (var i = 2004; i > 1929; i--)
            this.questions_user.find(x => x.key == 'dobYear')["options"].push({ key: i, value: i });
        //this.questions_user.find(x => x.key == 'dobYear').value = '0';

        this.questions_user.find(x => x.key == 'dobDay')["options"] = [{ key: '0', value: 'Select Day' }];
        //this.questions_user.find(x => x.key == 'dobDay').value = '0';

        if (this.isMobile) {
            this.questions_user.find(q => q.key == 'dummy').active = false;
            this.questions_user.find(q => q.key == 'dummy2').active = false;
            this.questions_user.forEach(x => {
                if (x.containerClass)
                    x.containerClass = x.containerClass.replace('h0', '');
            });
        }

        //this.questions_bill = this.questions_user.filter(x => x.order < 40).map(x => {
        //  var q = Object.assign({}, x); q.key = 'bill_' + x.key; return q;
        //});
        //this.questions_bill.find(q => q.key == 'bill_billsame').active = false;
        //this.questions_bill.find(q => q.key == 'bill_title').label = 'Billing Address';

        if (this.isMobile) {
            this.questions = this.questions_user.filter(x => x.order < 40);
            //this.questions = this.questions.concat(this.questions_bill);
            this.questions = this.questions.concat(this.questions_user.filter(x => x.order >= 40));
        }

        if (this.isProfile) {
            this.questions_user.find(q => q.key == 'password').active = false;
            this.questions_user.find(q => q.key == 'password2').active = false;
        }
        else
            this.auxButtons = [{ text: 'Already have an account? Login here', class: 'login-reg' }];
*/
        //this.passwordGood = false;
        this.badPassword = this.isProfile ? false : true;
        this.errorText = '';
        this.errorMessage = '';
        this.activated = false;
  }

    onFormInited(page) {
        //initAutocomplete_s(this.updateStateList.bind(this));

        //this.initControl(this.formComp.form.controls["dobMonth"], '0');
        //this.initControl(this.formComp.form.controls["dobYear"], '0');
        //this.initControl(this.formComp.form.controls["dobDay"], '0');
        //document.getElementById("dobMonth")["selectedIndex"] = "0";
        //document.getElementById("dobYear")["selectedIndex"] = "0";
        //document.getElementById("dobDay")["selectedIndex"] = "0";

        if (this.isProfile && !this.baComp) {
            let model = {
                lname: this.dataService.userData.UserData.u_lastnm,
                fname: this.dataService.userData.UserData.u_firstnm,
                country: this.dataService.userData.UserData.u_country.toUpperCase(),
                zip: this.dataService.userData.UserData.u_index,
                province: this.dataService.userData.UserData.u_oblast,
                city: this.dataService.userData.UserData.u_city,
                aptn: this.dataService.userData.UserData.u_apart,
                streetn: this.dataService.userData.UserData.u_house,
                street: this.dataService.userData.UserData.u_street,
                phone: this.dataService.userData.UserData.u_phone,
                email: this.dataService.userData.UserData.u_email,
                language: this.dataService.userData.UserData.language,

                bill_country: this.dataService.userData.UserData.b_country ? this.dataService.userData.UserData.b_country.toUpperCase() : null,
                bill_zip: this.dataService.userData.UserData.b_index,
                bill_province: this.dataService.userData.UserData.b_oblast,
                bill_city: this.dataService.userData.UserData.b_city,
                bill_aptn: this.dataService.userData.UserData.b_apart,
                bill_streetn: this.dataService.userData.UserData.b_house,
                bill_street: this.dataService.userData.UserData.b_street,
            };
            if (this.dataService.userData.UserData.dofbirth > 0) {
                model["year"] = Math.floor(this.dataService.userData.UserData.dofbirth / 10000);
                model["month"] = Math.floor((this.dataService.userData.UserData.dofbirth - model["year"] * 10000) / 100);
                model["day"] = this.dataService.userData.UserData.dofbirth - model["year"] * 10000 - model["month"] * 100;
            }
            this.form1.patchValue(model);
            this.form2.patchValue(model);
            this.form2b.patchValue(model);
            this.recalcDays(1);
            this.updateStateList(model.country, model.province, null);
            //this.setProvince('province', this.questions_user, model.country)
            //this.setDayBirth();
        }

        if (this.isProfile && this.baComp) {
            let model = {
                bill_country: this.dataService.userData.UserData.u_country.toUpperCase(),
                bill_zip: this.dataService.userData.UserData.u_index,
                bill_province: this.dataService.userData.UserData.u_oblast,
                bill_city: this.dataService.userData.UserData.u_city,
                bill_aptn: this.dataService.userData.UserData.u_apart,
                bill_streetn: this.dataService.userData.UserData.u_house,
                bill_street: this.dataService.userData.UserData.u_street,
            };
            this.form2b.patchValue(model);
            this.updateStateList_bill(model.bill_country, model.bill_province, null);
            //this.setProvince('province', this.questions_user, model.country)
            this.accordionClicked(2);
            this.onInited.emit();
        }
        //else {
        //    //this.formComp.form.patchValue({country: "CANADA"});
        //    //this.setProvince('province', this.questions_user, "CANADA");

        //    this.formComp.setDisabled(true);
        //    this.formComp.form.controls['password'].valueChanges.subscribe(val => {
        //        this.formComp.form.controls["password2"].patchValue('');
        //        this.errorText = '';
        //        this.badPassword = true;
        //        this.CheckPasswordStrength(val);
        //        this.formComp.setDisabled(!this.isPasswordOK());
        //    });

        //    this.formComp.form.controls['password2'].valueChanges.subscribe(val => {
        //        this.ComparePasswords(val, this.formComp.form.controls["password"].value, false);
        //        this.formComp.setDisabled(!this.isPasswordOK());
        //    });

        //    $('#pwdhint').insertAfter($('#q-container-password'));
        //}
    }

    accordionClicked(ix: number) {
        if (!this.accordionMenu[ix]) {
            if (ix == 2 && !this.form2.valid && !this.baComp)
                return;
            for (var i = 0; i < this.accordionMenu.length; i++)
                this.accordionMenu[i] = false;
            this.accordionMenu[ix] = true;

            if (ix == 1) {
                initAutocomplete_s(this.updateStateList.bind(this), 'g_autocomplete_s');
                this.disableAddress({ target: { checked: this.form2.get('isManualAddress').value } }, '');
            }
            if (ix == 2) {
                initAutocomplete_s(this.updateStateList_bill.bind(this), 'g_autocomplete_b');
                setTimeout(() => {
                    $('#bcountry').selectpicker();
                    $('#bprovince').selectpicker();
                    this.disableAddress({ target: { checked: this.form2b.get('isManualAddress').value } }, 'bill_');
                });
            }
        }
    }

    isPasswordOK() {
        if (this.isProfile && !this.passval.value && !this.pass2val.value)
            return true;
        else return !this.badPassword && this.rules.every(x => x.PasswordFlag);
    }

    initControl(ctrl: FormControl, val: any) {
        ctrl.patchValue(val);
        ctrl.markAsUntouched();
        ctrl.markAsPristine();
    }

    updateStateList(country, stateprov, elems) {
        if (elems) {
            elems.forEach(x => this.form2.controls[x.key.substr(1)].patchValue(x.value));
        }
        else {
            if (!this.countryStates[country.toUpperCase()])
                return;

            this.zone.run(() => {
                this.address.country = country.toUpperCase();
                this.oblastOpt = this.countryStates[country.toUpperCase()].map(c => {
                    return { key: c.state, value: c.desc }
                });
                this.address.province = stateprov;
                setTimeout(() => {
                    $('#acountry').selectpicker('val', country.toUpperCase());
                    this.form2.get('country').patchValue(country.toUpperCase());
                    this.form2.get('country').markAsDirty();
                    this.form2.get('country').markAsTouched();
                    $('#aprovince').selectpicker('refresh');
                    $('#aprovince').selectpicker('val', stateprov);
                    this.form2.get('province').patchValue(stateprov);
                    this.form2.get('province').markAsDirty();
                    this.form2.get('province').markAsTouched();
                    $('div > #acountry').parent().removeClass('ng-invalid');
                    $('div > #acountry').parent().addClass('ng-valid');
                    $('div > #aprovince').parent().removeClass('ng-invalid');
                    $('div > #aprovince').parent().addClass('ng-valid');
                });
            });
        }
        return false;
    }

    updateStateList_bill(country, stateprov, elems) {
        if (elems) {
            elems.forEach(x => this.form2b.controls['bill_'+x.key.substr(1)].patchValue(x.value));
        }
        else {
            if (!this.countryStates[country.toUpperCase()])
                return;

            this.zone.run(() => {
                this.address.country = country.toUpperCase();
                this.oblastOpt_bill = this.countryStates[country.toUpperCase()].map(c => {
                    return { key: c.state, value: c.desc }
                });
                this.address.province = stateprov;
                setTimeout(() => {
                    $('#bcountry').selectpicker('val', country.toUpperCase());
                    this.form2b.get('bill_country').patchValue(country.toUpperCase());
                    this.form2b.get('bill_country').markAsDirty();
                    this.form2b.get('bill_country').markAsTouched();
                    $('#bprovince').selectpicker('refresh');
                    $('#bprovince').selectpicker('val', stateprov);
                    this.form2b.get('bill_province').patchValue(stateprov);
                    this.form2b.get('bill_province').markAsDirty();
                    this.form2b.get('bill_province').markAsTouched();
                    $('div > #bcountry').parent().removeClass('ng-invalid');
                    $('div > #bcountry').parent().addClass('ng-valid');
                    $('div > #bprovince').parent().removeClass('ng-invalid');
                    $('div > #bprovince').parent().addClass('ng-valid');
                });
            });
        }
        return false;
    }

    onCountryChange() {
        if (this.countryStates[this.form2.get('country').value]) {
            this.oblastOpt = this.countryStates[this.form2.get('country').value].map(c => {
                return { key: c.state, value: c.desc }
            });
            this.form2.get('province').patchValue('');
            setTimeout(() => $('#aprovince').selectpicker('refresh'));
        }
    }

    onCountryChange_bill() {
        if (this.countryStates[this.form2b.get('bill_country').value]) {
            this.oblastOpt_bill = this.countryStates[this.form2b.get('bill_country').value].map(c => {
                return { key: c.state, value: c.desc }
            });
            this.form2b.get('bill_province').patchValue('');
            setTimeout(() => $('#bprovince').selectpicker('refresh'));
        }
    }

    onProvinceChange() {
        $('div > #aprovince').parent().removeClass('ng-invalid');
        $('div > #aprovince').parent().addClass('ng-valid');
    }

    onProvinceChange_bill() {
        $('div > #bprovince').parent().removeClass('ng-invalid');
        $('div > #bprovince').parent().addClass('ng-valid');
    }

    setDayBirth() {
        let days = moment(this.formComp.form.controls['dobYear'].value + '-' + this.formComp.form.controls['dobMonth'].value, "YYYY-MM").daysInMonth();
        this.questions_user.find(x => x.key == 'dobDay')["options"] = [{ key: '0', value: 'Select Day' }];
        for (var i = 1; i <= days; i++)
            this.questions_user.find(x => x.key == 'dobDay')["options"].push({ key: ("0" + i).slice(-2), value: i });
    }

    setProvince(kprov: string, quest: QuestionBase<any>[], val: any) {
        if (this.countryStates[val.toUpperCase()]) {
            var qx = quest.findIndex(x => x.key == kprov);
            quest[qx]['options'] = this.countryStates[val.toUpperCase()].map(c => {
                return { key: c.state, value: c.desc }
            });

            quest[qx] = Object.assign({}, quest[qx]);
        }
    }

    recalcDays(f: number) {
        this.days = f == 3 ? this.getAllDaysInMonth(this.form3.get('year').value, this.form3.get('month').value - 1) :  this.getAllDaysInMonth(this.yearval.value, this.monthval.value-1);
    }

     getAllDaysInMonth(year, month) {
         const dates = [];

         if (year != undefined && month != undefined) {
             const date = new Date(year, month, 1);

             while (date.getMonth() === month) {
                 dates.push(date.getDate());
                 date.setDate(date.getDate() + 1);
             }
         }

        return dates;
    }

    submitForm(f: number) {
        //if (this.baComp) {
        //    var ba = this.form2b.getRawValue();
        //    return;
        //}

        var model = f == 4 ? this.form3.value : this.form1.value;
        if (f === 2) 
            model = Object.assign(model, this.form2.getRawValue());
        if (f === 3)
            model = Object.assign(model, this.form2b.getRawValue());

        if (f === 1)
            this.accordionClicked(1);
        else if (f === 2 && !this.form2.get('isNoBillingAddress').value)
            this.accordionClicked(2);
        else if (f !== 4)
            this.submitted(JSON.stringify(model));
        else if (f == 4 && this.activateid > 0) {
            let obj = { id: this.activateid, Year: model.year, Month: model.month, Day: model.day };
            this.dataService.completeReg(obj).subscribe((ui: string) => {
                if (ui != 'OK')
                    this.errorMessage = ui;
                else
                    this.router.navigate(["home"]);
            },
                (err: any) => {
                    this.errorMessage = this.dataService.processError(err);
                });
        }
    }

    getBillAddress() {
        let model = this.form2b.getRawValue();
        let obj = {
            b_lastnm: model.bill_lname,
            b_firstnm: model.bill_fname,
            b_country: model.bill_country,
            b_index: model.bill_zip,
            b_oblast: model.bill_province,
            b_city: model.bill_city,
            b_apart: model.bill_aptn,
            b_house: model.bill_streetn,
            b_street: model.bill_street,
            b_phone: model.bill_phone,
        };
        return obj;
    }

    submitted(payLoad: string) {
        this.errorMessage = '';
        let model = JSON.parse(payLoad);

        if (this.activateid > 0) {
            let obj = { id: this.activateid, password: model.password };
            this.dataService.completeReg(obj).subscribe((ui: string) => {
                if (ui != 'OK')
                    this.errorMessage = ui;
                else
                    this.router.navigate(["home"]);
            },
                (err: any) => {
                    this.errorMessage = this.dataService.processError(err);
                });
        }
        else {
            let obj = {
                u_lastnm: model.lname,
                u_firstnm: model.fname,
                u_country: model.country,
                u_index: model.zip,
                u_oblast: model.province,
                u_city: model.city,
                u_apart: model.aptn,
                u_house: model.streetn,
                u_street: model.street,
                u_phone: model.phone,
                b_lastnm: model.bill_lname,
                b_firstnm: model.bill_fname,
                b_country: model.bill_country,
                b_index: model.bill_zip,
                b_oblast: model.bill_province,
                b_city: model.bill_city,
                b_apart: model.bill_aptn,
                b_house: model.bill_streetn,
                b_street: model.bill_street,
                b_phone: model.bill_phone,
                u_email: model.email,
                password: model.password,
                Month: model.month,
                Day: model.day,
                Year: model.year,
                language: model.language
            };

            if (this.isProfile) {
                obj["id"] = this.dataService.userData.UserData.id;
                this.dataService.userProfile(obj).subscribe((ui: any) => {
                    this.dataService.userData.UserData = ui;
                    //this.profileConfirm.show();
                    this.router.navigate(["home"]);
                },
                    (err: any) => {
                        this.errorMessage = this.dataService.processError(err);
                    });
            }
            else
                this.dataService.register(obj).subscribe((ui: any) => {
                    if (!ui.ID)
                        this.errorMessage = ui;
                    else {
                        this.dataService.userData = ui;
                        this.activated = true;
                        this.dataService.checkpoint(1).subscribe((ui: string) => {
                            if (gtag)
                                gtag('event', 'registration', {
                                    'event_category': 'registration',
                                    'event_label': 'cabinet'
                                }); 
                        });
                    }
                    //this.router.navigate(["home"]);
                },
                    (err: any) => {
                        this.errorMessage = this.dataService.processError(err);
                    });
        }
    }

    profileUpdated() {
        this.profileConfirm.hide();
        this.router.navigate(["home"]);
    }

     ValidatePassword(password) {
         if (password.length > 0) {
             if (password.length > 7) 
                 this.rules[0].PasswordFlag = true;
             else
                 this.rules[0].PasswordFlag = false;
         }

         for (var i = 1; i < this.rules.length; i++) {
             if (new RegExp(this.rules[i].Pattern).test(password)) 
                 this.rules[i].PasswordFlag = true;
             else
                 this.rules[i].PasswordFlag = false;
         }

         this.ComparePasswords(this.pass2val.value, password, false);
    }

    CheckPasswordStrength(password) {
        $('.error-message').show();
        this.ValidatePassword(password);
        return;
    /*
        var length = password.length;
        var colour = "";
        var text = "";
    
        badPassword = true;
        var score = 0;
        //if password bigger than 4 give 1 point
        if (password.length > 7) score++;
        //if password has both lower and uppercase characters give 1 point
        if ((password.match(/[a-z]/)) && (password.match(/[A-Z]/))) score++;
        //if password has at least one number give 1 point
        if (password.match(/\d+/)) score++;
        //if password has at least one special characther give 1 point
        if (password.match(/.[!,@@,#,$,%,^,&,*,>,<,\.,\+,?,_,~,\-,=,(,)[\]:{}]/)) score++;
        //if password bigger than 7 give another 1 point
        if (password.length < 13) score++;
    
        if (score <= 2) {
            colour = "red";
            text = "Password strength is low";
        }
        else if (score <= 4) {
            colour = "orange";
            text = "Password strength is moderate";
        }
        else {
            colour = "green";
            text = "";
            badPassword = false;
        }
        if (!badPassword)
            ComparePasswords($('#ConfirmPassword').get(0));
    
        $("#Password").css("background-color", colour);
        $("#PasswordMessage").text(text);
    */
}

    ComparePasswords(confirm, password, bs: boolean) {
        var colour = "";
        this.errorText = "";

        if (bs)
            $('.error-message2').show();

        if (confirm === '') {
            colour = 'unset';
            this.errorText = "";
        }
        else if (password !== confirm) {
            colour = "orangered";
            this.errorText = "Passwords do not match";
            this.badPassword = true;
        }
        else {
            colour = "lightgreen";
            this.errorText = "";
            this.badPassword = false;
        }

        //document.getElementById('password2').style.backgroundColor = colour;
    }
}
