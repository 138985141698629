import { Component, OnInit, OnDestroy, ViewChild, HostListener, Injectable, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, RouterEvent, CanDeactivate } from "@angular/router";
import { LocationStrategy } from '@angular/common'
import { Subscription } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';

declare var $: any;
import '../../node_modules/jquery/dist/jquery.min.js';
import * as FileSaver from 'file-saver';
import '../../magnific-popup/jquery.magnific-popup.js';

import { SlideInOutAnimation } from './animations';
import { QuestionService } from './question.service';
import { DataService } from './data.service';
import { MessengerService } from './messenger.service';
import { HomeComponent } from './home/home.component';
import { NewParcel2Component } from './new-parcel/new-parcel2.component';
import { NewMoneyComponent } from './new-money/new-money.component';
import { InvoiceComponentComponent } from './invoice-component/invoice-component.component';
import { PaymentComponent } from './payment/payment.component';
//import { EstimatorComponent } from './new-parcel/estimator.component';
import { PopupEstimatorComponent } from './popup-estimator/popup-estimator.component';
import { GuestComponent } from './login/guest.component';
import { LoginComponent } from './login/login.component';

@Component({
  selector: 'app-root',
    animations: [SlideInOutAnimation],
    templateUrl: './app.component.html'
/*  template: `
      <div style="overflow-y:auto; margin: auto;">
        <div style="position:relative;">
          <div *ngIf="active != 'Estimator' && active != 'Login'" alt="" border="0" class="logo" (click)="router.navigate(['home'])"></div>
          <span class="hdr-text" style="display:none">{{apptitle}}</span>
          <div *ngIf="!initial || !dataService.loggedIn" class="icons verticalCenter">
            <div style="text-align:center"><span class="user">{{userinfo}}</span></div>
            <!--i *ngIf="!dataService.loggedIn" class="glyphicon glyphicon-user" (click)="router.navigate(['registration'])"></i-->
            <img *ngIf="active != 'Home' && dataService.loggedIn && !dataService.isGuest && !(active == 'Invoice' && canSign())" src="images/house_icon.png" (click)="gohome()">
            <img *ngIf="canShowInvoice && dataService.loggedIn" src="images/invoice_print.png" (click)="doInvoice()">
            <img *ngIf="active == 'Home' && canPay()" src="images/dollar_icon.png" (click)="doPayment()">
            <img *ngIf="(active == 'Home' || active == 'NewParcel' || active == 'NewMoney') && dataService.loggedIn" src="images/new_parcel.png" (click)="doNewParcel()">
            <img *ngIf="(active == 'Home' || active == 'NewParcel' || active == 'NewMoney') && dataService.loggedIn && dataService.UserBranch == 1 && !dataService.isGuest" src="images/Sign_money.png" (click)="doNewMoney()">
            <!--img *ngIf="active == 'Invoice' && canSign()" src="images/SignButton.gif" style="height:47px;width:115px;" (click)="doSignature()"-->
            <!--img *ngIf="active == 'Invoice' && !canSign() && !isCustomer" src="images/printer.png" (click)="printInvoice(true)"-->
            <img *ngIf="active == 'Invoice' && !canSign() && !isCustomer" src="images/lab.png" (click)="printLabel()">
            <img *ngIf="active == 'Invoice' && !canSign() && !isCustomer" src="images/signature_pad.png" (click)="printInvoice(false)">
            <img *ngIf="active == 'Invoice' && !canSign() && !isCustomer" src="images/CN23.png" (click)="printCN23()">
            <!--img *ngIf="active == 'Invoice' && !canSign() && !isCustomer" src="images/icon.png" (click)="imageInvoice()"-->
            <img *ngIf="active == 'Invoice' && !canSign() && postlabel" [src]="(dataService.userData.Branch ? dataService.userData.Branch.id : 1) == 1 ? 'images/canpostbwt.png' : 'images/FEDEX_label.png'" (click)="labelInvoice()">
            <img *ngIf="dataService.loggedIn && !dataService.isGuest" src="images/profile.png" (click)="doProfile()">
            <img *ngIf="dataService.loggedIn &&  !dataService.isGuest" src="images/logout.png" (click)="logout()">
          </div>
          <hr *ngIf="dataService.isMobileDevice" style="clear:both; display:none" />
        </div>

      <!--button class="btn btn-primary" type="button" (click)="doScan()">Barcode</button>
      <div *ngIf="showScanner"><span>{{scannedCode}}</span>
          <div id="interactive" class="viewport"></div>
      </div>
      <<scandit-picker *ngIf="showScanner" [scanSettings]="settings"
                (scan)="onScan($event)"
                (error)="onError($event)"></scandit-picker>-->

        <div bsModal #smModal="bs-modal" class="modal fade" [config]="{ignoreBackdropClick:true, backdrop:'static'}" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
            <div class="modal-dialog modal-sm" style="margin-top:10%">
                <div class="modal-content popup">
                    <div class="modal-header">
                        <h4 id="dialog-sizes-name2" class="modal-title pull-left">Attention Required!</h4>
                    </div>
                    <div class="modal-body">
                        <p>
                            If you wish to go back or refresh the page, your data will be lost and your parcel will not be saved! 
                        </p><br />
                        <button type="button" class="btn" (click)="gohomeback(true)">PROCEED</button>
                        <button type="button" class="btn" style="margin-left:8px" (click)="gohomeback(false)">START OVER</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="initial && dataService.loggedIn" style="clear: both; margin-top: 15px">
              <!--button class="btn btn-primary pull-left" style="margin-left: 15px;" type="button" (click)="doNewParcel()">New Parcel</button>
              <button class="btn btn-primary pull-right" style="margin-right: 15px;" type="button" (click)="gohome()">Order History</button-->
              <button class="btn btn-primary login100-form-btn center-button" type="button" (click)="doNewParcel()">New Parcel</button>
              <button *ngIf="isCustomer && dataService.UserBranch == 1" class="btn btn-primary login100-form-btn center-button" type="button" (click)="doNewMoney()">New Money Transfer</button>
              <button class="btn btn-primary login100-form-btn center-button" type="button" (click)="doHistory()">Order History</button>
<a *ngIf="dataService.UserBranch == 2" target="_blank" href="https://my.meest.us/en/humanitarian-aid" (click)="donationClick($event)" class="center-button" style="width:60%; cursor:pointer"><img src="images/mpfb.svg"></a>
        </div>

        <div *ngIf="questionService.questions && (!initial || !dataService.loggedIn)" style="clear:both; text-align: left;"><router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet></div>
      </div>
  ` */ ,
    styleUrls: ['./app.componentOrig.css', './app.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  activeComponent: any;
  private messageSubscription: Subscription;
    apptitle = '';  //'myMeest Portal';
    initial = true;
    subscription: Subscription;
    browserRefresh = false;
    prohagree: boolean = false;
    gumb: boolean = false;
    @ViewChild('smModal', { static: false }) smModal: ModalDirective;
    //@HostListener('window:beforeunload', ['$event'])
    //    beforeunloadHandler(event) {
    //        event.preventDefault();
    //        event.returnValue = '';
    //        this.smModal.show();
    //    }
  //showScanner = false;
  //scannedCode = '';

  //public settings = new ScanSettings({
  //  enabledSymbologies: [
  //    Barcode.Symbology.EAN8,
  //    Barcode.Symbology.EAN13,
  //    Barcode.Symbology.UPCA,
  //    Barcode.Symbology.UPCE,
  //    Barcode.Symbology.CODE128,
  //    Barcode.Symbology.CODE39,
  //    Barcode.Symbology.CODE93,
  //    Barcode.Symbology.INTERLEAVED_2_OF_5
  //  ],
  //  codeDuplicateFilter: 1000
  //});

    constructor(public dataService: DataService, private router: Router, private messengerService: MessengerService, private questionService: QuestionService, private location: LocationStrategy ) {
        this.activeComponent = this;
        //this.location.onPopState(() => {
        //    this.dataService.backClicked = true;
        //});
        //router.events.subscribe((routerEvent: RouterEvent) => {
        //    if (routerEvent instanceof NavigationStart) {
        //        if (this.dataService.backClicked) {
        //            this.dataService.backComponent = this.smModal;
        //            this.smModal.show();
        //        }
        //    }
        //    if (routerEvent instanceof NavigationEnd) {
        //        this.dataService.backClicked = false;
        //    }
        //});
    }

  ngOnInit(): void {
    if (this.dataService.isMobileDevice)
      this.apptitle = '';

      this.questionService.getAllQuestions('MeestParcels').subscribe((res: any[]) => {
          this.questionService.initQuestions(res);
      },
          (err: any) => {
              //this.errorMessage = this.dataService.processError(err);
          });

    this.messageSubscription = this.messengerService.message.subscribe(m => {
      if (m.topic === 'InvoiceSigned') {
        //this.dataService.downloadInvoice(m.value, m.text);
        setTimeout(() => {
          //this.router.navigate(["home"]);
          this.router.navigate(["payment", m.value, 0]);
        });
      }
      });

      //$(document).ready(() => {
      //    $('.but_new_parcel').magnificPopup({
      //        type: 'inline',
      //        preloader: false,
      //        modal: true
      //    });
      //    $(document).on('click', '.popup-modal-dismiss', function (e) {
      //        e.preventDefault();
      //        $.magnificPopup.close();
      //    });
      //});
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    }

  public onRouterOutletActivate(event: any) {
      this.activeComponent = event;
      if (this.activeComponent instanceof GuestComponent || !this.dataService.isMobileDevice)
          this.initial = false;

      if (this.active == 'Home' && this.dataService.loggedIn) {
          this.activeComponent.initial = this.initial;
          setTimeout(() => {
              if (this.initial)
                  this.doGumb();
              //    $('.but_new_parcel').magnificPopup({
              //        type: 'inline',
              //        preloader: false,
              //        modal: true
              //    });
              //    $(document).on('click', '.popup-modal-dismiss', function (e) {
              //        e.preventDefault();
              //        $.magnificPopup.close();
              //    });
          });
      }
  }

  get active(): string {
    if (this.activeComponent instanceof HomeComponent) {
      return 'Home';
    }
    if (this.activeComponent instanceof NewParcel2Component) {
      return 'NewParcel';
    }
      if (this.activeComponent instanceof NewMoneyComponent) {
          return 'NewMoney';
      }
    if (this.activeComponent instanceof InvoiceComponentComponent) {
      return 'Invoice';
    }
    if (this.activeComponent instanceof PaymentComponent) {
      return 'Payment';
    }
      if (this.activeComponent instanceof PopupEstimatorComponent) {
          return 'Estimator';
      }
      if (this.activeComponent instanceof LoginComponent) {
          return 'Login';
      }
    return '';
  }

    gohome() {
        this.initial = false;
        this.router.navigate(["home"]);
    }

    gohomeback(isProceed: boolean) {
        this.dataService.backProceed = isProceed;
        this.smModal.hide();
    }

    closeProhibited(e: any) {
        e.stopPropagation();
        e.preventDefault();

        setTimeout(() => $.magnificPopup.close());
        return false;
    }

    toggleShipping() {
        setTimeout(() => $(".ship-menu").slideToggle(400));
    }

    doGumb() {
        if (this.dataService.isMobileDevice) {
            this.gumb = !this.gumb;
            setTimeout(() => $(".nav-menu").slideToggle(400));
        }
    }

    doHistory(e:any) {
        e.stopPropagation();
        e.preventDefault();

        this.doGumb();

        this.initial = false;
        if (this.active == 'Home' && this.dataService.loggedIn) 
            this.activeComponent.initial = this.initial;
        this.dataService.checkpoint(4).subscribe((ui: string) => {
            //if (this.router.url == '/home')
            //    this.doGumb();
            //else
                this.router.navigate(["home"]);
        });
        return false;
    }

  doNewParcel(e:any, dest?: string) {
      e.stopPropagation();
      e.preventDefault();

      this.doGumb();

      this.initial = false;
      this.dataService.numPrepaid().subscribe((num: any) => {
          $.magnificPopup.close();
          var n = Number(num);
          if (!isNaN(n) && n == 0)
              this.dataService.checkpoint(2).subscribe((ui: string) => {
                  this.router.navigate(["newParcel", dest || 'I']);
              });
          else
              this.smModal.show();
      });
      return false;
  }

    doNewMoney(e:any) {
        e.stopPropagation();
        e.preventDefault();

        this.doGumb();

        this.initial = false;
        this.dataService.checkpoint(3).subscribe((ui: string) => {
            this.router.navigate(["newMoney"]);
        });
        return false;
    }

    doProfile(e:any) {
        e.stopPropagation();
        e.preventDefault();

        this.doGumb();

        this.initial = false;
        this.router.navigate(["profile"]);
        return false;
    }

  doInvoice() {
    if (this.activeComponent instanceof HomeComponent) {
      var sel = (<HomeComponent>this.activeComponent).selectedParcel;
      if (sel)
          this.router.navigate(["invoice", sel.id, sel.ShipmentType == 'MONEY' ? '1' : '0']);
    }
  }

  donationClick(ev: any) {
        this.dataService.checkpoint(10).subscribe((ui: string) => {
        });
  }

    get canShowInvoice() {
        if (this.activeComponent instanceof HomeComponent) {
            var sel = (<HomeComponent>this.activeComponent).selectedParcel;
            return !!sel;
        }
        else
            return false;
    }

  get postlabel() {
      if (this.activeComponent instanceof InvoiceComponentComponent) {
          var parcel = (<InvoiceComponentComponent>this.activeComponent).parcel;
          return parcel && parcel.IsCourier;
      }
      else
          return false;
    }

   get isCustomer() {
       return this.dataService.userData.UserData.customerCD === 'GUEST';
    }

    labelInvoice() {
        if (this.activeComponent instanceof InvoiceComponentComponent) {
            var comp = <InvoiceComponentComponent>this.activeComponent;
            var id = comp.invoiceid;
            if (id) {
                window.open(`${this.dataService.siteURL}Agent/PrintPostLabel/${id}?partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 0}-${this.dataService.userData.ID}`, '_blank');
                //this.dataService.getCPLabel({ ShipmentID: id })
                //    .subscribe((response) => { // download file
                //        if (response.type != 'text/plain') {
                //            var lbl = new Blob([response], { type: response.type });
                //            var lblfilename = `${comp.parcel.code_a}_ShippingLabel.pdf`;
                //            FileSaver.saveAs(lbl, lblfilename);
                //        }
                //    });
            }
        }
    }

  printInvoice(isNarrow: boolean) {
    if (this.activeComponent instanceof InvoiceComponentComponent) {
      var id = (<InvoiceComponentComponent>this.activeComponent).invoiceid;
        if (id) {
            //window.open(this.dataService.siteURL + 'Agent/PrintParcelNarrow/' + id, '_blank');
            var ctrl = (<InvoiceComponentComponent>this.activeComponent).isMoney ? 'Money/PrintPayment' : 'Agent/PrintParcel';
            if (isNarrow)
                ctrl += 'Narrow';
            //    window.open(`${this.dataService.siteURL}${ctrl}/PrintParcelNarrow/${id}?partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id: 0}-${this.dataService.userData.ID}`, '_blank');
            //else
            window.open(`${this.dataService.siteURL}${ctrl}/${id}?partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 0}-${this.dataService.userData.ID}`, '_blank');
        }
    }
  }

    printCN23() {
        if (this.activeComponent instanceof InvoiceComponentComponent) {
            var id = (<InvoiceComponentComponent>this.activeComponent).invoiceid;
            if (id) {
                window.open(`${this.dataService.siteURL}Agent/PrintParcel/${id}?cn23=1&partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 0}-${this.dataService.userData.UserData.id}`, '_blank');
            }
        }
    }

    printLabel() {
        if (this.activeComponent instanceof InvoiceComponentComponent) {
            var id = (<InvoiceComponentComponent>this.activeComponent).invoiceid;
            if (id) {
                window.open(`${this.dataService.siteURL}Agent/PrintLabel/${id}?is4x8=false&partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 0}-${this.dataService.userData.UserData.id}`, '_blank');
            }
        }
    }

  imageInvoice() {
    if (this.activeComponent instanceof InvoiceComponentComponent) {
      var id = (<InvoiceComponentComponent>this.activeComponent).invoiceid;
      if (id)
          window.open(`${this.dataService.siteURL}Agent/PrintParcelNarrowImage/${id}?partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 0}-${this.dataService.userData.ID}`, '_blank');
    }
  }

  canPay() {
    if (this.activeComponent instanceof HomeComponent) {
      var sel = (<HomeComponent>this.activeComponent).selectedParcel;
        return sel ? (sel.status != 'PAID' && (sel.ShipmentType == 'MONEY' || sel.carrier != null)) : false;
    }
    else return false;
  }

  doPayment() {
    if (this.activeComponent instanceof HomeComponent) {
      var sel = (<HomeComponent>this.activeComponent).selectedParcel;
      if (sel)
          this.router.navigate(["payment", sel.id, sel.ShipmentType == 'PARCEL' ? 0 : 1]);
    }
  }

  canSign() {
    if (this.activeComponent instanceof InvoiceComponentComponent) {
      var invcomp = <InvoiceComponentComponent>this.activeComponent;
        return !invcomp.isMoney && invcomp.parcel && !invcomp.parcel.CN23_Signature;
    }
    else return false;
  }

  doSignature() {
    if (this.activeComponent instanceof InvoiceComponentComponent) {
      (<InvoiceComponentComponent>this.activeComponent).triggerSignature();
    }
  }

    logout() {
        this.dataService.loggedIn = false;
        this.dataService.userData = null;
        this.initial = true;
        this.router.navigate(["login"]);
    }
}

@Injectable()
export class NavigationGuard implements CanDeactivate<any> {
    constructor(private dataService: DataService) { }
    canDeactivate(component: any) {
        // will prevent user from going back
        if (this.dataService.backClicked) {
            this.dataService.backClicked = false;
            return this.dataService.backComponent.onHidden.pipe(map(() => {
                if(this.dataService.backProceed)
                    history.pushState(null, null, location.href);
                return !this.dataService.backProceed;
            }));
            // push current state again to prevent further attempts.
            //history.pushState(null, null, location.href);
            //return false;
        }
        return true;
    }
}

