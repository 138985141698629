import { Component, Input, forwardRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TreeModule, ITreeOptions, TreeModel, TreeNode, TreeComponent, IActionMapping, TREE_ACTIONS, KEYS } from 'angular-tree-component';

import { DataService } from './../data.service';

const noop = () => { };

interface IDictionary {
    [index: number]: any;
}

@Component({
    selector: 'tree-reactive',
    template: `
      <tree-root #tree [nodes]="nodes" [options]="options">
        <ng-template #treeNodeTemplate let-node let-index="index">
          <span [attr.nodeid]="node.id">{{ node.data.name }}</span>
        </ng-template>
      </tree-root>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TreeReactiveComponent),
        }
    ],
    //host: {
    //    (change): '_onChange($event.target.value)'
    //}
})
export class TreeReactiveComponent implements ControlValueAccessor {
    nodes: any;
    @Input() options: any;
    @Input() readonly: boolean;
    optionsTree: ITreeOptions;
    actionMapping: IActionMapping;
    @ViewChild('tree', { static: false }) treeComponent: TreeComponent;
    @ViewChildren('pop') pop: QueryList<any>;

    private _onChange: (_: any) => void = noop;
    values: IDictionary;
    htmlHelpText: SafeHtml;

    constructor(private dataService: DataService, private sanitizer: DomSanitizer) {
        this.options = {};
        this.values = {};
    }

    ngOnInit() {
        this.actionMapping = {
            mouse: {
                click: (tree, node, $event) => this.nodeClick(node, $event),  // TREE_ACTIONS.TOGGLE_SELECTED_MULTI,
                dblClick: null,
                contextMenu: null,
                expanderClick: TREE_ACTIONS.TOGGLE_EXPANDED,
                drop: TREE_ACTIONS.MOVE_NODE
            },
            keys: {
                [KEYS.RIGHT]: TREE_ACTIONS.DRILL_DOWN,
                [KEYS.LEFT]: TREE_ACTIONS.DRILL_UP,
                [KEYS.DOWN]: TREE_ACTIONS.NEXT_NODE,
                [KEYS.UP]: TREE_ACTIONS.PREVIOUS_NODE,
                [KEYS.SPACE]: TREE_ACTIONS.TOGGLE_SELECTED,
                [KEYS.ENTER]: TREE_ACTIONS.TOGGLE_SELECTED
            }
        }

        this.options = {
            actionMapping: this.actionMapping,
            animateExpand: true,
            animateSpeed: 90,
            animateAcceleration: 1.2
            //getChildren: this.loadOptions.bind(this)
        }
    }

    nodeClick(node: TreeNode, evt: any) {
        if (!this.readonly) {
            if (!node.isActive)
                this.values[node.id] = node.data;
            else
                delete this.values[node.id]
            this._onChange(this.values);

            node.setIsActive(!node.isActive, true);
        }
        //else
        //    node.toggleExpanded();
    }

    writeValue(value: any): void {
        this.nodes = value;
        this.values = {};
        if (!value || value === '')
            return;

        setTimeout(() => {
            this.treeComponent.treeModel.doForAll((node) => {
                if (node.data.IsSelected) {
                    this.values[node.id] = node.data;
                    node.setIsActive(true, true);
                    node.ensureVisible();
                }
            });
            this._onChange(this.values);
        });
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        //this._onTouched = fn;
    }

    private traverseTree(nodes: any[], crit: any): boolean {
        for (var n of nodes) {
            crit.ix++;
            if (n.id == crit.id)
                return true;
            if (!n.isCollapsed && !n.isLeaf && n.children) {
                if (this.traverseTree(n.children, crit))
                    return true;
            }
        }
        return false;
    }
}
