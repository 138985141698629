export class CreditCard {
  Name?: string;
  Number?: string;
  Expiration?: string;
  CVC?: string;
  Amount?: number;
  Currency?: string;
  UserInvoice?: string;
  Method: string;
}

export class CreditCardResponse {
  isApproved: boolean;
  ApprovalCode: string;
  ReferenceNumber: string;
  TraceNumber: string;
  ErrorMessage: string;
}
