import { IMyDpOptions } from 'mydatepicker';

export class QuestionBase<T>{
  page: string;
  value: T;
  key: string;
  label: string;
  required: boolean;
  active: boolean;
  focused: boolean;
  order: number;
  controlType: string;
  width: string;
  hidden: boolean;
  containerClass: string;
  labelClass: string;

  constructor(options: {
    page?: string,
    value?: T,
    key?: string,
    label?: string,
    required?: boolean,
    active?: boolean,
    focused?: boolean;
    order?: number,
    controlType?: string,
    width?: string,
    hidden?: boolean,
    containerClass?: string,
    labelClass?: string
  } = {}) {
    this.page = options.page;
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.active = !!options.active;
    this.focused = !!options.focused;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.width = options.width || '';
    this.hidden = !!options.hidden;
    this.containerClass = options.containerClass || '';
    this.labelClass = options.labelClass || '';
  }
}

export class TextboxQuestion extends QuestionBase<string> {
  controlType = 'textbox';
  type: string;
  readonly: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.readonly = options['readonly'] || false;
  }
}

export class CheckboxQuestion extends QuestionBase<string> {
  controlType = 'checkbox';
  readonly: boolean;
  //callback: (checked:boolean)=>void;

  constructor(options: {} = {}) {
    super(options);
    this.readonly = options['readonly'] || false;
  }
}

export class TextareaQuestion extends QuestionBase<string> {
  controlType = 'textarea';
  rows: string;
  readonly: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.rows = options['rows'] || '2';
    this.readonly = options['readonly'] || false;
  }
}

export class DateQuestion extends QuestionBase<Date> {
  controlType = 'datepicker';
  myDatePickerOptions: IMyDpOptions = { dateFormat: 'mmm dd, yyyy', disableWeekends: true, showTodayBtn: true, firstDayOfWeek: 'su', markCurrentDay: true, allowDeselectDate: true, sunHighlight: false };

  constructor(options: {} = {}) {
    super(options);
    //this.bsConfig = Object.assign({}, { containerClass: options['theme'] || 'theme-dark-blue', showWeekNumbers: false });
  }
}

export class TimeQuestion extends QuestionBase<Date> {
  controlType = 'timepicker';

  constructor(options: {} = {}) {
    super(options);
  }
}

export class TreeQuestion extends QuestionBase<any> {
  controlType = 'tree';
  readonly: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.readonly = options['readonly'] || false;
  }
}

export class DropdownQuestion extends QuestionBase<string> {
  controlType = 'dropdown';
  readonly: boolean;
  options: { key: string, value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.readonly = options['readonly'] || false;
  }
}

export class RadioQuestion extends QuestionBase<string> {
  controlType = 'radio';
  readonly: boolean;
  type: string;
  options: { key: string, value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.type = options['type'] || '';
    this.readonly = options['readonly'] || false;
  }
}

export class MultiDropdownQuestion extends QuestionBase<any[]> {
  controlType = 'multidropdown';
  readonly: boolean;
  options: { key: string, value: string }[] = [];

  myMultiOptions = { badgeShowLimit: 2, enableCheckAll: false, primaryKey: 'key', labelKey: 'value' };

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.readonly = options['readonly'] || false;

    this.value = [];

    if (this.readonly)
      this.myMultiOptions["disabled"] = true;
  }
}
