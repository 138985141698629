import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';




import { DataService } from './data.service';

import { QuestionBase, DropdownQuestion, TextboxQuestion, CheckboxQuestion, TextareaQuestion, DateQuestion, TimeQuestion, TreeQuestion, MultiDropdownQuestion } from './question-base';

@Injectable()
export class QuestionService {
  questions: QuestionBase<any>[];

  constructor(private http: HttpClient, private dataService: DataService) { }

  getAllQuestions(application: string): Observable<any> {
    return this.http.get(this.dataService.baseURL + 'api/UI/Get/' + application);
  }

    initQuestions(qdata: any[]) {
        //this.initDateControls();
        //this.initMultiSelectionControls();
        this.questions = qdata.map(x => {
            let qd: QuestionBase<any>;
            switch (x.controlType) {
                case 'textbox': qd = new TextboxQuestion(x); break;
                case 'dropdown': qd = new DropdownQuestion(x); break;
                case 'checkbox': qd = new CheckboxQuestion(x); break;
                case 'textarea': qd = new TextareaQuestion(x); break;
                case 'datepicker': qd = new DateQuestion(x); break;
                case 'timepicker': qd = new TimeQuestion(x); break;
                case 'tree': qd = new TreeQuestion(x); break;
                //case 'radio': qd = new RadioQuestion(x); break;
                case 'multidropdown': qd = new MultiDropdownQuestion(x); break;
                //case 'editor': qd = new EditorQuestion(x); break;
                default: qd = new QuestionBase<string>(x);
            }
            //let cpy = Object.assign({}, x);
            //Object.assign(x, qd, cpy);
            return qd;
        });
    }

  getQuestionsNewParcel() { return [].concat(this.questions.filter(x => x.page == 'ParcelInit')); }
  getQuestionsNewParcel4() { return [].concat(this.questions.filter(x => x.page == 'ParcelDetails')); }
  getQuestionsReceiver() { return [].concat(this.questions.filter(x => x.page == 'ParcelReceiver')); }
  getQuestionsSender() { return [].concat(this.questions.filter(x => x.page == 'ParcelSender')); }
    getQuestionsRegistration() { return [].concat(this.questions.filter(x => x.page == 'Registration')); }
    getQuestionsCompleteReg() { return [].concat(this.questions.filter(x => x.page == 'CompleteReg')); }
    getQuestionsNewMoney() { return [].concat(this.questions.filter(x => x.page == 'MoneyInit')); }
    getQuestionsNewMoney4() { return [].concat(this.questions.filter(x => x.page == 'MoneyDetails')); }

    getQuestionsNewParcelOld() {
        let questions: QuestionBase<any>[] = [

          new DropdownQuestion({
            key: 'rcountry',
            active: true,
            required: true,
            label: 'Recipient Country',
            options: [
              { key: '1', value: 'Ukraine' },
              { key: '2', value: 'Russia' },
              { key: '3', value: 'Kazakhstan' }
            ],
            width: 'col-sm-8 col-md-6 col-lg-4',
            order: 1
          }),

          new DropdownQuestion({
            key: 'roblast',
            active: false,
            label: 'Oblast/Province',
            options: [
              { key: '1', value: 'Kiyv' },
              { key: '2', value: 'Moscow' },
              { key: '3', value: 'Astana' }
            ],
            width: 'col-sm-8 col-md-6 col-lg-4',
            order: 2
          }),

            new TextboxQuestion({
                key: 'weight',
                active: true,
                label: 'Parcel Weight',
                required: true,
                type: 'number',
                width: 'col-sm-4 col-md-3 col-lg-2',
                order: 3
            }),

            new QuestionBase<string>({
              key: 'x2',
              active: true,
              label: 'Parcel Dimensions',
              controlType: 'label',
              //lblwidth: 'col-sm-8 col-md-5 col-lg-3 xsp0',
              order: 4
            }),

            new TextboxQuestion({
                key: 'h',
                active: true,
                label: 'H',
                type: 'number',
                width: 'col-xs-3 col-sm-2 col-md-1 pl0',
                lblwidth: 'col-xs-1 col-sm-1 tal w20',
                formGroup: false,
                order: 5
            }),

            new TextboxQuestion({
              key: 'l',
              active: true,
              label: 'L',
              width: 'col-xs-3 col-sm-2 col-md-1 pl0',
              type: 'number',
              lblwidth: 'col-xs-1 col-sm-1 tal w20',
              formGroup: false,
              order: 6
            }),

            new TextboxQuestion({
              key: 'w',
              active: true,
              label: 'W',
              width: 'col-xs-3 col-sm-2 col-md-1 pl0',
              type: 'number',
              lblwidth: 'col-xs-1 col-sm-1 tal w20',
              formGroup: false,
              order: 7
            }),
        ];

        return questions;
    }

    getQuestionsNewParcel3() {
      let questions: QuestionBase<any>[] = [

        new QuestionBase<string>({
          key: 'title',
          active: true,
          label: 'Address',
          controlType: 'label',
          //lblwidth: 'col-sm-12 header',
          order: 1
        }),

        new TextboxQuestion({
          key: 'phone',
          active: true,
          label: 'Contact Phone#',
          required: true,
          type: 'number',
          width: 'col-md-6 col-lg-5',
          lblwidth: 'col-md-6 col-lg-4',
          order: 3
        }),

        new TextboxQuestion({
          key: 'email',
          active: true,
          label: 'E-mail',
          required: true,
          type: 'email',
          width: 'col-md-8 col-lg-8',
          lblwidth: 'col-md-6 col-lg-4',
          order: 5
        }),

        new TextboxQuestion({
          key: 'lname',
          active: true,
          label: 'Last Name',
          required: true,
          width: 'col-md-8 col-lg-8',
          lblwidth: 'col-md-6 col-lg-4',
          order: 7
        }),

        new TextboxQuestion({
          key: 'fname',
          active: true,
          label: 'First Name',
          required: true,
          width: 'col-md-8 col-lg-8',
          lblwidth: 'col-md-6 col-lg-4',
          order: 9
        }),

        new DropdownQuestion({
          key: 'country',
          active: true,
          label: 'Country',
          options: [
            { key: 'Canada', value: 'Canada' },
            { key: 'USA', value: 'USA' }
          ],
          width: 'col-md-6 col-lg-5',
          lblwidth: 'col-md-6 col-lg-4',
          order: 11
        }),

        new TextboxQuestion({
          key: 'zip',
          active: true,
          required: false,
          label: 'Postal/ZIP Code',
          width: 'col-md-6 col-lg-5',
          lblwidth: 'col-md-6 col-lg-4',
          order: 13
        }),

        new TextboxQuestion({
          key: 'oblast',
          active: true,
          required: true,
          label: 'Oblast/Province',
          width: 'col-md-8 col-lg-8',
          lblwidth: 'col-md-6 col-lg-4',
          order: 15
        }),

        new TextboxQuestion({
          key: 'region',
          active: true,
          label: 'Region',
          width: 'col-md-8 col-lg-8',
          lblwidth: 'col-md-6 col-lg-4',
          order: 17
        }),

        new TextboxQuestion({
          key: 'city',
          active: true,
          label: 'City/Village',
          width: 'col-md-8 col-lg-8',
          lblwidth: 'col-md-6 col-lg-4',
          order: 19
        }),

        new TextboxQuestion({
          key: 'apartn',
          active: true,
          label: 'Apartment #',
          width: 'col-md-6 col-lg-2',
          lblwidth: 'col-md-6 col-lg-4',
          formGroup: false,
          order: 21
        }),

        new TextboxQuestion({
          key: 'streetn',
          active: true,
          label: 'Street #',
          width: 'col-md-6 col-lg-3',
          lblwidth: 'col-md-6 col-lg-3',
          order: 23
        }),

        new TextboxQuestion({
          key: 'street',
          active: true,
          label: 'Street Name',
          width: 'col-md-8 col-lg-8',
          lblwidth: 'col-md-6 col-lg-4',
          order: 25
        }),
      ];

      return questions.sort((a, b) => a.order - b.order);
    }

    getQuestionsNewParcel4Old() {
      let questions: QuestionBase<any>[] = [

        new TextboxQuestion({
          key: 'brokfee',
          active: true,
          label: 'Brokerage Fees',
          type: 'number',
          width: 'col-md-4 col-lg-3',
          lblwidth: 'col-md-6 col-lg-4',
          order: 3
        }),

        new DropdownQuestion({
          key: 'insamt',
          active: true,
          required: true,
          label: 'Amount of Insurance',
          options: [
            { key: '', value: 'Select Amount' },
          ],
          width: 'col-md-5 col-lg-4',
          lblwidth: 'col-md-6 col-lg-4',
          order: 4
        }),

        new TextboxQuestion({
          key: 'pakfee',
          active: true,
          label: 'Packaging Fees',
          type: 'number',
          width: 'col-md-4 col-lg-3',
          lblwidth: 'col-md-6 col-lg-4',
          order: 5
        }),

        new TextboxQuestion({
          key: 'estval',
          active: true,
          label: 'Estimated Value',
          required: true,
          type: 'number',
          width: 'col-md-4 col-lg-3',
          lblwidth: 'col-md-6 col-lg-4',
          order: 7
        }),

        new TextboxQuestion({
          key: 'airsea',
          active: true,
          label: 'Container Type',
          readonly: true,
          width: 'col-md-4 col-lg-3',
          lblwidth: 'col-md-6 col-lg-4',
          order: 9
        }),

        new TextboxQuestion({
          key: 'delmode',
          active: true,
          readonly: true,
          label: 'Delivery Mode',
          width: 'col-md-7 col-lg-6',
          lblwidth: 'col-md-5 col-lg-4',
          order: 10
        }),

        new TextboxQuestion({
          key: 'weight',
          active: true,
          readonly: true,
          label: 'Weight',
          width: 'col-md-4 col-lg-3',
          lblwidth: 'col-md-6 col-lg-4',
          order: 11
        }),

        new QuestionBase<string>({
          key: 'x2',
          active: true,
          label: 'Parcel Dimensions',
          controlType: 'label',
          //lblwidth: 'col-sm-8 col-md-5 col-lg-4 xsp0',
          order: 13
        }),

        new TextboxQuestion({
          key: 'h',
          active: true,
          readonly: true,
          label: 'H',
          width: 'col-xs-3 col-sm-2 col-md-1 pl0',
          lblwidth: 'col-xs-2 col-sm-1 tal w20 xsp0',
          formGroup: false,
          order: 14
        }),

        new TextboxQuestion({
          key: 'l',
          active: true,
          readonly: true,
          label: 'L',
          width: 'col-xs-3 col-sm-2 col-md-1 pl0',
          lblwidth: 'col-xs-2 col-sm-1 tal w20',
          formGroup: false,
          order: 15
        }),

        new TextboxQuestion({
          key: 'w',
          active: true,
          readonly: true,
          label: 'W',
          width: 'col-xs-3 col-sm-2 col-md-1 pl0',
          lblwidth: 'col-xs-2 col-sm-1 tal w20',
          formGroup: false,
          order: 16
        }),

        new TextareaQuestion({
          key: 'notes',
          active: true,
          rows: '5',
          label: 'Notes',
          width: 'col-sm-12',
          lblwidth: 'col-md-5 col-lg-3',
          order: 18
        }),
      ];

      return questions.sort((a, b) => a.order - b.order);
    }

    getQuestionsSearch() {
      let questions: QuestionBase<any>[] = [

        new TextboxQuestion({
          key: 'studentID',
          active: true,
          label: 'Student ID',
          //value: 'Bombasto',
          required: false,
          width: 'col-sm-9 col-md-5 col-lg-3',
          order: 1
        }),

        new TextboxQuestion({
          key: 'surname',
          active: true,
          label: 'Surname',
          //type: 'email',
          width: 'col-sm-9 col-md-5 col-lg-3',
          order: 2
        }),

        new CheckboxQuestion({
          key: 'swipe',
          active: true,
          label: 'Swipe',
          width: 'ml20',
          order: 4
        }),

        new TextboxQuestion({
          key: 'barcode',
          active: false,
          focused: true,
          label: 'Barcode',
          width: 'col-sm-9 col-md-5 col-lg-3',
          order: 5
        })
      ];

      return questions;
    }

    getQuestionsApptBooking() {
        let questions: QuestionBase<any>[] = [

            new TextboxQuestion({
                key: 'studentID',
                active: true,
                label: 'Student Number',
                required: false,
                readonly: true,
                width: 'col-sm-9 col-md-5 col-lg-3',
                order: 1
            }),

            new TextboxQuestion({
                key: 'firstname',
                active: true,
                label: 'Given Name',
                readonly: true,
                width: 'col-sm-12',
                order: 2
            }),

            new TextboxQuestion({
                key: 'surname',
                active: true,
                label: 'Surname',
                readonly: true,
                width: 'col-sm-12',
                order: 3
            }),

            new TextboxQuestion({
                key: 'postcd',
                active: true,
                label: 'Post CD',
                required: false,
                readonly: true,
                width: 'col-sm-12 col-md-4',
                order: 4
            }),

            new TextboxQuestion({
                key: 'program',
                active: true,
                label: 'Program',
                required: false,
                readonly: true,
                width: 'col-sm-12 col-md-6',
                order: 5
            }),

            new TextboxQuestion({
                key: 'year',
                active: true,
                label: 'Year of Study',
                required: false,
                readonly: true,
                width: 'col-sm-12 col-md-2',
                order: 6
            }),

            new TreeQuestion({
                key: 'topics',
                active: true,
                label: 'Requested Topics',
                width: 'col-sm-12',
                order: 20
            }),

            new DropdownQuestion({
                key: 'advisor',
                active: true,
                label: 'Requested Advisor',
                options: [
                    { key: '1', value: 'Solid' },
                    { key: '2', value: 'Great' },
                    { key: '3', value: 'Good' },
                    { key: '4', value: 'Unproven' }
                ],
                width: 'col-sm-12',
                order: 13
            }),

            new DateQuestion({
                key: 'date',
                active: true,
                required: true,
                theme: 'theme-dark-blue',
                label: 'Select Appointment Time',
                width: 'col-sm-6 col-md-4',
                order: 14
            }),
            new TimeQuestion({
                key: 'time',
                active: true,
                required: true,
                width: 'mt20 col-sm-6 col-md-4',
                order: 15
            }),

            new TextareaQuestion({
                key: 'notes',
                active: true,
                rows: '5',
                label: 'Pre-meeting Notes',
                width: 'col-sm-12',
                order: 16
            }),

        ];

        return questions;
    }

    getQuestionsAppt() {
        let questions: QuestionBase<any>[] = [

            new TextboxQuestion({
                key: 'studentID',
                active: true,
                label: 'Student Number',
                required: false,
                readonly: true,
                width: 'col-sm-9 col-md-5 col-lg-3',
                order: 1
            }),

            new TextboxQuestion({
                key: 'firstname',
                active: true,
                label: 'Given Name',
                readonly: true,
                width: 'col-sm-12',
                order: 2
            }),

            new TextboxQuestion({
                key: 'surname',
                active: true,
                label: 'Surname',
                readonly: true,
                width: 'col-sm-12',
                order: 3
            }),

            new TextboxQuestion({
                key: 'postcd',
                active: true,
                label: 'Post CD',
                required: false,
                readonly: true,
                width: 'col-sm-12 col-md-4',
                order: 4
            }),

            new TextboxQuestion({
                key: 'program',
                active: true,
                label: 'Program',
                required: false,
                readonly: true,
                width: 'col-sm-12 col-md-6',
                order: 5
            }),

            new TextboxQuestion({
                key: 'year',
                active: true,
                label: 'Year of Study',
                required: false,
                readonly: true,
                width: 'col-sm-12 col-md-2',
                order: 6
            }),

            new DateQuestion({
                key: 'date',
                active: true,
                required: true,
                theme: 'theme-dark-blue',
                label: 'Appointment Date and Time',
                width: 'col-sm-6 col-md-4',
                order: 7
            }),
            new TimeQuestion({
                key: 'time',
                active: true,
                required: true,
                width: 'mt20 col-sm-6 col-md-4',
                order: 8
            }),

            new DropdownQuestion({
                key: 'duration',
                active: true,
                label: 'Duration',
                options: [
                    { key: '0', value: '0 min' },
                    { key: '15', value: '15 min' },
                    { key: '30', value: '30 min' },
                    { key: '45', value: '45 min' },
                    { key: '60', value: '60 min' }
                ],
                width: 'col-sm-6 col-md-4',
                order: 9
            }),

            new DropdownQuestion({
                key: 'advisor',
                active: true,
                label: 'Advisor',
                options: [
                    { key: '1', value: 'Solid' },
                    { key: '2', value: 'Great' },
                    { key: '3', value: 'Good' },
                    { key: '4', value: 'Unproven' }
                ],
                width: 'col-sm-12',
                order: 10
            }),

            new TreeQuestion({
                key: 'topics',
                active: true,
                label: 'Requested Topics',
                width: 'col-sm-12 col-lg-6',
                order: 11
            }),
            new TreeQuestion({
                key: 'topics2',
                active: true,
                label: 'Discussed Topics',
                width: 'col-sm-12 col-lg-6',
                order: 11
            }),

            new TextareaQuestion({
                key: 'notes',
                active: true,
                rows: '5',
                label: 'Meeting Notes',
                width: 'col-sm-12',
                order: 12
            }),

            new DropdownQuestion({
                key: 'privacy',
                active: true,
                label: 'Privacy Level',
                options: [
                    { key: 'P', value: 'Private' },
                    { key: 'D', value: 'Departmental' },
                    { key: 'S', value: 'Student' },
                    { key: 'F', value: 'Faculty' }
                ],
                width: 'col-sm-12',
                order: 13
            }),

            new TextareaQuestion({
                key: 'postnotes',
                active: true,
                rows: '5',
                label: 'Meeting Outcomes',
                width: 'col-sm-12',
                order: 14
            }),

            new CheckboxQuestion({
                key: 'send_email',
                active: true,
                label: 'Send Email',
                width: 'col-sm-6 col-md-4 col-lg-3',
                order: 15
            }),
            new CheckboxQuestion({
                key: 'followup',
                active: true,
                label: 'Follow-up',
                width: 'col-sm-6 col-md-4 col-lg-3',
                order: 16
            }),
        ];

        return questions;
    }
}
