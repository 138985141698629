import { AfterViewInit, Component, ViewChild, ViewContainerRef, HostListener } from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
    selector: 'numeric-cell',
    template: `<input #input [type]="isSafari ? 'text' : 'number'" [attr.pattern]="isSafari ? safariPattern : null" (keydown)="onKeyDown($event)" [(ngModel)]="value" style="width: 100%">`
})

export class NumericEditor implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: number;
    private cancelBeforeStart: boolean = false;
    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    safariPattern = '\\d*';

    @ViewChild('input', { read: ViewContainerRef, static: false }) public input;

    @HostListener('wheel', ['$event']) onWheel(event: Event) {
        event.preventDefault();
    }
    @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
        event.preventDefault();
    }
    @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
        event.preventDefault();
    }
    @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
        event.preventDefault();
    }

    agInit(params: any): void {
        this.params = params;
        this.value = this.params.value;

        // only start edit if key pressed is a number, not a letter
        this.cancelBeforeStart = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    }

    getValue(): any {
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd(): boolean {
        return this.value > 1000000;
    };

    onKeyDown(event): void {
        if (!this.isKeyPressedNumeric(event)) {
            if (event.preventDefault) event.preventDefault();
        }
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        if (this.params.focus)
            setTimeout(() => {
                this.input.element.nativeElement.focus();
            });
    }

    private getCharCodeFromEvent(event): any {
        event = event || window.event;
        return (typeof event.which == "undefined") ? event.keyCode : event.which;
    }

    private isCharNumeric(charStr): boolean {
      return !!/[\d\.]/.test(charStr);
    }

    private isKeyPressedNumeric(event): boolean {
      const charCode = this.getCharCodeFromEvent(event);
      if (charCode == 8 || charCode == 46)  //  Backspace, Delete
        return true;
      const charStr = event.key ? event.key : String.fromCharCode(charCode);
      return this.isCharNumeric(charStr);
    }
}
