import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { CreditCard, CreditCardResponse } from './creditCard';
import * as FileSaver from 'file-saver';

import { CookieService } from 'ngx-cookie-service';
import { DataService } from './../data.service';

@Component({
  selector: 'payment-result',
  templateUrl: './payment-result.component.html',
  providers: [CookieService],
  styles: [`
      .txtLabel {
        font-weight: bold;
      }
      .txt {
      }
  `]

})
export class PaymentResultComponent implements OnInit {
  @Input() parcelid: number;
  parcel: any;
  paycode: string;
  errorMessage: string;
  statusMessage: string;

  private baseURL: string;
  model: any;
  loading: boolean;

  constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute, private cookieService: CookieService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.parcelid = Number(params.get('id'));

      this.loading = false;
      this.statusMessage = '';
      this.errorMessage = '';

      var credenc = encodeURIComponent(this.cookieService.get('authHash'));
      this.dataService.loginEncrypted(credenc).subscribe((ui: any) => {
        this.dataService.userData = ui;
        this.dataService.loggedIn = true;

        this.dataService.getPaymentResult(this.parcelid).subscribe((res: any[]) => {
          this.model = res.length > 0 ? res[0] : {};

          if (this.model.ShipmentID)
            this.statusMessage = this.model.isApproved ? 'Payment Completed' : 'Payment error';
        },
          (err: any) => {
            this.errorMessage = this.dataService.processError(err);
          });
      },
        (err: any) => {
          this.errorMessage = this.dataService.processError(err);
        });

    });
  }

  cancelOK() {
    if (!this.model.isApproved) {
      setTimeout(() => { this.router.navigate(['home']); });
      return;
    }

    this.loading = true;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari)
      setTimeout(() => { this.router.navigate(['home']); });
    else
      this.dataService.downloadInvoiceAsync(this.parcelid, false)
        .subscribe((response) => { // download file
          this.loading = false;

          var blob = new Blob([response], { type: 'application/pdf' });
          var filename = `${this.model.ShipmentID}.pdf`;
          FileSaver.saveAs(blob, filename);
          setTimeout(() => { this.router.navigate(['home']); });
        });
  }
}
