import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { QuestionBase } from './question-base';

@Injectable()
export class QuestionControlService {
    constructor() { }

    toFormGroup(questions: QuestionBase<any>[]) {
        let group: any = {};

        questions.filter(x => x.active).sort((a, b) => a.order - b.order).forEach(question => {
            group[question.key] = question.required ? new FormControl(question.value || '', Validators.required)
                : new FormControl(question.value || '');
        });
        return new FormGroup(group);
    }

    addFormGroup(questions: QuestionBase<any>[], formGroup: FormGroup) {
      let group: any = {};

      questions.filter(x => x.active).sort((a, b) => a.order - b.order).forEach(question => {
        let ctrl = question.required ? new FormControl(question.value || '', Validators.required) : new FormControl(question.value || '');
        formGroup.addControl(question.key, ctrl);
      });
    }
}
