import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//import { } from 'googlemaps';
declare var google: any;

import { initAutocomplete, initAutocomplete_s, restrictCountry, buildSenderAddress, getAddressComponent } from './autocompleteAddress';
import { DataService } from '../data.service';

@Component({
    selector: 'app-map',
    template: `
        <div>
          <ng-template #tplloading>
            <div class="text-center">
              <img src="assets/images/ajax-loader.gif" style="margin:30px;" />
            </div>
          </ng-template>

          <div>
            <span style="color:red;font-size:1.2em;margin-left:10px">{{errorMessage}}</span>
          </div>

          <div *ngIf="!loading; else tplloading">
                <div id="map" style="width: 100%; height: 520px;"></div>
          </div>

          <div *ngIf="selectedAgent" class="col-sm-12 col-lg-10" style="margin-top:15px; padding:0">
            <span style="font-weight:bold; color:#0061af">{{agentAddr}}</span><br/>
          </div>
        </div>
`,
    styles: [`
        .sel-row { background-color: #10c0e0 !important; }
`]
})
export class MapComponent implements OnInit {
    errorMessage: string;
    loading: boolean;
    country: string;
    map: any;
    location: any;
    address;
    agentAddr: string;
    selectedAgent: any;
    selectedMarker: any;
    @Input() parcel: any = {};
    rates: any[];
    user_addrComp: any = {};
    agent_addrComp: any = {};
    url = "https://maps.google.com/mapfiles/ms/icons/";
    selectedPostService: number;

    constructor(public router: Router, private dataService: DataService, public zone: NgZone) { }


    ngOnInit() {
        this.loading = false;
        this.selectedAgent = null;
        this.rates = [];
        this.selectedPostService = -1;

        var addrComp = this.dataService.parseUserAddress(this.user_addrComp);
        this.address = addrComp.length > 0 ? addrComp.join(', ') : '';

        if (this.address != '') {
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': this.address }, (results, status) => {
                if (status == 'OK') {
                    const config = {
                        ignoreBackdropClick: true,
                        class: 'modal-lg'
                    };
                    this.country = getAddressComponent(results[0].address_components, 'country');
                    this.location = results[0].geometry.location;
                    this.show();
                    //map.setCenter(results[0].geometry.location);
                    //var marker = new google.maps.Marker({
                    //    map: map,
                    //    position: results[0].geometry.location
                    //});
                } else {
                    alert('Geocode was not successful for the following reason: ' + status);
                }
            });
        }
    }

    show() {
        this.dataService.getAgentList(this.country, this.location.lat(), this.location.lng()).subscribe((ui: any[]) => {
            var mapOptions = { zoom: 10 };

            this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
            this.map.setCenter(this.location);
            //var marker = new google.maps.Marker({
            //    map: this.map,
            //    position: this.location
            //});

            var cityCircle = new google.maps.Circle({
                strokeColor: '#0000FF',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#0000FF',
                fillOpacity: 0.35,
                map: this.map,
                center: this.location,
                radius: 100
            });

            for (var ag of ui) {
                var marker = new google.maps.Marker({
                    position: { lat: Number(ag.latitude), lng: Number(ag.longitude) },
                    icon: this.url + "red-dot.png",
                    title: ag.agentname
                });
                marker.setMap(this.map);

                this.handleMarker(ag, marker);
            }
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }

    handleMarker(agent, marker) {
        var ag = Object.assign({}, agent);
        marker.addListener('click', (event) => {
            //console.log(ag);
            let color = '';
            if (this.selectedMarker != null) {
                color = 'red';
                this.selectedMarker.setIcon(this.url + color + "-dot.png");
            }

            color = 'green';
            marker.setIcon(this.url + color + "-dot.png");

            let addrComp = this.dataService.parseAgentAddress(ag, this.agent_addrComp);
            let agphone = ag.phone1 ? `, ${ag.phone1}` : (ag.phone2 ? `, ${ag.phone2}` : '');
            this.agentAddr = addrComp.length > 0 ? addrComp.join(', ') + agphone : '';
            this.selectedMarker = marker;
            this.selectedPostService = -1;
            this.zone.run(() => {
                this.selectedAgent = ag;
                //this.getRates();
            });
        });
    }

    //getRates() {
    //    this.errorMessage = "";
    //    this.dataService.getCPRates({ From: this.user_addrComp.postal_code, To: this.agent_addrComp.postal_code, Weight: this.parcel.weight, Height: this.parcel.h, Width: this.parcel.w, Length: this.parcel.l }).subscribe((ui: any) => {
    //        //console.log(ui.Message);
    //        if (ui.Error)
    //            this.errorMessage = ui.Message;
    //        else {
    //            this.rates = ui.Rates;
    //            this.rates.splice(0, 0, { ServiceName: 'Drop-off'});
    //        }
    //    },
    //        (err: any) => {
    //            this.errorMessage = this.dataService.processError(err);
    //        });
    //}

    get getData() {
        return JSON.stringify({ userAddress: this.user_addrComp, agentAddress: this.agent_addrComp, agent: this.selectedAgent });  //, rate: this.rates[this.selectedPostService] });
    }
}
