import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
declare var google: any;

@Component({
    selector: 'address-autocomplete',
    template: `
      <input class="input"
        type="text"
        [(ngModel)]="autocompleteInput"
        #addresstext style="padding: 12px 20px; border: 1px solid #ccc; width: 400px; display: none"
        >
    `,
})
export class AddressAutocompleteComponent implements OnInit, AfterViewInit {
    @Input() inputElement: string;
    @Input() countryRestrictions: any;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext', { static: false }) addresstext: any;
    autocomplete: any;  // google.maps.places.Autocomplete;

    autocompleteInput: string;
    componentForm_s: any;
    formElements_s: any;

    constructor() {
        this.componentForm_s = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };

        this.formElements_s = {
            street_number: 'sstreetn',
            route: 'sstreet',
            locality: 'scity',
            administrative_area_level_1: 'soblast',
            country: 'scountry',
            postal_code: 'szip'
        };
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        this.autocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>document.getElementById(this.inputElement ? this.inputElement : 'g_autocomplete_s') ,  //this.addresstext.nativeElement,
            {
                types: ['geocode']
            });
        google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
            const place = this.autocomplete.getPlace();
            this.invokeEvent(place);
        });

        if (this.countryRestrictions)
            this.autocomplete.setComponentRestrictions({ 'country': this.countryRestrictions });
    }

    invokeEvent(place: any) {
        if (!place.address_components)
            return;

        console.log(place);
        var stateprov = '';
        var country = '';
        var elems = [];

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (this.componentForm_s[addressType]) {
                var vald = place.address_components[i][this.componentForm_s[addressType]];

                if (this.formElements_s[addressType] == 'soblast')
                    stateprov = vald;
                else if (this.formElements_s[addressType] == 'scountry') {
                    if (vald == 'United States') vald = 'USA';
                    country = vald;
                }
                else
                    elems.push({ key: this.formElements_s[addressType], value: vald });
            }
        }

        setTimeout(() => this.setAddress.emit({ country: country, stateprov: stateprov, elems: elems }));
    }
}
