exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h3 {\r\n  font-size: 1.5em;\r\n  color: #002a5c;\r\n}\r\n\r\nh4 {\r\n  color: #369;\r\n  font-family: Arial, Helvetica, sans-serif;\r\n  font-size: 1.2em;\r\n}\r\n\r\n.newparcel {\r\n  /*border: 1px solid rgba(2,2,2,2);\r\n  border-radius: 5px;\r\n  margin: 10px 10px 10px 10px;\r\n  padding-top: 10px;\r\n  padding-bottom: 10px;\r\n  overflow-y: auto;*/\r\n  padding: 7px;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    #newparcel {\r\n        border: none;\r\n        margin: 1px 1px 1px 1px;\r\n    }\r\n  h3 { margin-top: 0 !important; }\r\n}\r\n\r\n.itemButtons .btn {\r\n  margin-right:8px;\r\n}\r\n\r\n.ng-valid[required]:not(form):not(div):not(ng-multiselect-dropdown), .ng-valid.required:not(ng-multiselect-dropdown), .ng-valid[required] > div.multiselect-dropdown, div.ng-valid.bootstrap-select {\r\n    border-left: 5px solid #42A948 !important; /* green */\r\n}\r\n\r\n.ng-invalid:not(form):not(div):not(ng-multiselect-dropdown), .ng-invalid[required] > div.multiselect-dropdown {\r\n    border-left: 5px solid #a94442 !important; /* red */\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();