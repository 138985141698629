import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, zip } from 'rxjs';

import { DataService, Credentials, UserCredentials } from './../data.service';

@Component({
    selector: 'app-guest',
    template: `
            <div>
                <span style="color:red">{{errorMessage}}</span>
            </div>
                                            <div class="centerFlex" *ngIf="loading">
                                                <img src="images/highres_box.gif" style="margin:30px;" />
                                            </div>
        `,
    styles: [`
        `]
})
export class GuestComponent implements OnInit, AfterViewInit {
    errorMessage = '';
    rptext = '';
    loading = false;
    resetp = false;
    agentLogin: any;
    credentials: Credentials;
    branchList = [];
    serviceList = [];
    user: UserCredentials;

    constructor(private dataService: DataService, private router: Router, private sanitizer: DomSanitizer) {
        this.credentials = { username: '', password: '', branch: '0', service: 'PARCELS' };
    }

    ngOnInit() {
        this.errorMessage = '';
        this.loading = true;
        zip(this.dataService.getBranches(), this.dataService.getServices(this.credentials.branch)).subscribe((ui: any[]) => {
            this.branchList = ui[0];
            this.dataService.branches = ui[0];
        },
            (err: any) => {
                this.loading = false;
                this.errorMessage = this.dataService.processError(err);
            });
    }

    ngAfterViewInit() {
        this.login(this.credentials);
    }

    login(credentials: Credentials) {
        this.errorMessage = '';
        this.agentLogin = null;
        this.loading = true;
            this.dataService.loginGuest(this.credentials).subscribe((ui: UserCredentials) => {
                this.loading = false;
                this.dataService.userData = ui;
                this.dataService.loggedIn = true;

                this.dataService.checkpoint(2).subscribe((ui: string) => {
                    this.router.navigate(["newParcel"]);
                });
            },
                (err: any) => {
                    this.loading = false;
                    if (err.status == 403) {
                        this.errorMessage = 'Sorry, we couldn’t log you in.';
                    }
                    else
                        this.errorMessage = this.dataService.processError(err);
                });
    }
}
