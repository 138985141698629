import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { QuestionBase, TextboxQuestion } from './../question-base';
import { TreeReactiveComponent } from './tree.component';

@Component({
    selector: 'app-question',
    template: `
        <div [ngClass]="vertClass" [formGroup]="form">
          <label *ngIf="question.controlType != 'checkbox'" [ngClass]="lblClass" [attr.for]="question.key" [innerHTML]="labelHTML"></label>
 
          <div *ngIf="question.controlType != 'label'" [ngClass]="horzClass" [ngSwitch]="question.controlType">
 
            <input #input class="form-control" *ngSwitchCase="'textbox'" [formControlName]="question.key" (change)="oninputChange.emit($event)" [required]="question.required"
                    [id]="question.key" [name]="question.key" [type]="question.type" [readonly]="question.readonly" [attr.pattern]="isNumberSafari ? safariPattern : null">
 
            <my-date-picker *ngSwitchCase="'datepicker'" [formControlName]="question.key" [id]="question.key" [options]="question.myDatePickerOptions"></my-date-picker>
 
            <timepicker *ngSwitchCase="'timepicker'" [formControlName]="question.key"
                    [id]="question.key"></timepicker>
 
            <tree-reactive *ngSwitchCase="'tree'" [formControlName]="question.key" [readonly]="question.readonly"
                    [id]="question.key"></tree-reactive>
 
            <label *ngSwitchCase="'checkbox'"><input #chk [formControlName]="question.key"
                    [id]="question.key" [type]="'checkbox'" (change)="checkChange($event)"><span [innerHTML]="labelHTML"></span>
            </label>
 
            <textarea *ngSwitchCase="'textarea'" #tarea [formControlName]="question.key" style="width:100%"
                    [id]="question.key" [rows]="question.rows" [readonly]="question.readonly">
            </textarea>
 
            <select #ddown class="form-control" [id]="question.key" *ngSwitchCase="'dropdown'" (change)="checkChange($event)" [formControlName]="question.key" [required]="question.required">
              <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
            </select>
 
            <div *ngSwitchCase="'radio'">
              <ng-container *ngIf="question.type != 'horizontal'">
                <div *ngFor="let opt of question.options" class="radio">
                  <label [ngClass]="opt.style"><input type="radio" [formControlName]="question.key" [value]="opt.key">{{opt.value}}</label>
                </div>
              </ng-container>
              <ng-container *ngIf="question.type == 'horizontal'">
                <label *ngFor="let opt of question.options" class="radio-inline" [ngClass]="opt.style">
                  <input type="radio" [formControlName]="question.key" [value]="opt.key">{{opt.value}}
                </label>
              </ng-container>
            </div>
 
            <angular2-multiselect [id]="question.key" *ngSwitchCase="'multidropdown'" [formControlName]="question.key"
                [data]="question.options" [settings]="question.myMultiOptions">
            </angular2-multiselect>
 
          </div> 
 
          <div class="text-danger" *ngIf="!isValid && !question.readonly">{{question.label ? question.label : 'Field'}} is required</div>
        </div>
    `,
    styles: [`
      .inline { display: inline }
      .green { color: green }
      .blue { color: blue }
      .yellow { color: sandybrown }
      .red { color: red }
      .ml20 { margin-left: 20px }
      .ml15 { margin-left: 15px }
      .mb15 { margin-bottom: 15px }
      .mt20 { margin-top: -20px }
      .mt10 { margin-top: 10px }
      .mt15 { margin-top: 15px }
      .mt30 { margin-top: 30px }
      .mr15 { margin-right: 15px }
      .mt20p { margin-top: 20px }
      .mb0 { margin-bottom: 0 }
      .mgr { margin: 0 -15px 15px -15px }
      .pt10 { padding-top: 10px }
      .pl0 { padding-left: 0px }
      .p0 { padding: 0px }
      .tal { text-align: left }
      .w20 { width: 20px }
      /*.note-offset { margin-left: 7px; padding-left:2px }*/
      .col-xs-3 { width: 22% !important }
      .h0 { height: 0; margin-bottom: 0 }
        /*@media (max-width: 768px) {
          .h0 { height: inherit; margin-bottom: 15px }
        }*/
      .mb0 { margin-bottom: 0 }
      .no-group { margin-left: 0; margin-right: 0; display: block; }
      .no-group:after, .no-group:before { display: initial !important; }
      .fnorm { font-weight: normal }
      .text-success { padding-left: 15px; }
      .taright { text-align: right; }
      .tacenter { text-align: center; }
      .hiddenCtl { display:none; }
      .colrlb { background-color: #0061af; color: white; }
      .clearb { clear: both; }
      .ovyauto { overflow-y: auto; }
      .cb2 input[type=checkbox]
      {
        /* Double-sized Checkboxes */
        -ms-transform: scale(2); /* IE */
        -moz-transform: scale(2); /* FF */
        -webkit-transform: scale(2); /* Safari and Chrome */
        -o-transform: scale(2); /* Opera */
        padding: 10px;
        margin-left: -30px !important;
      }
      .cb2 label
      {
        /* Double-sized Checkbox text */
        font-size: 110%;
        font-weight: bold;
      }
      ::ng-deep input.bs-timepicker-field { width: 80px; }

.ng-valid[required]:not(form):not(div):not(ng-multiselect-dropdown), .ng-valid.required:not(ng-multiselect-dropdown), .ng-valid[required] > div.multiselect-dropdown, div.ng-valid.bootstrap-select {
    border-left: 5px solid #42A948 !important; /* green */
}

.ng-invalid:not(form):not(div):not(ng-multiselect-dropdown), .ng-invalid[required] > div.multiselect-dropdown {
    border-left: 5px solid #a94442 !important; /* red */
}
    `]
})
export class DynamicFormQuestionComponent implements AfterViewInit {
    @Input() question: QuestionBase<any>;
    @Input() form: FormGroup;
    @Input() horizontal: boolean = false;
    @Input() noGroup: boolean = false;
    get isValid() { return this.form.controls[this.question.key].valid || this.form.controls[this.question.key].pristine }
    @Output() oncheckChange = new EventEmitter<any>();
    @Output() oninputChange = new EventEmitter<any>();

    @ViewChild('input', { static: false }) vc;
    @ViewChild('chk', { static: false }) chk;
    @ViewChild('ddown', { static: false }) ddown;
    @ViewChild('tarea', { static: false }) tarea;
    lblClass: string = '';
    vertClass: string = '';
    horzClass: string = '';
    labelHTML: SafeHtml;
    isSafari: boolean;
    isNumberSafari = false;
    safariPattern = '\\d*';

    constructor(private ref: ChangeDetectorRef, private sanitizer: DomSanitizer) { }

  ngOnInit() {
      this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      this.init();
    }

    init() {
        this.lblClass = this.horizontal && this.question.controlType != 'checkbox' ? (this.question.labelClass ? this.question.labelClass : 'control-label col-sm-3 col-md-2 ') : '';

        if (this.question.controlType == 'label') {
            this.lblClass = this.question.width;
            if (this.question.containerClass)
                this.vertClass = this.question.containerClass;
        }
        else {
            this.vertClass = this.noGroup ? '' : 'form-group ';
            this.vertClass = this.vertClass + (this.horizontal ? '' : this.question.width);
            if (this.question.containerClass)
              this.vertClass += (' ' + this.question.containerClass);

            if (this.question.label == '')
                this.lblClass += ' inline';
        }

        if (this.question.hidden)
            this.vertClass += ' hiddenCtl';

        this.horzClass = this.horizontal ? this.question.width :
            (this.question.controlType == 'textarea' || this.question.controlType == 'datepicker' || this.question.controlType == 'timepicker' || this.question.controlType == 'multidropdown' ? '' : 'inline');

        if (this.question.controlType == 'checkbox')
            this.horzClass += ' checkbox';

        if (this.question.label && this.question.label != '')
            this.labelHTML = this.sanitizer.bypassSecurityTrustHtml(this.question.label);

        if (this.question.controlType == 'textbox' && (<TextboxQuestion>this.question).type == 'number' && this.isSafari) {
            (<TextboxQuestion>this.question).type = 'text';
            this.isNumberSafari = true;
        }
    }

    ngAfterViewInit() {
        var ctrl = <FormControl>this.form.controls[this.question.key];
        if (this.vc && this.question.focused)
            this.vc.nativeElement.focus();
        if (this.chk) {
            this.chk.nativeElement.checked = this.question.value == '1' ? '1' : null;
            ctrl.registerOnChange(() => {
                this.chk.nativeElement.checked = ctrl.value == '1' ? '1' : null;
            });
        }
        if (this.ddown || this.tarea || this.chk) {
            if (this.question["readonly"])
              ctrl.disable();
        }
        if (this.question.value) {
          ctrl.patchValue(this.question.value);
          this.ref.detectChanges();
        }
    }

    checkChange(e) {
      this.oncheckChange.emit({ key: this.question.key, type: this.question.controlType, value: this.question.controlType == 'checkbox' ? e.target.checked : e.target.value });
    }

    externalChanges() {
        if (this.question.value) 
            this.form.controls[this.question.key].patchValue(this.question.value);

        var ctrl = <FormControl>this.form.controls[this.question.key];
        if (this.ddown || this.tarea || this.chk) {
            if (this.question["readonly"])
                ctrl.disable();
            else
                ctrl.enable();
        }

        this.ref.detectChanges();
    }
}
