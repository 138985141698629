export class Parcel {
  public id : number;
        public ShipmentType : string;
        public branchID : number;
        public userID : number;
        public customerCD : string;
        public code_a : string;
        public msg_old : number;
        public msg_new : number;
        public AddressName : string;
        public s_lastnm : string;
        public s_firstnm : string;
        public r_lastnm : string;
        public r_firstnm : string;
        public s_country : string;
        public s_index : string;
        public s_oblast : string;
        public s_region : string;
        public s_city : string;
        public s_apart : string;
        public s_house : string;
        public s_street : string;
        public s_phone : string;
        public s_email : string;
        public r_country : string;
        public r_index : string;
        public r_oblast : string;
        public r_region : string;
        public r_city : string;
        public r_apart : string;
        public r_house : string;
        public r_street : string;
        public r_phone : string;
        public r_email : string;
        public packages : number;
        public packagedDT? : Date;
        public shipmentDT? : Date;
        public deliveryDT? : Date;
        public skid : string;
        public container : string;
        public s_a_e : string;
        public status : string;
        public statusDT? : Date;
        public synchrDT? : Date;
        public currencyCD : string;
        public storage : number;
        public packaging : number;
        public rpackaging : number;
        public inspection : number;
        public delivery : number;
        public rate_fee : number;
        public insurance : number;
        public ins_amount : number;
        public vol_fee : number;
        public customs : number;
        public storage_packed : number;
        public cust_own : number;
        public value : number;
        public paymentID : number;
        public weightCD : string;
        public weight : number;
        public dimenCD : string;
        public vol_H : number;
        public vol_L : number;
        public vol_W : number;
        public vol_wghtCD : string;
        public vol_wght : number;
        public collected : number;
        public packed : number;
        public MessageType : string;
        public first_DT? : Date;
        public edit_DT? : Date;
        public source : string;
        public ShipmentSubType : string;
        public DeliveryType : string;
        public r_CountryCode : string;
        public DeliveryMode : string;
        public rate : number;
        public rate_fee_meest : number;
        public rate_fee_agent : number;
        public rate_fee_ws : number;
        public delivery_meest : number;
        public delivery_agent : number;
        public delivery_ws : number;
        public primaryTC : string;
        public secondTC : string;
        public ins_meest : number;
        public ins_agent : number;
        public ins_ws : number;
        public ExchangeRate : number;
        public TotalCustomerOwe_USD : number;
        public TotalCustomerOwe_CAD : number;
        public notes : string;
        public meest_own : number;
        public agent_own : number;
        public ws_own: number;
        public RateName: string;
        public Packaging_meest : number;
        public Packaging_agent : number;
        public Packaging_ws : number;
        public Customs_meest : number;
        public Customs_agent : number;
        public Customs_ws: number;
        public DiscountID: number;
        public carrier: number;
    public pickup: number;
        public Discount_00: string;
}
