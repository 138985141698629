import { Component, OnInit, Input, ViewChild, Output, EventEmitter  } from '@angular/core';
import { Router } from '@angular/router';

import intlTelInput from 'intl-tel-input';
import { initAutocomplete, initAutocomplete_s, restrictCountry, buildSenderAddress, getAddressComponent } from './new-parcel/autocompleteAddress';

import { QuestionService } from './question.service';
import { QuestionBase } from './question-base';
import { DataService } from './data.service';

declare var $: any;

@Component({
    selector: 'sender-receiver',
    template: `
            <app-dynamic-form *ngIf="!isMobile" #form [questions]="questions_sender" [questionsCol2]="questions_receiver" [horizontal]="true" [txtOK]="txtOK" (onSubmitted)="submitted($event)" (onCheckChange)="onCheckChange($event)" (oninputChange)="oninputChange($event)" (onInited)="onFormInited($event)">
            </app-dynamic-form>
            <!--[auxButtons]="[{text: 'Locate Agent'}]" (onAuxButtonClicked)="locateAgent()"-->
            <app-dynamic-form *ngIf="isMobile" #form [questions]="questions" [horizontal]="true" [txtOK]="txtOK" (onSubmitted)="submitted($event)" (onCheckChange)="onCheckChange($event)" (oninputChange)="oninputChange($event)" (onInited)="onFormInited($event)"></app-dynamic-form>
`,
    styles: [`
    `]
})
export class SenderReceiverComponent implements OnInit {
    errorMessage: string;
    @ViewChild('form', { static: false }) formComp;
    @Input() parcel: any = {};
    @Input() isMobile: boolean;
    questions: QuestionBase<any>[];
    questions_sender: QuestionBase<any>[];
    qsby: QuestionBase<any>[] = [];
    qsad: QuestionBase<any>[] = [];
    questions_receiver: QuestionBase<any>[];
    qrby: QuestionBase<any>[] = [];
    qrad: QuestionBase<any>[] = [];
    @Input() countries: any[];
    @Input() countryStates: any[];
    @Input() oblasts: any[];
    doSender: number;
    page3: any;
    @Input() address: any;
    @Output() addressChange = new EventEmitter<string>();
    txtOK = 'Next';
    sender_map = {
        sapartn: 'u_apart', scity: 'u_city', scountry: 'u_country', semail: 'u_email', sfname: 'u_firstnm', slname: 'u_lastnm', soblast: 'u_oblast', sphone: 'u_phone', sregion: 'u_region', sstreet: 'u_street', sstreetn: 'u_house', szip: 'u_index'
    };
    @Output() onSubmitted = new EventEmitter<string>();

    constructor(public router: Router, private service: QuestionService, private dataService: DataService) { }


    ngOnInit() {
        this.questions_sender = this.service.getQuestionsSender().map((q) => { return Object.assign({}, q); });
        let ix = this.questions_sender.findIndex(x => x.key.indexOf('titleaddr') > 0);
        for (var i = 0; i < ix; i++)
            this.qsby.push(this.questions_sender[i]);
        for (var i = ix; i < this.questions_sender.length; i++)
            this.qsad.push(this.questions_sender[i]);

        this.questions_receiver = this.service.getQuestionsReceiver().map((q) => { return Object.assign({}, q); });
        ix = this.questions_receiver.findIndex(x => x.key.indexOf('titleaddr') > 0);
        for (var i = 0; i < ix; i++)
            this.qrby.push(this.questions_receiver[i]);
        for (var i = ix; i < this.questions_receiver.length; i++)
            this.qrad.push(this.questions_receiver[i]);
        //zip(this.dataService.insertQuestions(this.questions_sender, 'ParcelSender'), this.dataService.insertQuestions(this.questions_receiver, 'ParcelReceiver')).subscribe((obl: any[]) => {
        //},
        //  (err: any) => {
        //    this.errorMessage = this.dataService.processError(err);
        //  });

        if (this.parcel.pickupAddress) {
            this.parcel.rcountrycode = this.parcel.pickupAddress.CountryCode;
            var rq = this.oblasts.find(x => x.r_region == this.parcel.pickupAddress.Oblast);
            if (rq)
                this.parcel.roblast = rq.Id;
            //this.parcel.rcity = this.parcel.pickupAddress.City;
            //this.parcel.rstreet = this.parcel.pickupAddress.Address;
            //this.parcel.rstreetn = this.parcel.pickupAddress.House;

            rq = this.questions_receiver.find(x => x.key == 'rcity'); rq.value = this.parcel.pickupAddress.City; rq["readonly"] = true;
            rq = this.questions_receiver.find(x => x.key == 'rstreet'); rq.value = this.parcel.pickupAddress.Address; rq["readonly"] = true;
            rq = this.questions_receiver.find(x => x.key == 'rstreetn'); rq.value = this.parcel.pickupAddress.House; rq["readonly"] = true;
        }

        var rc = this.questions_receiver.find(x => x.key == 'rcountry');
        rc.controlType = 'textbox';
        rc["readonly"] = true;
        var cc = this.countries.find(x => x.CountryCodeValue == this.parcel.rcountrycode && x.CountryCodeType != 'DropDownTable');
        if (cc)
            rc.value = cc.r_country;
        else {
            cc = this.oblasts.find(x => x.Id == this.parcel.roblast);
            if (cc) {
                rc.value = cc.r_country;
                rc = this.questions_receiver.find(x => x.key == 'roblast');
                rc.value = cc.r_region;
                rc["readonly"] = true;
            }
        }

        if (this.dataService.isGuest) 
            this.questions_sender.find(x => x.key == 'semail')["required"] = true;

        var receivingCountry = this.countries.find(x => x.CountryCodeValue == this.parcel.rcountrycode || x.r_country == this.parcel.rcountry);
        if (receivingCountry && receivingCountry.ValidationRequired) 
            this.questions_receiver.find(x => x.key == 'remail')["required"] = true;
        else
            this.questions_receiver.find(x => x.key == 'remail').label += ' (optional)';

        if (this.dataService.userData.UserData.u_type == 'CUSTOMER') {
            let scv = '';
            for (var key in this.sender_map) {
                let q = this.questions_sender.find(x => x.key == key);
                if (q && !(this.dataService.isGuest && this.qsby.find(x => x.key == key))) {
                    q.value = this.dataService.userData.UserData[this.sender_map[key]];
                    if (key == 'scountry') {
                        q.value = q.value.toUpperCase();
                        scv = q.value;
                    }
                }
            }
            this.onCheckChange({ key: 'scountry', value: scv });
            var qx = this.questions_sender.find(x => x.key == 'soblast');
            var cl = this.countryStates[scv];
            if (cl && qx && qx.value && qx.value != '') {
                let v = qx.value.toUpperCase();
                let sn = cl.find(x => x.state == v || x.desc.toUpperCase() == v);
                if (sn)
                    qx.value = sn.state;
            }
        }

        if (this.isMobile) {
            this.questions_sender.filter(x => x.containerClass == 'h0').forEach(x => x.containerClass = null);
            this.questions_receiver.filter(x => x.containerClass == 'h0').forEach(x => x.containerClass = null);

            this.doSender = 1;
            this.questions = this.qsby;  // this.questions_sender;
        }
    }

    onFormInited(page) {
        if (page === 'ParcelReceiver') {
            initAutocomplete(this.updateStateList.bind(this));
            restrictCountry(this.questions_receiver.find(x => x.key == 'rcountry').value);

            const input = document.querySelector("#rphone");
            intlTelInput(input, {
                allowDropdown: true,
                initialCountry: "ua",
                separateDialCode: true,
                onlyCountries: this.countries.map(x => x.CountryCode2)
            });
}
        if (page === 'ParcelSender')
            initAutocomplete_s(this.updateStateList.bind(this));
    }

    updateStateList(country, stateprov, elems) {
        if (elems) {
            elems.forEach(x => this.formComp.form.controls[x.key].patchValue(x.value));
        }
        else {
            if (!this.countryStates[country.toUpperCase()])
                return;

            this.questions_sender.find(x => x.key == 'scountry').value = country.toUpperCase();
            this.formComp.updateQuestions(['scountry']);

            var qx = this.questions_sender.findIndex(x => x.key == 'soblast');
            this.questions_sender[qx]['options'] = this.countryStates[country.toUpperCase()].map(c => {
                return { key: c.state, value: c.desc }
            });

            //this.questions_sender[qx] = Object.assign({ value: stateprov }, this.questions_sender[qx]);
            this.questions_sender[qx].value = stateprov;
            this.formComp.updateQuestions(['soblast']);
        }
        return false;
    }

    onCheckChange(arg: any) {
        if (arg.key == 'rcountry' && arg.value !== '') {
            let c = this.questions.find(x => x.key == 'rcountry');
            var qx = this.questions.findIndex(x => x.key == 'roblast');
            let q = this.questions[qx];
            q.value = arg.value;
            if (this.countries.find(x => x.CountryCodeValue == arg.value).CountryCodeType === 'DropDownTable') {  // && c['getList']) {
                this.dataService.getOblast(arg.value).subscribe((obl: any[]) => {
                    this.oblasts = obl;
                    q['options'] = obl.map(c => {
                        return { key: c.Id, value: c.r_region }
                    });
                    q['options'].splice(0, 0, { key: '', value: 'Select Oblast' });
                    q.hidden = false;
                    q.required = true;
                    this.questions[qx] = Object.assign({}, q);
                })
            } else {
                this.oblasts = [];
                q.hidden = true;
                this.questions[qx] = Object.assign({}, q);
            }
        }

        if (arg.key == 'scountry' && arg.value !== '') 
            this.setProvince('soblast', (this.isMobile && this.questions) ? this.questions : this.questions_sender, arg.value);
    }

    setProvince(kprov: string, quest: QuestionBase<any>[], val: any) {
        if (this.countryStates[val.toUpperCase()]) {
            var qx = quest.findIndex(x => x.key == kprov);
            quest[qx]['options'] = this.countryStates[val.toUpperCase()].map(c => {
                return { key: c.state, value: c.desc }
            });

            quest[qx] = Object.assign({}, quest[qx]);
        }
    }

    oninputChange(e: any) {
        var item = e.target;
        if (item.id == 'sphone') {
            this.dataService.getPhoneSender(item.value).subscribe((addr: any) => {
                if (addr) {
                    this.formComp.form.controls['sphone'].setValue(addr.s_phone);
                    this.formComp.form.controls['semail'].setValue(addr.s_email);
                    this.formComp.form.controls['sfname'].setValue(addr.s_firstnm);
                    this.formComp.form.controls['slname'].setValue(addr.s_lastnm);
                    if (!this.dataService.isGuest) {
                        if (this.isMobile) {
                            this.qsad.find(x => x.key == 'sapartn').value = addr.s_apart;
                            this.qsad.find(x => x.key == 'scity').value = addr.s_city;
                            this.qsad.find(x => x.key == 'scountry').value = addr.s_country;
                            this.qsad.find(x => x.key == 'sstreetn').value = addr.s_house;
                            this.qsad.find(x => x.key == 'szip').value = addr.s_index;
                            this.qsad.find(x => x.key == 'soblast').value = addr.s_oblast;
                            this.qsad.find(x => x.key == 'sstreet').value = addr.s_street;
                        }
                        else {
                            this.formComp.form.controls['sapartn'].setValue(addr.s_apart);
                            this.formComp.form.controls['scity'].setValue(addr.s_city);
                            this.formComp.form.controls['scountry'].setValue(addr.s_country);
                            this.formComp.form.controls['sstreetn'].setValue(addr.s_house);
                            this.formComp.form.controls['szip'].setValue(addr.s_index);
                            this.formComp.form.controls['soblast'].setValue(addr.s_oblast);
                            //this.formComp.form.controls['sregion'].setValue(addr.s_region);
                            this.formComp.form.controls['sstreet'].setValue(addr.s_street);

                            var sc = this.formComp.form.controls['scountry'].value;
                            if (this.countryStates[sc.toUpperCase()]) {
                                var qx = this.questions_sender.findIndex(x => x.key == 'soblast');
                                this.questions_sender[qx]['options'] = this.countryStates[sc.toUpperCase()].map(c => {
                                    return { key: c.state, value: c.desc }
                                });

                                this.questions_sender[qx] = Object.assign({}, this.questions_sender[qx]);
                            }
                        }
                    }
                }
            });
        }
        else if (item.id == 'rphone' && item.value && item.value.length >= 10) {
            this.dataService.getPhoneRecipient(item.value).subscribe((addr: any) => {
                if (addr) {
                    this.formComp.form.controls['rphone'].setValue(addr.r_phone);
                    this.formComp.form.controls['remail'].setValue(addr.r_email);
                    this.formComp.form.controls['rfname'].setValue(addr.r_firstnm);
                    this.formComp.form.controls['rlname'].setValue(addr.r_lastnm);
                    if (!this.parcel.pickupAddress && !this.isMobile) {
                        this.formComp.form.controls['rapartn'].setValue(addr.r_apart);
                        this.formComp.form.controls['rcity'].setValue(addr.r_city);
                        //this.formComp.form.controls['rcountry'].setValue(addr.r_country);
                        this.formComp.form.controls['rstreetn'].setValue(addr.r_house);
                        //this.formComp.form.controls['rzip'].setValue(addr.r_index);
                        //this.formComp.form.controls['roblast'].setValue(addr.r_oblast);
                        this.formComp.form.controls['rregion'].setValue(addr.r_region);
                        this.formComp.form.controls['rstreet'].setValue(addr.r_street);
                    }
                    else if (!this.parcel.pickupAddress && this.isMobile) {
                        this.qrad.find(x => x.key == 'rapartn').value = addr.r_apart;
                        this.qrad.find(x => x.key == 'rcity').value = addr.r_city;
                        this.qrad.find(x => x.key == 'rstreetn').value = addr.r_house;
                        this.qrad.find(x => x.key == 'rstreet').value = addr.r_street;
                    }
                }
            });
        }
    }

    submitted(payLoad: string) {
        if (payLoad && payLoad !== '') {
            if (this.isMobile) {
                if (this.doSender) {
                    if (this.doSender < 4) {
                        switch (this.doSender) {
                            case 1: this.questions = this.qsad;
                                this.page3 = JSON.parse(payLoad);
                                this.doSender++;
                                break;
                            case 2: this.questions = this.qrby;
                                Object.assign(this.page3, JSON.parse(payLoad));
                                this.address = buildSenderAddress();
                                this.addressChange.emit(this.address);
                                this.doSender++;
                                break;
                            case 3: this.questions = this.qrad;
                                Object.assign(this.page3, JSON.parse(payLoad));
                                this.doSender = 0;
                                break;
                        }
                        //this.questions = this.questions_receiver;
                        $('html, body').animate({ scrollTop: '0px' }, 300);
                    }
                    return;
                }
                else {
                    let rcvr = JSON.parse(payLoad);
                    Object.assign(this.page3, rcvr);
                }
            }
            else {
                this.page3 = JSON.parse(payLoad);
                this.address = buildSenderAddress();
                this.addressChange.emit(this.address);
            }

            this.onSubmitted.emit(JSON.stringify(this.page3));
        }
        else
            this.onSubmitted.emit(payLoad);
    }
}
