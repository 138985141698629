import { AfterViewInit, Component, ViewChild, ViewContainerRef, ElementRef, ViewEncapsulation } from "@angular/core";
import { Observable, Observer ,  noop, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ICellRendererAngularComp, ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";

import { DataService, ITypeAsync } from './data.service';

@Component({
    selector: 'typeahead-cell',
    encapsulation: ViewEncapsulation.None,
    host: { style: `position: absolute;
					left: 0px; 
					top: 0px;
					background-color: transparent;
					` },
    template: `
           <input #newitemDesc class="item-grid-input" [(ngModel)]="value" style="height:28px" [style.width]="params.column.actualWidth + 'px'" placeholder="start typing your items to activate autofill"
                [typeahead]="suggestions$['ItemName']"  typeaheadOptionField="name" [typeaheadAsync]="true" [typeaheadOptionsLimit]="20" (typeaheadOnSelect)="onItemSelect($event)" autocomplete="off">
    `,
    styles: [
        `.ag-root-wrapper {overflow-y: visible !important}
        `
    ]
})
export class TypeaheadEditor implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value: string;
    public text: string;
    private cancelBeforeStart: boolean = false;
    private cancelAfterEnd: boolean = false;

    suggestions$: ITypeAsync = {};
    typeable: string[] = ['ItemName'];

    @ViewChild('newitemDesc', { static: false }) public input: ElementRef;

    constructor(private dataService: DataService) { }

    agInit(params: ICellEditorParams): void {
        this.params = params;
        this.text = this.params.value;
        this.value = this.params.value;

        for (var f of this.typeable)
            this.suggestions$[f] = this.setAsync(f);
    }

    setAsync(field: string): Observable<any> {
        return new Observable((observer: Observer<string>) => {
            observer.next(this.value);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    //this.errorMessage = '';
                    return this.dataService.getTypeahead(query)
                        .pipe(
                            tap(() => noop, err => {
                                //this.errorMessage = this.dataService.processError(err);
                            })
                        );
                }

                return of([]);
            })
        );
    }

    getValue(): any {
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    isCancelAfterEnd(): boolean {
        return this.cancelAfterEnd;
    };

    onItemSelect(event): void {
        this.value = event.item.name;
        this.params.context.selectedItems[this.params.rowIndex] = event.item;
        setTimeout(() => this.params.api.stopEditing());

    //    if (this.params.onAdded && this.value) {
    //        var f = this.params.onAdded.bind(this.params.context);
    //        setTimeout(() => f());
    //    }
    }

    //onChanged(ev) {
    //    if (this.text != this.value)
    //        this.params.context.selectedItems[this.params.rowIndex] = null;
    //}

    isPopup(): boolean {
        return true;
    }

    focusIn() {
        if (this.params.focus)
            this.input.nativeElement.focus();
    }

    ngAfterViewInit() {
        if (this.params.focus)
            setTimeout(() => {
                this.input.nativeElement.focus();
            })
    }
}
