import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

export class MessageParameters {
  topic: string;
  value: number;
  text: string;
}

@Injectable()
export class MessengerService {
  private messageSource: BehaviorSubject<MessageParameters> = new BehaviorSubject({ topic: '', value: 0, text: '' });
  public message = this.messageSource.asObservable();
  public statusChanged(value: MessageParameters) {
    this.messageSource.next(value);
  }
}
