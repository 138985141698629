import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//import { } from 'googlemaps';

declare var google: any;
import { DataService } from '../data.service';

@Component({
  selector: 'app-modal-email-carrier',
    template: `
        <div class="modal-header">
          <h4 class="modal-title pull-left">Agent Location</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <ng-template #tplloading>
            <div class="text-center">
              <img src="assets/images/ajax-loader.gif" style="margin:30px;" />
            </div>
          </ng-template>

          <div>
            <span style="color:red;font-size:1.2em;margin-left:10px">{{errorMessage}}</span>
          </div>

          <div *ngIf="!loading; else tplloading">
                <div id="map" style="width: 100%; height: 520px;"></div>
          </div>
        </div>
        <div class="modal-footer" style="border-top: 0; text-align: left">
          <div *ngIf="selectedAgent" style="display: inline-block">
            <span style="font-weight:bold; color:#0061af">{{agentAddr}}</span><br/>
          </div>
                <button class="btn btn-warning active" type="button" style="margin-left:8px; float:right" (click)="cancel()">Cancel</button>
                <button class="btn btn-primary" type="button" style="float:right" (click)="bsModalRef.hide()" [disabled]="!selectedAgent">Select</button>
        </div>
`,
    styles: [`
`]
})
export class ModalMapComponent implements OnInit {
  errorMessage: string;
  loading: boolean;
    cancelled: boolean;
  country: string;
    map: any;
    location: any;
    address;
    agentAddr: string;
    selectedAgent: any;
    selectedMarker: any;
    user_addrComp: any = {};
    agent_addrComp: any = {};
    url = "https://maps.google.com/mapfiles/ms/icons/";
    selectedPostService: number;

    constructor(public bsModalRef: BsModalRef, public router: Router, private dataService: DataService, public zone: NgZone) { }


    ngOnInit() {
        this.loading = false;
        this.cancelled = false;
    }

    show() {
        this.dataService.getAgentList(this.country, 0, 0).subscribe((ui: any[]) => {
            var mapOptions = { zoom: 10 };

            this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
            this.map.setCenter(this.location);
            //var marker = new google.maps.Marker({
            //    map: this.map,
            //    position: this.location
            //});

            var cityCircle = new google.maps.Circle({
                strokeColor: '#0000FF',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#0000FF',
                fillOpacity: 0.35,
                map: this.map,
                center: this.location,
                radius: 100
            });

            for (var ag of ui) {
                var marker = new google.maps.Marker({
                    position: { lat: Number(ag.latitude), lng: Number(ag.longitude) },
                    icon: this.url + "red-dot.png",
                    title: ag.agentname
                });
                marker.setMap(this.map);

                this.handleMarker(ag, marker);
            }
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }

    handleMarker(agent, marker) {
        var ag = Object.assign({}, agent);
        marker.addListener('click', (event) => {
            //console.log(ag);
            let color = '';
            if (this.selectedMarker != null) {
                color = 'red';
                this.selectedMarker.setIcon(this.url + color + "-dot.png");
            }

            color = 'green';
            marker.setIcon(this.url + color + "-dot.png");

            let addrComp = this.dataService.parseAgentAddress(ag, this.agent_addrComp);
            let agphone = ag.phone1 ? `, ${ag.phone1}` : (ag.phone2 ? `, ${ag.phone2}` : '');
            this.agentAddr = addrComp.length > 0 ? addrComp.join(', ') + agphone : '';
            this.selectedMarker = marker;
            this.selectedPostService = -1;
            this.zone.run(() => {
                this.selectedAgent = ag;
                //this.getRates();
            });
        });
    }

    cancel() {
        this.cancelled = true;
        this.bsModalRef.hide();
    }

    get getData() {
        return this.cancelled ? null : JSON.stringify({ userAddress: this.user_addrComp, agentAddress: this.agent_addrComp, agent: this.selectedAgent });  //, rate: this.rates[this.selectedPostService] });
    }
}
