import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";

import { ICellRendererAngularComp, ICellEditorAngularComp } from "ag-grid-angular";

@Component({
  selector: 'currency-cell',
  template: `{{convert()}}`
})
export class DropdownRenderer implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  convert() {
    if (this.params.value == '')
      return '';
    var opt = this.params.options.find(x => x.key == this.params.value);
    return opt ? opt.value : '***';
  }
}

@Component({
  selector: 'numeric-cell',
  template: `
            <select #input [(ngModel)]="value" (change)="checkChange($event)" style="width: 100%">
              <option *ngFor="let opt of options" [value]="opt.key">{{opt.value}}</option>
            </select>
  `
})
export class DropdownEditor implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: string;
  public options: { key: string, value: string }[] = [];
  private cancelBeforeStart: boolean = false;
  private cancelAfterEnd: boolean = false;

  @ViewChild('input', { read: ViewContainerRef, static: false }) public input;


  agInit(params: any): void {
    this.params = params;
    this.options = this.params.options;
    this.value = this.params.value;
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    return this.cancelAfterEnd;
  };

  checkChange(event): void {
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    })
  }
}
