import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";
import Quagga from 'quagga/dist/quagga';

@Component({
  selector: 'numeric-cell',
  template: `
            <div #container tabindex="0" style="height:100%; width:80vw" (keydown)="onKeyDown($event)">
              <label>Barcode <input #input type="text" [(ngModel)]="value" style="width: 250px"></label><br />
              <button class="btn btn-success" (click)="scanBarcode($event)">Scan Barcode</button>

              <div *ngIf="showScanner">
                <div id="interactive" class="viewport"></div>
              </div>
            </div>
  `
})
export class DeclarationEditor implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: string;
  public options: { key: string, value: string }[] = [];
  private cancelBeforeStart: boolean = false;
  private cancelAfterEnd: boolean = false;
  private popup: boolean = true;
  showScanner: boolean;

  @ViewChild('input', { read: ViewContainerRef, static: false }) public input;


  agInit(params: any): void {
    this.params = params;
    this.options = this.params.options;
    this.value = this.params.value;
    this.showScanner = false;
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    return this.cancelAfterEnd;
  };

  isPopup() {
    return this.popup;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    })
  }

  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    alert(key);
    if (key == 37 ||  // left
      key == 39) {  // right
      event.stopPropagation();
    }
  }

  scanBarcode(e: any) {
    e.stopPropagation();
    e.preventDefault();

    this.showScanner = !this.showScanner;
    if (this.showScanner)
      setTimeout(() => this.startQuagga());
    else
      Quagga.stop();

    return false;
  }

  startQuagga() {
    let me = this;

    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        //target: document.querySelector('#yourElement')    // Or '#yourElement' (optional)
      },
      decoder: {
        //readers: ["codabar_reader"]
        readers: ["code_128_reader"]
      }
    }, function (err) {
      if (err) {
        console.log(err);
        me.showScanner = false;
        alert(err);
        return;
      }
      console.log("Initialization finished. Ready to start");
      Quagga.start();
    });

    Quagga.onProcessed((result) => {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
          result.boxes.filter(function (box) {
            return box !== result.box;
          }).forEach(function (box) {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
          });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
        }
      }
    });

    Quagga.onDetected((result) => {
      this.value = result.codeResult.code;
      Quagga.stop();
      this.showScanner = false;
    });
  }
}
