exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media print {\r\n    .guest-print { display: none; }\r\n}\r\n\r\n#invoiceContainer {\r\n    font: 70%/150% Arial, Helvetica, sans-serif;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -ms-flex-pack: center;\r\n        justify-content: center;\r\n}\r\n\r\n    #invoiceContainer td, #invoiceContainer th {\r\n        padding: 0 !important;\r\n        vertical-align: top !important;\r\n    }\r\n\r\n    #invoiceContainer .label4x8 td, #invoiceContainer .label4x8 th {\r\n        padding: 0 0 10px 0 !important;\r\n    }\r\n\r\n#invoiceContainer tr:nth-child(even) {\r\n    background-color: white !important;\r\n}\r\n    #invoiceContainer caption { text-align: center; }\r\n    #invoiceContainer table {\r\n        border-spacing: 3px;\r\n    }\r\n    #invoiceContainer .InvoiceTopRow {\r\n        background-color: white;\r\n        text-decoration: none;\r\n        outline: none;\r\n        color: black;\r\n        font-family: Calibri,Arial;\r\n        font-size: 1em;\r\n        font-style: normal;\r\n        font-weight: normal;\r\n        text-align: left;\r\n    }\r\n    #invoiceContainer #inv-header {\r\n        margin: 0 auto;\r\n    }\r\n#invoiceContainer b { font-weight: bold !important;}\r\n    #invoiceContainer hr {\r\n        border: 1px solid #888;\r\n        margin-top: 5px;\r\n        margin-bottom: 5px;\r\n    }\r\n\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();