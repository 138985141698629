import { Component, OnChanges, Input } from '@angular/core';
import { trigger, state, animate, transition, style } from '@angular/animations';

@Component({
  selector: 'my-fader',
  animations: [
    trigger('isVisibleChanged', [
      state('true', style({ opacity: 1, transform: 'scale(1.0)' })),
      state('false', style({ opacity: 0, transform: 'scale(0.0)' })),
      transition('true => false', animate('900ms')),
      transition('false => true', animate('300ms'))
    ])
  ],
  template: `
    <div [@isVisibleChanged]="isVisible" class="popup" >
      <ng-content></ng-content>    
      <p>Please review and sign your invoice.</p>
    </div>
  `,
  styles: [`
      .popup { position:fixed;top:30vh;left:10%;border:2px solid red;background:#fbc39a;border-radius: 5px;padding:10px 20px }
  `]
})
export class FaderComponent {
  @Input() isVisible: string = 'true';
}
