import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import './../node_modules/jquery/dist/jquery.min.js';
import './../node_modules/jquery-ui/ui/widget.js';
import './../node_modules/jquery-ui/ui/widgets/datepicker.js';
import './../node_modules/jquery-ui/ui/widgets/dialog.js';
import "./../../MeestMVC/Scripts/MeestFunctions.js";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
