import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpRequest, HttpResponse, HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpHeaders,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { DataService } from './data.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private dataService: DataService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = request.headers.has('TargetService') ? request.headers : request.headers.set('TargetService', 'MeestPortal');
        if (this.dataService.userData)
            headers = headers.set("authHash", this.dataService.userData.Hash);

        request = request.clone({
            headers: headers
        });

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        },
            (err: any) => {
            //if (err instanceof HttpErrorResponse) {
            //    if (err.error && err.error.ExceptionMessage === 'Token error') {
            //        this.state.token = null;
            //        this.state.loggedIn = false;
            //        this.router.navigate(['login']);
            //    }
            //}
        }));
    }
}
