import { Component, Input, Directive, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject, zip } from 'rxjs';
import { GridOptions } from "ag-grid-community/main";
import { ModalDirective } from 'ngx-bootstrap/modal';
declare var $: any;

//import * as ScanditSDK from "scandit-sdk/build/browser";
import Quagga from 'quagga/dist/quagga';

import { DeclarationEditor } from './../declaration-editor.component';
import { SlideInOutAnimation } from './../animations';
import { DataService, UserCredentials } from './../data.service';
import { Parcel } from './../parcel-model';

@Component({
  selector: 'app-home',
  animations: [SlideInOutAnimation],
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.css']
})
export class HomeComponent {
  title = 'app';
    errorMessage: string;
    initial: boolean = false;
  user: UserCredentials;
  userinfo: string;
  currentPage: string = '';
  currentStudentId: number;
  animationState = 'out';
  //animationPageState = 'in';
  searchSource: string;
  invoiceid: number;
    selectedRow: number = -1;
  edited: any = {};
    @ViewChild('childModal', { static: false }) public childModal: ModalDirective;
  showScanner: boolean = false;
    barResult: string;
    rowState: boolean[] = [];
    rowState7: boolean[] = [];

  @Input() searchParameters: any;

  private gridOptions: GridOptions;
  public rowData: any[];
    public rowData7: any[];
  private columnDefs: any[];
  public gridApi;
  private gridColumnApi;
  private frameworkComponents;

  constructor(private dataService: DataService, private datePipe: DatePipe, private sanitizer: DomSanitizer, private router: Router, private route: ActivatedRoute) {
    this.gridOptions = <GridOptions>{
      enableFilter: true,
      enableColResize: true,
      enableSorting: true,
      //pagination: true,
      //paginationPageSize: 20,
      //paginationAutoPageSize: true,
      context: { dateFormat: 'short', datePipe: this.datePipe },
    };

    this.columnDefs = [
      { headerName: "Declaration", field: "code_a", filter: "agTextColumnFilter", editable: false, cellEditor: "declarationEditor" },
      { headerName: "A/S", field: "s_a_e" },
      { headerName: "Items", field: "packages", width: 80 },
      { headerName: "Last Name", field: "s_lastnm", filter: "agTextColumnFilter" },
      { headerName: "Street", field: "s_street" },
      { headerName: "Phone", field: "s_phone", filter: "agTextColumnFilter" },
      { headerName: "E-mail", field: "s_email", filter: "agTextColumnFilter" },
      { headerName: "Own", field: "cust_own", valueFormatter: this.dataService.currencyFormatter },
      { headerName: "Status", field: "status" },
      { headerName: "Status Date", field: "statusDT", sort: "desc", valueFormatter: this.dataService.dateFormatter },
    ];

    this.frameworkComponents = { declarationEditor: DeclarationEditor };

    this.rowData = [];
  }

  ngOnInit() {
    this.currentPage = this.dataService.loggedIn ? 'parcels' : 'login';

    this.route.url.subscribe(url => {
      this.rowData = [];

        if (this.dataService.loggedIn) {
            this.user = this.dataService.userData;
            this.currentPage = 'parcels';

            let UserBranch = this.dataService.userData.UserData["u_country"].toUpperCase() == 'USA' ? 2 : 1;
            this.userinfo = `${this.user.UserData.u_firstnm} ${this.user.UserData.u_lastnm} (${this.user.UserData.customerCD} ID:${this.user.UserData.id})`;
            if (this.dataService.userData.UserData.u_type != 'CUSTOMER') {
                this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.dataService.userData.UserData.branchID);
            }
            else {
                if (this.dataService.userData.UserData["branchID"])
                    UserBranch = this.dataService.userData.UserData["branchID"];
                this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == UserBranch);
            }

            this.reload();
        }
        else
            this.router.navigate(["login"]);
    });
  }

    clickDots(ix: number) {
        let b = !this.rowState[ix];
        for (var i = 0; i < this.rowData.length; i++) 
            this.rowState[i] = false;
        this.rowState[ix] = b;
    }

    clickDots7(ix: number) {
        this.rowState7[ix] = !this.rowState7[ix];
    }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    //this.autoSizeAll(params.columnApi);
  }

  onRowDataChanged(params) {
    //params.columnApi.autoSizeColumns();
    this.autoSizeAll(params.columnApi);
  }

  autoSizeAll(colapi: any) {
    var allColumnIds = [];
    colapi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId);
    });
    colapi.autoSizeColumns(allColumnIds);
  }

  get selectedParcel(): any {
    if (this.selectedRow < 0) // (!this.gridApi || this.gridApi.getSelectedRows().length == 0)
      return null;
    else
          return this.rowData[this.selectedRow];  // this.gridApi.getSelectedRows()[0];
  }

    reload() {
        this.rowState = [];
        this.rowState7 = [];
        this.rowData7 = [];
        this.edited = {};
        this.errorMessage = '';
        zip(this.dataService.getParcels(), this.dataService.getDomesticAvailable()).subscribe((ui: any[]) => {
            this.rowData = ui[0];
            if (this.dataService.appService == 'Parcels')
                this.rowData = this.rowData.filter(x => x.ShipmentType == 'PARCEL');
            else if (this.dataService.appService == 'Money')
                this.rowData = this.rowData.filter(x => x.ShipmentType == 'MONEY');

            this.dataService.DomesticAvailable = ui[1] === 1;
            var el: any = {};
            for (var i = 0; i < this.rowData.length; i++) {
                this.rowState.push(false);
                if (i % 7 == 0) {
                    el = { top: this.rowData[i], bot: [] };
                    this.rowData7.push(el);
                    this.rowState7.push(false);
                }
                else
                    el.bot.push(this.rowData[i]);
            }
          //this.gridApi.setRowData(ui);
          //params.api.sizeColumnsToFit();

          //setTimeout(() => {
            //this.autoSizeAll();
          //});
    },
      (err: any) => {
        this.errorMessage = this.dataService.processError(err);
      });
  }

    labelInvoice(id: number) {
        window.open(`${this.dataService.siteURL}Agent/PrintPostLabel/${id}?partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 0}-${this.dataService.userData.ID}`, '_blank');
    }

    doTrack(sel: any) {
        if (sel.code_a.startsWith('CV'))
            window.open(`https://ca.meest.com/parcel-track?parcel_number=${sel.code_a}`, '_blank');
        else if (sel.code_a.startsWith('CH'))
            window.open(`https://ca.meest.com/parcel-track?parcel_number=${sel.code_a}`, '_blank');
    }

  parcelSubmitted(parcel: Parcel) {
    if (parcel) {
      //window.open(`${this.dataService.siteURL}Agent/Invoice/${parcel.id}?partial=${this.dataService.userData.Branch.id}-${this.user.UserData.id}`, '_blank');
      //this.pageParcel.reload();
      //this.currentPage = 'parcels';
      //this.invoiceid = parcel.id;
      //this.currentPage = 'invoice';
      this.router.navigate(["invoice", parcel.id, 0]);
    }
    this.router.navigate(["home"]);
    //this.animationState = this.animationState === 'out' ? 'in' : 'out';
    //this.animationPageState = this.animationState === 'out' ? 'in' : 'out';
  }


    doInvoice(sel: any) {
        this.router.navigate(["invoice", sel.id, sel.ShipmentType == 'MONEY' ? '1' : '0']);
    }

    doPayment(sel: any) {
        this.router.navigate(["payment", sel.id, sel.ShipmentType == 'PARCEL' ? 0 : 1]);
    }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.edited = {};
    this.childModal.hide();
  }

  doSave() {
    this.barResult = '';
    this.dataService.saveBarcode(this.edited.id, this.edited.code_a).subscribe((ui: string) => {
      this.barResult = ui;
      if (!ui || ui == '') {
        this.gridApi.updateRowData({ update: this.edited });
        this.hideChildModal();
      }
    },
      (err: any) => {
        this.barResult = this.dataService.processError(err);
      });
  }

  scanBarcode(e: any) {
    e.stopPropagation();
    e.preventDefault();

    this.showScanner = !this.showScanner;
    if (this.showScanner)
      setTimeout(() => this.startQuagga());
    else
      Quagga.stop();

    return false;
  }

  startQuagga() {
    let me = this;

    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        //target: document.querySelector('#yourElement')    // Or '#yourElement' (optional)
      },
      decoder: {
        //readers: ["codabar_reader"]
        readers: ["code_128_reader"]
      }
    }, function (err) {
      if (err) {
        console.log(err);
        me.showScanner = false;
        alert(err);
        return;
      }
      console.log("Initialization finished. Ready to start");
      Quagga.start();
    });

    Quagga.onProcessed((result) => {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
          result.boxes.filter(function (box) {
            return box !== result.box;
          }).forEach(function (box) {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
          });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
        }
      }
    });

    Quagga.onDetected((result) => {
      this.edited.code_a = result.codeResult.code;
      Quagga.stop();
      this.showScanner = false;
    });
  }
}
/*
@Directive({ selector: '[runScripts]' })
export class RunScriptsDirective implements OnInit {
  constructor(private elementRef: ElementRef) { }
  ngOnInit(): void {
    setTimeout(() => { // wait for DOM rendering
      var t = $('#invoiceContainer').text();
      this.reinsertScripts();
    });
  }
  reinsertScripts(): void {
    const scripts = <HTMLScriptElement[]>this.elementRef.nativeElement.getElementsByTagName('script');
    const scriptsInitialLength = scripts.length;
    for (let i = 0; i < scriptsInitialLength; i++) {
      const script = scripts[i];
      const scriptCopy = <HTMLScriptElement>document.createElement('script');
      scriptCopy.type = script.type ? script.type : 'text/javascript';
      if (script.innerHTML) {
        scriptCopy.innerHTML = script.innerHTML;
      } else if (script.src) {
        scriptCopy.src = script.src;
      }
      scriptCopy.async = false;
      script.parentNode.replaceChild(scriptCopy, script);
    }
  }
} */
