import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[numbersOnly]'
})
export class NumberOnlyDirective {
    @Input() allowDecimals: boolean = false;
    @Input() allowSign: boolean = false;
    @Input() maxValue = 0;
    //private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
    //  Regular expressions
    integerUnsigned: string = '^[0-9]+$';
    integerSigned: string = '^-?[0-9]+$';
    decimalUnsigned: string = '^[0-9]+(.[0-9]?)?$';
    decimalSigned: string = '^-?[0-9]+(.[0-9]?)?$';

    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Escape', 'Delete' ];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        let regex: string;
        if (!this.allowDecimals && !this.allowSign) regex = this.integerUnsigned;
        if (!this.allowDecimals && this.allowSign) regex = this.integerSigned;
        if (this.allowDecimals && !this.allowSign) regex = this.decimalUnsigned;
        if (this.allowDecimals && this.allowSign) regex = this.decimalSigned;

        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = this.el.nativeElement.value;
        // We need this because the current value on the DOM element
        // is not yet updated with the value from this event
        let next: string = current.concat(event.key);
        if (next && (!String(next).match(regex) || (this.maxValue > 0 && Number(next) > this.maxValue))) {
            event.preventDefault();
        }
    }
}
