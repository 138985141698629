import { Component, OnInit, Input, NgZone, ViewChild, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, zip } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//import { } from 'googlemaps';

declare var google: any;
declare var $: any;
import '../../../node_modules/jquery/dist/jquery.min.js';
import '../../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import "../../../node_modules/bootstrap-select/dist/js/bootstrap-select.min.js";

import { initAutocomplete, initAutocomplete_s, restrictCountry, buildSenderAddress, getAddressComponent, restrictCountry_s } from '../new-parcel/autocompleteAddress';

import { QuestionService } from './../question.service';
import { QuestionBase } from './../question-base';
import { DataService } from '../data.service';

@Component({
  selector: 'popup-estimator',
  templateUrl: './popup-estimator-new.component.html',
  styleUrls: ['./popup-estimator.component.css']
})
export class PopupEstimatorComponent implements OnInit, AfterViewInit {
    UserBranch = 1;
    AgentCode = 'MEEST';
    errorMessage: string;
    loading: boolean;
    inited: boolean = false;
    answer: string = '';
    @Input() agentRates: boolean = false;
    @Input() noRates: boolean = false;
    @Input() shipType: string = 'I';
    @Input() compType: string = '1';
    @Output() doShop = new EventEmitter<any>();
    @Output() closed = new EventEmitter();
    @ViewChild('form', { static: false }) formComp;
    page1: any = {};
    deliverySelection: any[] = [];
    deliverySelectionUPS: any[] = [];
    deliverySelectionUSPS: any[] = [];
    deliverySelectionUPSPickup: any[] = [];
    deliverySelectionAgent: any[];
    closestAgent: any;
    closestAgentList: any[];
    location: any;  // google.maps.LatLng;
    domestic: any = {};
    expressShip: boolean = false;
    receivingCountry: any = {};
    pageMap: any = {};
    pageno = 1;
    saddress: any;
    raddress: any;
    user_addrComp: any = {};
    agent_addrComp: any = {};
    countries: any[];
    countryStates: any = {};
    countryFrom: string;
    stateFrom: string;
    oblasts: any[] = [];
    countryOpt: any[] = [];
    country: string;
    oblastOpt: any[] = [];
    oblast: string;
    showOblast: boolean;
    meestHub: string;
    txtOK = 'Calculate';
    txtCancel = null;
    isMobile: boolean;
    pageTitle: string;
    weight: number;
    height: number;
    width: number;
    length: number;
    estimated: boolean = false;
    pickups: any[] = [];
    WeightLimit: number;
    @ViewChild('weightLimit', { static: false }) weightLimit;
    minPostAir: number;
    minPostSea: number;
    minAgentAir: number;
    minAgentSea: number;
    selag: number;
    selrate: any;
    estimating: boolean = false;
    post_rates: any[] = [null, null, null, null, null];
    expressFixedRate: number = 0;
    //postSelected: boolean = false;
    //agentSelected: boolean = true;
    @Input() isModal: string = '';
    @Output() isEstimated = new EventEmitter();
    delSelected: number = 1;
    accActive: boolean[] = [false, false];
    sender_map = {
        sapartn: 'u_apart', scity: 'u_city', scountry: 'u_country', semail: 'u_email', sfname: 'u_firstnm', slname: 'u_lastnm', soblast: 'u_oblast', sphone: 'u_phone', sregion: 'u_region', sstreet: 'u_street', sstreetn: 'u_house', szip: 'u_index'
    };
    expressShipCountries: string[] = ['NGA', 'GHA', 'IND', 'PHI', 'AUT', 'CAN', 'VET', 'PAK', 'COL', 'MEX',
        , 'ARE'
        , 'ARG'
        , 'BOL'
        , 'BRA'
        , 'CHL'
        , 'ECU'
        , 'PER'
        , 'NZL'
        , 'IDN'
        , 'SAU'
        , 'DOM'
        , 'JAM'
        , 'ZAF'
        , 'TAJ'
        , 'TKM'
        , 'CHN'
        , 'JPN'
    ];

    constructor(public router: Router, private dataService: DataService, public zone: NgZone, private route: ActivatedRoute) {
        this.isMobile = this.dataService.isMobileDevice;
        if (!this.isMobile && window.innerWidth < 992)
            this.isMobile = true;

        this.route.queryParams.subscribe(params => {
            if (params['isModal'])
                this.isModal = params['isModal'];
        });
    }

    ngOnInit() {
        if (!this.dataService.userData)
            this.dataService.userData = { Code: "OOREG", Name: '', ID: 0, Permissions: [], Branch: null, Service: '', UserData: { u_RateGroup: 'OOREG', u_country: 'CANADA' }, Hash: '' };
    }

    ngAfterViewInit() {
        //setTimeout(() => {
        //    this.inited = true;
        //})
        if (!this.dataService.branches) {
            this.dataService.getBranches().subscribe((ui: any[]) => {
                this.dataService.branches = ui;
                this.init();
            });
        }
        else
            setTimeout(() => this.init());
    }

    @HostListener('window:message', ['$event'])
    handleExternalMessagen(e: any) {
        if (e.origin == 'http://accounting-mash.westeurope.cloudapp.azure.com/aspnet_client/site/') {
            e.source.postMessage(this.answer, e.origin)
        }
        else return;
    }

    init() {
        if (this.isModal === 'M')
            return;
        this.loading = false;

        this.UserBranch = this.dataService.userData.UserData["u_country"].toUpperCase() == 'USA' ? 2 : 1;

        if (!this.dataService.userData.Branch)
            this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.UserBranch);

        zip(this.dataService.getCountryState(), this.dataService.getCountries(), this.dataService.getPickupSites()).subscribe((res: any[]) => {
            this.countryStates = res[0];
            for (var c in this.countryStates)
                this.countryStates[c].splice(0, 0, { state: 'X', desc: 'Select State/Province' });
            this.countryStates["Select Country"] = [{ state: 'X', desc: 'Select State/Province' }];
            this.countryFrom = 'Select Country';
            this.stateFrom = 'X';
            //if (!this.noRates)
            //    this.onStateFromChange();

            this.countries = res[1];
            this.countries.forEach(x => x["expressShip"] = this.expressShipCountries.some(c => c == x.CountryCodeValue) ? 1 : 0);
            setTimeout(() => $('#rcountry').selectpicker());

            this.pickups = res[2];

            if (this.noRates && !this.dataService.isGuest) {
                this.countryOpt = this.countries.map(c => {
                    if (c.CountryCodeValue == 'ddt_CountryCodeParcels') {
                        c.CountryCodeValue = c.r_country;
                    }
                    return { key: c.CountryCodeValue, value: c.r_country, code: c.CountryCode2 }
                });
                this.countryOpt.splice(0, 0, { key: '', value: 'Select Country' });
                this.country = 'Ukraine';
            }
            this.showOblast = false;
            this.oblast = '*';

            this.pageTitle = this.noRates ? 'Enter Parcel Details' : 'Cost Estimator';

            //if (this.compType == '1') {
            //    initAutocomplete_s(this.updateSenderAddress.bind(this), 'g_autocomplete_s');
            //    if (this.shipType == 'I')
            //        restrictCountry_s(['us', 'ca']);
            //    else {
            //        initAutocomplete_s(this.updateDomesticAddress.bind(this), 'g_autocomplete_r');
            //        restrictCountry_s(['ca']);
            //    }
            //}

            if (this.agentRates && !this.dataService.isGuest) {
                var addrComp = this.dataService.parseUserAddress(this.user_addrComp);
                var address = addrComp.length > 0 ? addrComp.join(', ') : '';

                let geocoder = new google.maps.Geocoder();
                geocoder.geocode({ 'address': address }, (results, status) => {
                    if (status == 'OK') {
                        this.location = results[0].geometry.location;
                        this.showAgentRates();
                    } else {
                        console.log('Geocode was not successful for the following reason: ' + status);
                    }
                });
            }

            if (this.shipType == 'I') {
                if (this.noRates && !this.dataService.isGuest)
                    this.onCountryChange();
                else {
                    $('.dropdown-toggle[data-id="rcountry"]').removeClass('ng-valid');
                    $('.dropdown-toggle[data-id="rcountry"]').addClass('ng-invalid');
                }
            }

            this.inited = true;
            //if (!this.dataService.isGuest && this.noRates)
            //    this.saddress = '......';
            setTimeout(() => {
                this.initControl(this.formComp.form.controls["h"], null);
                this.initControl(this.formComp.form.controls["w"], null);
                this.initControl(this.formComp.form.controls["l"], null);
                //this.initControl(this.formComp.form.controls["saddress"], null);
            });
        });
    }

    initControl(ctrl: FormControl, val: any) {
        //ctrl.patchValue(val);
        ctrl.markAsUntouched();
        ctrl.markAsPristine();
        ctrl.setValidators([Validators.required]);
        ctrl.updateValueAndValidity();
    }

    get countryList() {
        let list = [];
        for (var c in this.countryStates)
            list.push(c);
        return list;
    }

    get isDisabled1() {
        return !this.inited || (this.pageno == 1 && this.formComp && this.formComp.form.invalid) || (!this.noRates && !this.location) || this.estimating;
    }

    senderAddressChanged(data: any) {
        if (!data && (!this.noRates || this.dataService.isGuest)) {
            this.countryOpt = [];
            this.country = '';
            this.showOblast = false;
            this.oblast = '*';
            $('.dropdown-toggle[data-id="rcountry"]').removeClass('ng-valid');
            $('.dropdown-toggle[data-id="rcountry"]').addClass('ng-invalid');
            setTimeout(() => $('#rcountry').selectpicker('refresh'));
        }
    }

    updateSenderAddress(ev: any) {
        if (ev.country) {
            this.dataService.userData.UserData["u_country"] = ev.country;
            this.dataService.userData.UserData["u_oblast"] = ev.stateprov;
        }
        if (ev.elems) {
            var address = ev.elems.length > 0 ? ev.elems.map(e => e.value).join(', ') : '';

            for (var p in this.sender_map) {
                var e = ev.elems.find(x => x.key == p);
                if (e)
                    this.dataService.userData.UserData[this.sender_map[p]] = e.value;
                else if (address && p != 'scountry' && p != 'soblast' && p != 'semail' && p != 'sfname' && p != 'slname' && p != 'sphone')
                    this.dataService.userData.UserData[this.sender_map[p]] = '';
            }

            var addrComp = this.dataService.parseUserAddress(this.user_addrComp);

            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': address }, (results, status) => {
                if (status == 'OK') {
                    this.zone.run(() => {
                        this.location = results[0].geometry.location;
                        this.agentRates = true;
                        this.dataService.queue.next({ location: results[0].geometry.location });
                        //this.senderAddress.emit({ location: results[0].geometry.location });
                        this.dataService.getMeestHub(this.user_addrComp.postal_code, true).subscribe((hub: any) => {
                            this.meestHub = hub;
                            if (ev.country) {
                                this.countryFrom = ev.country;
                                this.UserBranch = this.countryFrom == 'USA' ? (hub ? 4 : 2) : 1;
                                this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.UserBranch);
                                this.dataService.userData.UserData["u_country"] = ev.country;
                                this.dataService.userData.UserData["u_oblast"] = ev.stateprov;
                                this.dataService.queue.next({ country: ev.country, stateprov: ev.stateprov });
                                setTimeout(() => this.onCountryFromChange());
                            }
                            this.showAgentRates();
                        });
                    });
                } else {
                    console.log('Geocode was not successful for the following reason: ' + status);
                }
            });
        }
        return true;
    }

    updateDomesticAddress(ev: any) {
        if (ev.country)
            this.zone.run(() => {
                this.country = ev.country;
                this.oblast = ev.stateprov;
                this.dataService.queueDomestic.next({ country: ev.country, stateprov: ev.stateprov });
            });

        if (ev.elems) {
            var address = ev.elems.length > 0 ? ev.elems.map(e => e.value).join(', ') : '';

            this.zone.run(() => {
                for (var p in this.sender_map) {
                    var e = ev.elems.find(x => x.key == p);
                    if (e)
                        this.domestic[this.sender_map[p]] = e.value;
                    else if (address && p != 'scountry' && p != 'soblast')
                        this.domestic[this.sender_map[p]] = '';
                }
                this.dataService.queueDomestic.next(this.domestic);
            });
        }
        return true;
    }

    showAgentRates() {
        let c = null;
        if (this.user_addrComp) 
            c = this.user_addrComp.country === 'USA' ? 'USA' : 'CAN';
        
        this.dataService.getClosestAgent(this.location.lat(), this.location.lng(), c).subscribe((agents: any[]) => {
            if (agents.length > 0) {
                this.closestAgent = agents[0];
                this.closestAgentList = agents;
            }
        })
    }

    onCountryChange() {
        let value = this.country;
        this.oblast = '';
        $('.dropdown-toggle[data-id="rcountry"]').removeClass('ng-invalid');
        $('.dropdown-toggle[data-id="rcountry"]').addClass('ng-valid');
        if (this.countries.find(x => x.CountryCodeValue == value).CountryCodeType === 'DropDownTable') {
            this.dataService.getOblast(value).subscribe((obl: any[]) => {
                this.oblasts = obl;
                this.oblastOpt = obl.map(c => {
                    return { key: c.Id, value: c.r_region }
                });
                this.oblastOpt.splice(0, 0, { key: '', value: 'Select Oblast' });
                this.showOblast = true;
                this.oblast = '';
                setTimeout(() => $('#roblast').selectpicker('refresh'));
            })
        } else {
            this.oblast = '';
            this.oblasts = [];
            this.showOblast = false;
            this.oblast = '*';
        }
    }

    onCountryFromChange() {
        this.stateFrom = 'X';

        if (!this.meestHub && this.UserBranch == 1)
            this.UserBranch = this.countryFrom == 'USA' ? 2 : 1;
        this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.UserBranch);

        this.dataService.getCountries().subscribe((res: any[]) => {
            this.countries = res;
            this.countries.forEach(x => x["expressShip"] = this.expressShipCountries.some(c => c == x.CountryCodeValue) ? 1 : 0);
            this.countryOpt = this.countries.map(c => {
                if (c.CountryCodeValue == 'ddt_CountryCodeParcels') {
                    c.CountryCodeValue = c.r_country;
                }
                return { key: c.CountryCodeValue, value: c.r_country, code: c.CountryCode2 }
            });
            this.countryOpt.splice(0, 0, { key: '', value: 'Select Country' });
            setTimeout(() => $('#rcountry').selectpicker('refresh'));
            //this.country = '';
            //this.showOblast = false;
            //this.oblast = '*';

            //this.formComp.form.controls['fstate'].setErrors({ 'incorrect': true });
        })
    }

    onStateFromChange() {
        if (this.stateFrom != 'X' && this.countryFrom != 'X')
            setTimeout(() => this.formComp.form.controls['fstate'].setErrors(null));
        else
            setTimeout(() => this.formComp.form.controls['fstate'].setErrors({ 'incorrect': true }));
    }

    //get getData() {
    //    return JSON.stringify({ delivery: this.deliverySelection[this.selectedRate], post: this.posts[this.selectedPostService] });
    //}

    selectPost(ix: number) {
        //this.selectedPostService = ix;
        //let rate = this.posts[this.selectedPostService];

        //if (rate.ServiceName != 'Drop-off')
        this.deliverySelection.forEach(r => r["ship_fee"] = 0);  //rate.Price);
        this.deliverySelectionUPS.forEach(r => r["ship_fee"] = 0);
        this.deliverySelectionUSPS.forEach(r => r["ship_fee"] = 0);
        this.deliverySelectionUPSPickup.forEach(r => r["ship_fee"] = 0);
    }

    getCountryFlag(c: any) {
        return 'flag-icon flag-icon-' + c.code;
    }

    getFormData() {
        this.page1 = { weight: this.weight, h: this.height, l: this.length, w: this.width, rcountry: this.country };
        //this.page1.agentCode = this.AgentCode;

        var cntr = this.countries.find(x => x.CountryCodeValue == this.country);

        if (this.shipType == 'D') {
            this.page1["rcountrycode"] = this.country == 'USA' ? 'USA' : 'CAN';
            this.page1["roblast"] = this.oblast;
        }
        else if (this.oblast != '' && this.oblasts.length > 0) {  //  roblast contains the Id value
            this.page1["rcountrycode"] = this.oblasts.find(x => x.Id == this.oblast).CountryCodeValue;
            this.page1["roblast"] = this.oblast;
        }
        else {
            this.page1["rcountrycode"] = this.country;
            this.page1.rcountry = cntr.r_country;
        }
        return this.page1;
    }

    submitted() {
        if (this.pageno == 1) {
            if (!this.noRates && this.UserBranch == 1) {
                this.UserBranch = this.countryFrom == 'USA' ? 2 : 1;
                this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.UserBranch);
            }
            if (this.dataService.isGuest && !this.closestAgent) {
                this.errorMessage = 'Invalid sender address';
                return;
            }
            if (this.weight == 0) {
                this.errorMessage = 'Weight has to be greater than 0';
                return;
            }
            this.page1 = this.getFormData();
            if (!this.checkWeight())
                return;

            this.estimated = true;
            this.deliverySelection = [];
            this.deliverySelectionUPS = [];
            this.deliverySelectionUSPS = [];
            this.deliverySelectionUPSPickup = [];
            this.deliverySelectionAgent = [];
            this.accActive = [false, false];
            this.errorMessage = '';
            this.estimating = true;
            if (this.shipType == 'D')
                this.domesticRates();
            else {
                var subs = [];
                subs.push(this.dataService.getDeliverySelection(this.page1));
                subs.push(this.dataService.getCarrierPostal());
                subs.push(this.dataService.getMeestHub(this.dataService.userData.UserData.u_index));
                this.receivingCountry = Object.assign({}, this.countries.find(x => x.CountryCodeValue == this.page1.rcountrycode || x.r_country == this.page1.rcountry));

                zip(...subs).subscribe((res: any[]) => {
                    var ui = res[0];
                    if (ui.length > 0 && ui[0].RateName == 'Error' && this.receivingCountry.expressShip) {
                        ui[0].RateName = 'Dummy';
                        ui[0].AirSea = 'Express';
                        ui[0].DeliveryModeCode = 'E';
                        ui[0].delivery = 0;
                        ui[0].total_rate_fee = 50;
                    }
                    if (ui.length > 0 && ui[0].RateName == 'Error') {
                        this.errorMessage = ui[0].DeliveryModeDescription;
                        this.estimating = false;
                    }
                    else {
                        this.errorMessage = '';
                        this.answer = 'calculate';
                        ui = ui.filter(x => x.DeliveryModeCode != 'PU');  //  Remove pickup rates
                        ui.forEach(x => { x.ship_fee = x.carrier; x.carrier = 0; });
                        this.deliverySelection = ui;
                        //this.deliverySelectionUPS = res[2] ? [] : JSON.parse(JSON.stringify(ui));  //  Only USPS for hub
                        this.deliverySelectionUPS = JSON.parse(JSON.stringify(ui));
                        this.deliverySelectionUSPS = JSON.parse(JSON.stringify(ui));
                        this.deliverySelectionUPSPickup = (res[1] || res[2]) ? [] : JSON.parse(JSON.stringify(ui));  // Omit UPS pickup
                        if (this.deliverySelection.some(x => x.RateName.toUpperCase() == 'EXPEDITED'))
                            this.receivingCountry["expressShip"] = 2;
                        this.minPostAir = this.minPrice(this.deliverySelection, 'Air');
                        this.minPostSea = this.minPrice(this.deliverySelection, 'Sea');
                        this.txtCancel = null;
                        this.txtOK = 'Ship with Meest';
                        this.pageTitle = 'Estimated Cost';
                        if (!this.dataService.isGuest)
                            this.meestHub = res[2];

                        if (this.noRates && (!this.dataService.isGuest && this.dataService.userData.UserData["customerCD"] != 'OOREG'))
                            this.nextPage();
                        else
                            this.postRates();
                    }
                });
            }
        }
        else
            this.gohome(null);
    }

    postRates() {
        if (this.noRates && (!this.dataService.isGuest && this.dataService.userData.UserData["customerCD"] != 'OOREG'))
            return;
        this.errorMessage = "";
        let post_agent = this.dataService.userData.UserData["u_country"].toUpperCase() == 'USA' ? 'MAI' : 'MEM';
        let isDOV = this.dataService.userData.Branch.id != 1 && !!this.meestHub;
        if (isDOV)
            post_agent = this.meestHub;

        this.dataService.getAgent(post_agent).subscribe((res: any) => {
            let agent_addrComp = {};
            let user_addrComp = {};
            let addrComp = this.dataService.parseAgentAddress(res, agent_addrComp);
            let addrComp_u = this.dataService.parseUserAddress(user_addrComp);
            this.pageMap = { userAddress: user_addrComp, agentAddress: agent_addrComp, agent: res };

            let agentCountry = this.pageMap.agentAddress.country;
            if (agentCountry == 'CAN')
                agentCountry = 'CA';
            else if (agentCountry == 'USA')
                agentCountry = 'US';
            let fromAddr = { City: this.pageMap.userAddress.locality, Province: this.pageMap.userAddress.state, Country: this.pageMap.userAddress.country.toUpperCase() == 'CANADA' ? 'CA' : 'US', Postal: this.pageMap.userAddress.postal_code };
            let toAddr = { City: this.pageMap.agentAddress.locality, Province: this.pageMap.agentAddress.state, Country: agentCountry, Postal: this.pageMap.agentAddress.postal_code };
            let param = {
                From: fromAddr, To: toAddr, Weight: this.page1.weight, Height: this.page1.h, Width: this.page1.w, Length: this.page1.l, User: this.dataService.userData.UserData
            };

            if (!fromAddr.Postal) {
                this.errorMessage = "Invalid sender address - postal/zip code is not provided";
                this.estimating = false;
                return;
            }

            var subs = [];
            if (agentCountry == 'US') {
                subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: isDOV ? 'usps_parcel_select' : 'fedex_ground' }, param)));
                subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: isDOV ? 'ups_ground_saver' : 'ups_ground' }, param)));
                if (!isDOV && this.dataService.userData.Branch.id == 2)
                    subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: 'usps_parcel_select' }, param)));
            }
            else {
                subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: 'DOM.EP' }, param)));
                subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: 'CanPar' }, param)));
                subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: 'ups_standard' }, param)));
                subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: 'purolator_ground ' }, param)));
            }
            subs.push(this.dataService.getFixedRates());

            zip(...subs).subscribe((rates: any[]) => {
                this.post_rates[0] = rates[0];
                this.post_rates[2] = agentCountry == 'US' ? rates[1] : rates[2];
                if (!isDOV && this.dataService.userData.Branch.id == 2)
                    this.post_rates[4] = rates[2];  //  USPS
                else if (this.dataService.userData.Branch.id == 1)
                    this.post_rates[4] = rates[3];  //  Purolator

                var cfr = rates[rates.length - 1].find(x => x.carrier == 'ups_worldwide_expedited');
                if (cfr) this.expressFixedRate = (cfr.ValueFixed == null ? 0 : cfr.ValueFixed);

                var cntr = this.countries.find(x => x.CountryCodeValue == this.country);
                if (cntr.pickup) {
                    this.post_rates[3] = rates[1];  //  UPS / CanPar pickup
                    var fcar = { pickup_charge: cntr.pickup_charge || 0 };
                    if (agentCountry != 'US')
                        this.deliverySelectionUPSPickup = JSON.parse(JSON.stringify(this.deliverySelectionUPS));
                    this.post_rates[3] = Object.assign({ Pickup: fcar.pickup_charge }, this.post_rates[3]);  //  Fixed $8 for pickup
                }
                else
                    this.deliverySelectionUPSPickup = [];

                if (this.post_rates[0]) {
                    this.deliverySelection.forEach(x => {
                        var fixed = this.selectFixedRate(this.post_rates[0], x.AirSea, agentCountry == 'US' ? 'fedex_ground' : 'DOM.EP');
                        let p = ((this.post_rates[0].Rates && this.post_rates[0].Rates.length && this.post_rates[0].Rates[0].ServiceCode != 'fixedRate') ? this.post_rates[0].Rates[0].Price : 0) + fixed;
                        x.total_rate_fee += p;
                        x.carrier = p;
                        x.fixed = fixed;
                    });
                }
                if (this.post_rates[2]) {
                    this.deliverySelectionUPS.forEach(x => {
                        var fixed = this.selectFixedRate(this.post_rates[2], x.AirSea, agentCountry == 'US' ? 'ups_ground' : 'ups_standard');
                        let p = ((this.post_rates[2].Rates && this.post_rates[2].Rates.length && this.post_rates[2].Rates[0].ServiceCode != 'fixedRate') ? this.post_rates[2].Rates[0].Price : 0) + fixed;
                        x.total_rate_fee += p;
                        x.carrier = p;
                        x.fixed = fixed;
                    });
                }
                if (this.post_rates[3]) {
                    this.deliverySelectionUPSPickup.forEach(x => {
                        var fixed = this.selectFixedRate(this.post_rates[3], x.AirSea, agentCountry == 'US' ? 'ups_ground' : 'CanPar');
                        let p = ((this.post_rates[3].Rates && this.post_rates[3].Rates.length && this.post_rates[3].Rates[0].ServiceCode != 'fixedRate') ? this.post_rates[3].Rates[0].Price : 0) + fixed;
                        x.total_rate_fee += (p + this.post_rates[3].Pickup);
                        x.carrier = p;
                        x.pickup = this.post_rates[3].Pickup;
                        x.fixed = fixed;
                    });
                }
                if (this.post_rates[4]) {
                    this.deliverySelectionUSPS.forEach(x => {
                        var fixed = this.selectFixedRate(this.post_rates[4], x.AirSea, agentCountry == 'US' ? 'usps_parcel_select' : 'purolator_ground ');
                        let p = ((this.post_rates[4].Rates && this.post_rates[4].Rates.length && this.post_rates[4].Rates[0].ServiceCode != 'fixedRate') ? this.post_rates[4].Rates[0].Price : 0) + fixed;
                        x.total_rate_fee += p;
                        x.carrier = p;
                        x.fixed = fixed;
                    });
                }

                this.nextPage();
            });
        });
    }

    postRatesForAgent() {
        if (this.noRates && (this.dataService.isGuest || this.dataService.userData.UserData["customerCD"] != 'OOREG')) {
            this.toggleDelSelected(null, 1);
            return;
        }
        if (!this.closestAgent.UseCarrier) {
            this.toggleDelSelected(null, 1);
            return;
        }

        let post_agent = this.dataService.userData.UserData["u_country"].toUpperCase() == 'USA' ? 'MAI' : 'MEM';
        let isDOV = this.dataService.userData.Branch.id != 1 && !!this.meestHub;
        if (isDOV)
            post_agent = this.meestHub;

        zip(this.dataService.getAgent(this.closestAgent.agentcode), this.dataService.getAgent(post_agent)).subscribe((ui: any) => {
            let agent_addrComp: any = {};
            let meest_addrComp: any = {};
            let addrComp = this.dataService.parseAgentAddress(ui[0], agent_addrComp);
            let addrComp_u = this.dataService.parseAgentAddress(ui[1], meest_addrComp);

            let agentCountry = agent_addrComp.country;
            if (agentCountry == 'CAN')
                agentCountry = 'CA';
            else if (agentCountry == 'USA')
                agentCountry = 'US';
            let fromAddr = { City: agent_addrComp.locality, Province: agent_addrComp.state, Country: agentCountry, Postal: agent_addrComp.postal_code };
            let toAddr = { City: meest_addrComp.locality, Province: meest_addrComp.state, Country: agentCountry, Postal: meest_addrComp.postal_code };
            let param = {
                From: fromAddr, To: toAddr, Weight: this.page1.weight, Height: this.page1.h, Width: this.page1.w, Length: this.page1.l
            };

            var USCarrier = 'fedex_ground';
            if (isDOV)
                USCarrier = 'usps_parcel_select';
            var subs = [];
            if (agentCountry == 'US') 
                subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: USCarrier }, param)));
            else 
                subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: 'DOM.EP' }, param)));

            zip(...subs).subscribe((rates: any[]) => {
                this.post_rates[1] = rates[0];
                if (rates[0]) {
                    this.deliverySelectionAgent.forEach(x => {
                        var fixed = this.selectFixedRate(rates[0], x.AirSea, agentCountry == 'US' ? USCarrier : 'DOM.EP');
                        let p = ((rates[0].Rates && rates[0].Rates.length && rates[0].Rates[0].ServiceCode != 'fixedRate') ? rates[0].Rates[0].Price : 0) + fixed;
                        x.total_rate_fee += p;
                        x.carrier = p;
                        x.fixed = fixed;
                    });
                }
                this.toggleDelSelected(null, 1);
            });
        });
    }

    domesticRates() {
        let agent_addrComp = {};
        let user_addrComp = {};
        this.domestic.u_country = this.country;
        this.domestic.u_oblast = this.oblast;
        let addrComp = this.dataService.parseUserAddress(agent_addrComp, this.domestic);
        let addrComp_u = this.dataService.parseUserAddress(user_addrComp);
        this.pageMap = { userAddress: user_addrComp, agentAddress: agent_addrComp };

        this.saddress = addrComp_u.join(', '); this.saddress = this.saddress.replace(',', '');
        this.raddress = addrComp.join(', '); this.raddress = this.raddress.replace(',', '');

        let agentCountry = this.country;
        if (agentCountry == 'CAN')
            agentCountry = 'CA';
        else if (agentCountry == 'USA')
            agentCountry = 'US';
        let fromAddr = { Address: this.pageMap.userAddress.street_number + ' ' + this.pageMap.userAddress.route, City: this.pageMap.userAddress.locality, Province: this.pageMap.userAddress.administrative_area_level_1, Country: this.pageMap.userAddress.country.toUpperCase() == 'CANADA' ? 'CA' : 'US', Postal: this.pageMap.userAddress.postal_code };
        let toAddr = { Address: this.pageMap.agentAddress.street_number + ' ' + this.pageMap.agentAddress.route, City: this.pageMap.agentAddress.locality, Province: this.oblast, Country: agentCountry, Postal: this.pageMap.agentAddress.postal_code };
        let param = {
            From: fromAddr, To: toAddr, Weight: this.page1.weight, Height: this.page1.h, Width: this.page1.w, Length: this.page1.l, AgentCompany: 'Meest', AgentName: 'Meest', ContactPhone: '4163063522', User: this.dataService.userData.UserData
        };

        var subs = [];
        if (agentCountry == 'US') {
            subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: 'fedex_ground' }, param)));
        }
        else {
            subs.push(this.dataService.getPostRates(Object.assign({ ServiceCode: 'CanPar_Domestic' }, param)));
        }

        zip(...subs).subscribe((rates: any[]) => {
            if (rates[0].Error)
                this.errorMessage = rates[0].Message;
            else {
                if (agentCountry == 'US') {
                    this.post_rates[0] = rates[0];
                    this.deliverySelection = this.post_rates[2].Rates.map(x => {
                        return { total_rate_fee: x.Price, VolumeWeight: x.VolumeWeight, carrier: 0, delivery: 0, DeliveryModeDescription: 'Home Delivery Regular', DeliveryModeCode: 'R', DeliveryTime: x.Delivery, AirSea: 'Air', RateName: 'Regular' }
                    });
                }
                else {
                    this.post_rates[3] = rates[0];
                    this.deliverySelectionUPSPickup = this.post_rates[3].Rates.map(x => {
                        return { total_rate_fee: x.Price, VolumeWeight: x.VolumeWeight, carrier: 0, delivery: 0, DeliveryModeDescription: 'Home Delivery Regular', DeliveryModeCode: 'R', DeliveryTime: x.Delivery, AirSea: 'Air', RateName: 'Regular' }
                    });
                }

                this.nextPage();
            }
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }

    selectFixedRate(post_rate: any, sae: string, sc: string) {
        var rate = 0;
        if (post_rate.Rates) {
            var fixed = post_rate.Rates.filter(x => x.ServiceCode == 'fixedRate');
            var fil = fixed.filter(x => x.ServiceName == sc);
            if (fil.length == 0)
                fil = fixed;
            var fil1 = fil.filter(x => x.Delivery == sae);
            if (fil1.length == 0)
                fil1 = fil.filter(x => !x.Delivery && (x.ServiceName == sc || !x.ServiceName));
            if (fil1.length > 0)
                rate = fil1[0].Price;

        //    if (post_rate.Rates.length > 0 && post_rate.Rates[0].ServiceCode != 'fixedRate')
        //        rate += post_rate.Rates[0].Price;
        //    if (rate == 0)
        //        post_rate.Rates = null;
        //    else
        //        post_rate.Rates[0].Price = rate;
        }
        return rate;
    }

    nextPage() {
        this.pageno++;

        if (this.closestAgent) {
            var subs = [];
            for (var i = 0; i < this.closestAgentList.length; i++) {
                subs.push(this.dataService.getDeliverySelection(Object.assign({ agentCode: this.closestAgentList[i].agentcode }, this.page1)));
            }
            zip(...subs).subscribe((r: any[]) => {
                for (var i = 0; i < this.closestAgentList.length; i++) {
                    let ui = r[i];
                    if (ui.length > 0 && ui[0].RateName != 'Error') {
                        this.errorMessage = '';
                        ui = ui.filter(x => x.DeliveryModeCode != 'PU');  //  Remuve pickup rates
                        this.deliverySelectionAgent = ui;
                        this.minAgentAir = this.minPrice(this.deliverySelectionAgent, 'Air');
                        this.minAgentSea = this.minPrice(this.deliverySelectionAgent, 'Sea');
                        this.closestAgent = this.closestAgentList[i];
                        this.postRatesForAgent();
                        return;
                    }
                }
                this.toggleDelSelected(null, 0);
            });
        }
        else
            this.toggleDelSelected(null, 0);
    }

    reset() {
        this.pageno = 1;
        this.country = 'Ukraine';
        this.showOblast = false;
        this.oblast = '*';
        this.pageTitle = this.noRates ? 'Enter Parcel Details' : 'Cost Estimator';
        this.weight = null;
        this.height = null;
        this.length = null;
        this.width = null;
        this.estimated = false;
        this.saddress = '';

        this.onCountryChange();

        setTimeout(() => {
            //initAutocomplete_s(this.updateSenderAddress.bind(this));
            //restrictCountry_s(['us', 'ca']);
            $('#rcountry').selectpicker();
            $('#roblast').selectpicker();
        });
    }

    setupCountryPicker() {
        $('#rcountry').selectpicker();
        $('#roblast').selectpicker();
    }

    minPrice(rates: any[], sae: string) {
        let selr = rates.filter(x => x.AirSea === sae);
        let val = selr.length == 0 ? 0 : selr[0].delivery + selr[0].total_rate_fee + (selr[0].ship_fee ? selr[0].ship_fee : 0);
        for (var r of selr) {
            let p = r.delivery + r.total_rate_fee + (r.ship_fee ? r.ship_fee : 0);
            if (p < val)
                val = p;
        }
        return val;
    }

    toggleDelSelected(e: any, type: number) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        //if (type == 0) {
        //    this.postSelected = true;
        //    this.agentSelected = false;
        //}
        //else if (type == 1) {
        //    this.postSelected = false;
        //    this.agentSelected = true;
        //}
        this.estimating = false;
        setTimeout(() => {
            this.delSelected = type;
            this.isEstimated.emit();
        });
        return false;
    }

    checkWeight() {
        this.getFormData();
        let cntr = this.countries.find(x => x.CountryCodeValue == this.page1.rcountrycode || x.r_country == this.page1.rcountry);
        let f = this.dataService.userData.Branch.weight == 'kg' ? 'MaxWeightKG' : 'MaxWeightLB';
        this.WeightLimit = cntr && cntr[f] > 0 ? cntr[f] : 0;
        let volWeight = this.page1.w * this.page1.l * this.page1.h / (this.dataService.userData.Branch.weight == 'kg' ? 5000 : 139);
        if (this.WeightLimit > 0 && (this.WeightLimit < this.page1.weight || this.WeightLimit < volWeight)) {
            this.weightLimit.show();
            return false;
        }
        else
            return true;;
    }

    validateNumber(e: any, v: any) {
        let input = String.fromCharCode(e.charCode);
        const reg = /^\d+(?:[.]\d?)?$/;

        var val = (v || v === 0) ? v.toString() : '';
        if (!reg.test(val + input)) {
            e.preventDefault();
        }
    }

    gohome(ev: any) {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        let url = window.location != window.parent.location ? document.referrer : document.location.href;
        //let url = window.location != window.parent.location ? 'https://www.mymeest.ca/MeestPortal/login' : document.location.href;
        window.parent.postMessage('ship_now_redirect', url);
        if (window.location == window.parent.location) {
            url = window.location.href.replace(this.router.url, '/home');
            window.open(url, '_self');
        }
    }

    selectRate(inx: number, r: any, ix: number) {
        this.selag = inx;
        this.selrate = r;
        if (ix == 2 && !this.expressShip) 
            this.expressShip = true;
        else
            this.expressShip = false;
        document.getElementById(`rateselNew_${inx}_${ix}`).click();
    }

    selectRateMobile(inx: number, r: any, ix: number) {
        this.selag = inx;
        this.selrate = r;
        if (ix == 2 && !this.expressShip) 
            this.expressShip = true;
        else
            this.expressShip = false;
        document.getElementById(`rateselNewMobile_${inx}_${ix}`).click();
    }

    shopNow() {  //(selag: number, rate: any) {
        if (this.noRates) {
            var pickup = null;
            if (this.selrate.DeliveryModeCode == 'PU') {
                var rq = this.oblasts.find(x => x.Id == this.page1.roblast);
                if (rq)
                    pickup = this.pickups.find(x => x.Oblast.trim() == rq.r_region.trim() && x.branchID == this.dataService.userData.Branch.id);
            }

            let isDOV = this.dataService.userData.Branch.id != 1 && !!this.meestHub;

            let sc = 'DOM.EP';
            if (this.UserBranch == 1) {
                if (this.selag == 0) sc = 'DOM.EP';
                else if (this.selag == 2) sc = 'ups_standard';
                else if (this.selag == 3) sc = 'CanPar';
                else if (this.selag == 4) sc = 'purolator_ground ';
            }
            else {  //if (this.UserBranch == 2) {
                if (this.selag == 0) sc = 'fedex_ground';
                else if (this.selag == 2 || this.selag == 3) sc = 'ups_ground';
                else if (this.selag == 4) sc = 'usps_parcel_select';
                if (isDOV && this.selag != 3)
                    sc = this.selag == 0 ? 'usps_parcel_select' : 'ups_ground_saver';
            }
            //this.selrate["ship_fee"] = 0;
            var res = JSON.stringify({ delivery: this.selrate, post: { ServiceName: 'Expedited', ServiceCode: sc }, pickup: pickup, express: this.expressShip });

            this.dataService.checkpoint(this.selrate.AirSea.toLowerCase() == 'air' ? 6 : 5).subscribe((ui: string) => {
                this.doShop.emit({ agix: this.selag == 0 || this.selag == 2 || this.selag == 3 || this.selag == 4 ? 1 : 2, rate: res });
            });
        }
        else {
            if (this.isModal && window.location == window.parent.location)
                this.router.navigate(['home']);
            else {
                this.answer = 'ship-now';
                this.gohome(null);
            }
        }
    }

    close(ev: any) {
        ev.stopPropagation();
        ev.preventDefault();
        this.answer = 'close-modal';
        let url = window.location != window.parent.location ? document.referrer : document.location.href;
        window.parent.postMessage('close-modal', url);
        this.closed.emit();
    }

    noop(ev: any) {
        ev.stopPropagation();
        ev.preventDefault();
        return false;
    }

    getRateType(rates: any[], type: string) {
        let r = rates.find(x => x.AirSea === type && (!x.RateName || x.RateName.toUpperCase() != 'EXPEDITED'));
        if (!r && type == 'Express') {
            r = rates.find(x => x.RateName && x.RateName.toUpperCase() == 'EXPEDITED');
            if (!r)
               r = rates.find(x => x.AirSea === 'Air');
        }
        return r;
    }
}
