import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

declare var $: any;
import '../../../node_modules/jquery/dist/jquery.min.js';
import "../../../node_modules/jsignature/src/jSignature.js";
//import "../../../node_modules/jsignature/libs/jSignature.min.js";

import { DataService } from './../data.service';
import { MessengerService } from './../messenger.service';

@Component({
    selector: 'signature-component',
    template: `
<div id="sigPos" style="width:100%; height:1px">
</div>
    <div *ngIf="loaded" id="signature-dialog">
        <!-- Signature area -->
        <div id="signature"></div><br />

        <input type='button' id='clickSignature' value='Capture'>
        <!--input type='button' id='cancelSignature' value='Cancel' style="margin-left:7px"-->
    </div>
`,
    styles: []
})
export class SignatureComponent implements OnInit {
    errorMessage: string;
    @Input() invoiceid: number;
    parcelCode: string;
    public parcel: any;
    dataInvoice: any = '';
    public isMoney = false;
    loaded = true;
    //showFaderStr: string = 'false';
    @ViewChild('invoiceContainer', { static: false }) invoiceContainer: ElementRef;
    @Output() onSigned = new EventEmitter<any>();
    sigButton: any;
    @ViewChild('signModal', { static: false }) signModal;
    topHeader = '<tr><td class="InvoiceInfoName" style="font-size: 1.2em; text-align:center">This is your confirmation slip</td></tr><tr><td class="InvoiceInfoName" style="padding-bottom: 10px; font-size: 1.2em; text-align:center">Please note your invoice number</td></tr>';
    @ViewChild('gbModal', { static: false }) gbModal;

    constructor(private dataService: DataService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, private messengerService: MessengerService) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        //this.init();
    }

    init() {
        this.errorMessage = '';
        this.InitScripts(this.dataService.siteURL);
    }

    gohomeback(isProceed: boolean) {
        this.gbModal.hide();
        if (isProceed) {
            if (this.parcel.status == "INVOICED" && this.parcel.paymentID == 0) {
                this.errorMessage = '';
                this.dataService.deleteParcel(this.parcel.id).subscribe((res: any) => {
                    this.router.navigate(["home"]);
                },
                    (err: any) => {
                        this.errorMessage = this.dataService.processError(err);
                    });
            }
            else
                this.router.navigate(["home"]);
        }
    }

    triggerSignature() {
        if (this.sigButton)
            this.sigButton.click();
    }

    InitScripts(siteUrl) {
        var _this00 = this;

        $('#signature-dialog').dialog({
            autoOpen: false,
            modal: true,
            width: 720,
            resizable: false,
            height: 'auto',
            position: { my: "center top", at: "center bottom", of: "#sigPos" },
            open: function (event, ui) { $(".ui-dialog-titlebar-close", ui.dialog | ui).hide(); }
        });

        var Signature_Add = function (sigdata) {
            var id = $('#invoiceid').val();
            var parCode = $('#parcelCode').val();
            var url = siteUrl + 'Agent/AddSignature';
            $.ajax({
                type: "POST",
                beforeSend: function (request) {
                    request.setRequestHeader("TargetService", "MeestPortal");
                },
                url: url,
                data: { id: id, signature: sigdata, action: window.opener ? 'email' : '' },
                success: function (data) {
                    $('#signature_area_' + id).attr('src', sigdata);
                    $('#signature_area_' + id).show();
                    $('#ParcelSignature_' + id).hide();
                    $('#ParcelSignatureDate_' + id).text(data.date);
                    $("#ParcelInvoceButton").attr("disabled", false);
                    $("#ParcelLabelButton").attr("disabled", false);

                    _this00.messengerService.statusChanged({ topic: 'InvoiceSigned', value: id, text: parCode });
                }
            });
        }

        //$('[id^=ParcelSignature]').off('click').on('click', function (e) {
        setTimeout(() => {
            $("#signature-dialog").dialog('option', 'title', 'Signature is mandatory!');
            $("#signature-dialog").dialog('open');

            // Initialize jSignature
            var $sigdiv = $("#signature").jSignature({ 'UndoButton': false });
            $("#signature").jSignature('reset');

            $("#clickSignature").attr("disabled", true);

            $("#signature").bind('change', function (e) {
                $("#clickSignature").attr("disabled", false);
            });

            $('#clickSignature').off('click').on('click', function (e) {
                $("#signature").unbind('change');
                // Get response of type image
                var data = $sigdiv.jSignature('getData', 'default');
                $("#signature-dialog").dialog('close');
                $("#signature").empty();
                _this00.onSigned.emit(data);
                //Signature_Add(data);
            });
            $('#cancelSignature').off('click').on('click', function (e) {
                $("#signature").unbind('change');
                $("#signature-dialog").dialog('close');
                $("#signature").empty();
            });
            return false;
        });
    }
}
