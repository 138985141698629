import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../data.service';

@Component({
    selector: 'delivery-selection',
    template: `
        <div>
          <ng-template #tplloading>
            <div class="text-center">
              <img src="assets/images/ajax-loader.gif" style="margin:30px;" />
            </div>
          </ng-template>

          <div>
            <span style="color:red;font-size:1.2em;margin-left:10px">{{errorMessage}}</span>
          </div>

            <!--<ag-grid-angular #agGrid class="ag-theme-blue" [domLayout]="'autoHeight'" [rowSelection]="'single'" style="width:100%; min-width:220px;" (gridReady)="onGridReady($event)" (rowDataChanged)="onRowDataChanged1($event)"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs" [enableColResize]="true" [suppressMovableColumns]="true"
                         [rowData]="rowData">
            </ag-grid-angular>-->

          <div class="col-sm-12" style="padding: 0">
            <table *ngIf="postSelection"  class="table table-striped table-sm" style="margin-top:10px">
                <thead>
                <tr class="info-label">
                    <th>Service Name</th>
                    <th>Price</th>
                    <th>Expected Delivery</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of posts; index as ix" (click)="selectPost(ix)" [ngClass]="{'sel-row': selectedPostService == ix}">
                    <td>{{r.ServiceName}}</td>
                    <td>{{r.Price | currency:'CAD'}}</td>
                    <td>{{r.Delivery ? r.Delivery + ' days' : ''}}</td>
                </tr>
                </tbody>
            </table>

            <table *ngIf="!postSelection" class="table table-striped table-sm" style="margin-bottom: 2px;">
                <thead>
                <tr class="info-label">
                    <th>Sent By</th>
                    <th>Delivery Time</th>
                    <th>Delivery Option</th>
                    <!--th>Rate</th-->
                    <!--th>Can Post</th-->
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of deliverySelection; index as ix" (click)="selectRate(ix)" [ngClass]="{'sel-row': selectedRate == ix}" style="cursor:pointer">
                    <td>{{r.AirSea}}</td>
                    <td>{{r.DeliveryTime}}</td>
                    <td>{{r.DeliveryModeDescription}}</td>
                    <!--td>{{r.rate_fee | number:'1.2-2'}}</td-->
                    <!--td>{{r.ship_fee | number:'1.2-2'}}</td-->
                    <td>{{r.delivery + r.total_rate_fee + (r.ship_fee ? r.ship_fee : 0) | number:'1.2-2'}}</td>
                </tr>
                </tbody>
            </table>

            <div *ngIf="!postSelection && selectedRate >=0 && deliverySelection[selectedRate].DeliveryModeCode == 'PU' && pickups.length > 0" style="margin-top:15px">
                <h4>Pickup from MEEST Office</h4>
                <table *ngIf="!postSelection" class="table table-striped table-sm" style="margin-bottom: 2px;">
                    <thead>
                    <tr class="info-label">
                        <th>City</th>
                        <th>Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let r of pickups; index as ix" (click)="selectedPickup = ix" [ngClass]="{'sel-row': selectedPickup == ix}" style="cursor:pointer">
                        <td>{{r.City}}</td>
                        <td>{{r.Address}} {{r.House}}, {{r.City}}, {{r.Oblast}} Oblast, {{r.Country}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>

            <div *ngIf="postSelection" class="col-sm-12 itemButtons" style="margin-top:10px; margin-bottom: 20px; padding-left: 0">
                <button class="btn btn-primary" type="button" [disabled]="selectedPostService < 0" (click)="postSelection = false">Next</button>
                <button class="btn" type="button"  attr.data-cancelbut="{{'Cancel'}}" (click)="submitted('')">Cancel</button>
            </div>
`,
    styles: [`
        .sel-row { background-color: #10c0e0 !important; }
`]
})
export class DeliverySelectionComponent implements OnInit {
    errorMessage: string;
    loading: boolean;
    @Input() parcel: any = {};
    @Input() deliverySelection: any[];
    @Input() agentData: any = {};
    selectedRate: number;
    selectedPostService: number;
    selectedPickup: number;
    posts: any[];
    pickups: any[] = [];
    @Input() postSelection: boolean;
    @Input() oblasts: any[];

    constructor(public router: Router, private dataService: DataService) { }


    ngOnInit() {
        this.loading = false;
        this.selectedRate = -1;
        this.selectedPostService = -1;
        this.selectedPickup = -1;
        this.posts = [];

    //this.gridOptions = <GridOptions>{
    //};

    //this.columnDefs = [
    //  { headerName: "Transport", field: "AirSea", minWidth: 100 },
    //  { headerName: "Delivery", field: "DeliveryModeDescription" },
    //  { headerName: "Rate Name", field: "RateName", minWidth: 110 },
    //  { headerName: "Delivery Time", field: "DeliveryTime" },
    //    {
    //        headerName: "Total " + (this.dataService.userData ? this.dataService.userData.Branch.currency : 'CAD'), valueFormatter: this.currencyFormatter,
    //    valueGetter: function aPlusBValueGetter(params) {
    //        return params.data.delivery + params.data.rate_fee + (params.data.ship_fee ? params.data.ship_fee : 0);
    //    }
    //  },
    //  { headerName: "Delivery Fee " + this.dataService.userData.Branch.currency, field: "delivery", valueFormatter: this.currencyFormatter },
    //  { headerName: "Rate Fee " + this.dataService.userData.Branch.currency, field: "rate_fee", valueFormatter: this.currencyFormatter },
    //    { headerName: "Shipment Fee " + this.dataService.userData.Branch.currency, field: "ship_fee", valueFormatter: this.currencyFormatter },
    //];

    //this.rowData = [];

        this.dataService.getPickupSites().subscribe((ui: any[]) => {
            if (this.deliverySelection.some(x => x.DeliveryModeCode == 'PU') && this.oblasts) {
                var rq = this.oblasts.find(x => x.Id == this.parcel.roblast);
                if (rq)
                    this.pickups = ui.filter(x => x.Oblast.trim() == rq.r_region.replace(' Oblast', '').trim());
                if (this.pickups.length == 0) 
                    this.deliverySelection = this.deliverySelection.filter(x => x.DeliveryModeCode != 'PU');
            }
            if (this.postSelection)
                this.getPostRates();
            else {
                this.posts.push({ ServiceName: 'Expedited', ServiceCode: 'DOM.EP' });
                this.selectPost(0);
            }
        });
    }

    get getData() {
        return JSON.stringify({ delivery: this.deliverySelection[this.selectedRate], post: this.posts[this.selectedPostService], pickup: this.selectedPickup >= 0 ? this.pickups[this.selectedPickup] : null });
    }

    getPostRates() {
        this.errorMessage = "";
        this.dataService.getCPRates({ From: this.agentData.userAddress.postal_code, To: this.agentData.agentAddress.postal_code, Weight: this.parcel.weight, Height: this.parcel.h, Width: this.parcel.w, Length: this.parcel.l }).subscribe((ui: any) => {
            //console.log(ui.Message);
            if (ui.Error)
                this.errorMessage = ui.Message;
            else {
                this.posts = ui.Rates;
                this.posts.splice(0, 0, { ServiceName: 'Drop-off' });
            }
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }

    selectRate(ix: number) {
        this.selectedRate = ix;
        this.selectedPickup = this.deliverySelection[ix].DeliveryModeCode == 'PU' ? -2 : -1;
    }

    selectPost(ix: number) {
        this.selectedPostService = ix;
        let rate = this.posts[this.selectedPostService];

        //if (rate.ServiceName != 'Drop-off')
        this.deliverySelection.forEach(r => r["ship_fee"] = 0);  //rate.Price);
    }
}
