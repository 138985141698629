import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../data.service';

@Component({
    selector: 'delivery-selection-money',
    template: `
        <div>
          <ng-template #tplloading>
            <div class="text-center">
              <img src="assets/images/ajax-loader.gif" style="margin:30px;" />
            </div>
          </ng-template>

          <div>
            <span style="color:red;font-size:1.2em;margin-left:10px">{{errorMessage}}</span>
          </div>

            <!--<ag-grid-angular #agGrid class="ag-theme-blue" [domLayout]="'autoHeight'" [rowSelection]="'single'" style="width:100%; min-width:220px;" (gridReady)="onGridReady($event)" (rowDataChanged)="onRowDataChanged1($event)"
                         [gridOptions]="gridOptions"
                         [columnDefs]="columnDefs" [enableColResize]="true" [suppressMovableColumns]="true"
                         [rowData]="rowData">
            </ag-grid-angular>-->

          <div class="col-sm-12" style="padding: 0">
            <table class="table table-striped table-sm" style="margin-bottom: 2px;">
                <thead>
                <tr class="info-label">
                    <th>Payout Option</th>
                    <th>Delivery Time</th>
                    <th>Amount USD</th>
                    <th>Service Fee USD</th>
                    <th>Delivery Fee USD</th>
                    <th>Total USD</th>
                    <th>Total CAD</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of deliverySelection; index as ix" (click)="selectedRate = ix" [ngClass]="{'sel-row': selectedRate == ix}">
                    <td>{{r.DeliveryModeDescription}}</td>
                    <td>{{r.DeliveryTime}}</td>
                    <td>{{parcel.amount | number:'1.2-2'}}</td>
                    <td>{{r.rate_fee | number:'1.2-2'}}</td>
                    <td>{{r.delivery | number:'1.2-2'}}</td>
                    <td>{{r.Total_USD | number:'1.2-2'}}</td>
                    <td>{{r.TotalCustomerOwe_CAD | number:'1.2-2'}}</td>
                </tr>
                </tbody>
            </table>
          </div>
        </div>
`,
    styles: [`
        .sel-row { background-color: #10c0e0 !important; }
`]
})
export class DeliverySelectionMoneyComponent implements OnInit {
    errorMessage: string;
    loading: boolean;
    @Input() parcel: any = {};
    @Input() deliverySelection: any[];
    selectedRate: number;
    selectedPostService: number;
    posts: any[];

    constructor(public router: Router, private dataService: DataService) { }


    ngOnInit() {
        this.loading = false;
        this.selectedRate = -1;
        this.selectedPostService = -1;
        this.posts = [];

    //this.gridOptions = <GridOptions>{
    //};

    //this.columnDefs = [
    //  { headerName: "Transport", field: "AirSea", minWidth: 100 },
    //  { headerName: "Delivery", field: "DeliveryModeDescription" },
    //  { headerName: "Rate Name", field: "RateName", minWidth: 110 },
    //  { headerName: "Delivery Time", field: "DeliveryTime" },
    //    {
    //        headerName: "Total " + (this.dataService.userData ? this.dataService.userData.Branch.currency : 'CAD'), valueFormatter: this.currencyFormatter,
    //    valueGetter: function aPlusBValueGetter(params) {
    //        return params.data.delivery + params.data.rate_fee + (params.data.ship_fee ? params.data.ship_fee : 0);
    //    }
    //  },
    //  { headerName: "Delivery Fee " + this.dataService.userData.Branch.currency, field: "delivery", valueFormatter: this.currencyFormatter },
    //  { headerName: "Rate Fee " + this.dataService.userData.Branch.currency, field: "rate_fee", valueFormatter: this.currencyFormatter },
    //    { headerName: "Shipment Fee " + this.dataService.userData.Branch.currency, field: "ship_fee", valueFormatter: this.currencyFormatter },
    //];

    //this.rowData = [];
    }

    get getData() {
        return JSON.stringify({ delivery: this.deliverySelection[this.selectedRate] });
    }
}
