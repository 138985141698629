import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, zip } from 'rxjs';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';

import { DataService, Credentials, UserCredentials } from './../data.service';
//import { EstimatorComponent, ModalEstimatorComponent } from '../new-parcel/estimator.component';

@Component({
  selector: 'app-login',
  templateUrl: './login-new3.component.html',
  styleUrls: ['./login-new3.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
    errorMessage = '';
    rptext = '';
    loading = false;
    resetp = false;
    agentLogin: any;
    bsModalRef: BsModalRef;
  credentials: Credentials;
  branchList = [];
  serviceList = [];
  user: UserCredentials;
    @ViewChild('form', { static: false }) formComp;
  @Output() onLogin = new EventEmitter<UserCredentials>();
    @ViewChild(ModalDirective, { static: false }) lgModal: ModalDirective;
    isModalShown: boolean = false;

    constructor(private dataService: DataService, private router: Router, private sanitizer: DomSanitizer, private modalService: BsModalService) {
    this.credentials = { username: '', password: '', branch: '0', service: 'PARCELS' };
  }

  ngOnInit() {
    this.errorMessage = '';
    this.loading = true;
      zip(this.dataService.getBranches(), this.dataService.getServices(this.credentials.branch)).subscribe((ui: any[]) => {
        this.branchList = ui[0];
        this.dataService.branches = ui[0];
          this.serviceList = ui[1];
          if (this.serviceList.length > 0)
              this.credentials.service = this.serviceList[0].ServiceValue;
          this.loading = false;

          if (this.router.routerState.snapshot.root.queryParams.e) {
              this.errorMessage = 'Your password has been reset. Please login to access your account';
              this.credentials.username = this.router.routerState.snapshot.root.queryParams.e;
          }
    },
    (err: any) => {
      this.loading = false;
      this.errorMessage = this.dataService.processError(err);
    });
  }

    ngAfterViewInit() {
        if (this.router.routerState.snapshot.root.queryParams.e)
            setTimeout(() => {
                this.formComp.controls.username.markAsDirty();
            })
    }

  getServices() {
    this.errorMessage = '';
    this.loading = true;
    this.dataService.getServices(this.credentials.branch).subscribe((ui: any[]) => {
      this.serviceList = ui;
      if (ui.length > 0)
        this.credentials.service = ui[0].ServiceValue;
      this.loading = false;
    },
      (err: any) => {
        this.loading = false;
        this.errorMessage = this.dataService.processError(err);
      });
  }

    login(credentials: Credentials) {
        this.errorMessage = '';
        this.agentLogin = null;
        this.loading = true;
        if (this.resetp) {
            this.dataService.resetPassword(this.credentials).subscribe((ui: any) => {
                this.loading = false;
                this.resetp = false;
                this.rptext = 'A message for Password Reset will be sent to your email address. Please follow the provided instructions.';
            },
                (err: any) => {
                    this.loading = false;
                    this.errorMessage = this.dataService.processError(err);
                });
        }
        else {
            this.dataService.login(this.credentials).subscribe((ui: UserCredentials) => {
                this.loading = false;
                this.dataService.userData = ui;
                this.dataService.loggedIn = true;
                this.router.navigate(['home']);
                //this.onLogin.emit(ui);
            },
                (err: any) => {
                    this.loading = false;
                    if (err.status == 403) {
                        this.errorMessage = 'Sorry, we couldn’t log you in. Please check if you have already created an account and have entered the correct email or password.';
                    }
                    else if (err.status == 409) {
                        this.agentLogin = this.sanitizer.bypassSecurityTrustUrl(this.dataService.siteURL);
                    }
                    else
                        this.errorMessage = this.dataService.processError(err);
                });
        }
    }

    registration(e) {
        e.stopPropagation();
        e.preventDefault();

        this.router.navigate(['registration']);
        return false;
    }

    resetPassword(e) {
        e.stopPropagation();
        e.preventDefault();

        if (!this.credentials.username) {
            this.errorMessage = 'Please enter email address associated with your account. A message for Password Reset will be sent to this address if it has been registered with Meest';
            this.resetp = true;
        }
        else {
            this.resetp = true;
            this.login(null);
        }
        //this.router.navigate(['registration']);
        return false;
    }

    modalEstimator(ev: any) {
        ev.stopPropagation();
        ev.preventDefault();
        this.isModalShown = true;
    }
}
