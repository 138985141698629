import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { TreeModule } from 'angular-tree-component';
import { AgGridModule } from "ag-grid-angular";
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { MyDatePickerModule } from 'mydatepicker';
import { CodeInputModule } from 'angular-code-input';
//import { ScanditConfigProvider, ScanditPickerComponent, ScanditService } from "scandit-sdk-angular";

import { routes } from './app.router';
import { TokenInterceptor } from './token-interceptor.service';
import { MessengerService } from './messenger.service';
import { QuestionService } from './question.service';
import { DataService } from './data.service';
import { AppComponent, NavigationGuard } from './app.component';
import { FaderComponent } from './fader.component';
import { DeclarationEditor } from './declaration-editor.component';
import { NumericEditor } from './numeric-editor.component';
import { TypeaheadEditor } from './typeahead-editor.component';
import { DropdownEditor, DropdownRenderer } from './dropdown-editor.component';
import { NumberOnlyDirective } from './numbersOnly.directive';
import { AddressAutocompleteComponent } from './address-autocomplete.component';
import { LoginComponent } from './login/login.component';
import { GuestComponent } from './login/guest.component';
import { SenderReceiverComponent } from './sender-receiver.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-form/dynamic-form-question.component';
import { TreeReactiveComponent } from './dynamic-form/tree.component';
import { NewParcel2Component } from './new-parcel/new-parcel2.component';
import { ModalMapComponent } from './new-parcel/modal-map.component';
import { MapComponent } from './new-parcel/map.component';
import { DeliverySelectionComponent } from './new-parcel/delivery-selection.component';
//import { EstimatorComponent, ModalEstimatorComponent } from './new-parcel/estimator.component';
import { SignatureComponent } from './new-parcel/signature.component';
import { InvoiceComponentComponent } from './invoice-component/invoice-component.component';
import { HomeComponent } from './home/home.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentResultComponent } from './payment/payment-result.component';
import { DiscountComponentComponent } from './discount-component/discount-component.component';
import { RegistrationComponent } from './registration/registration.component';
import { NewMoneyComponent } from './new-money/new-money.component';
import { DeliverySelectionMoneyComponent } from './new-money/delivery-selection-money.component';
import { PopupEstimatorComponent } from './popup-estimator/popup-estimator.component';

@NgModule({
  declarations: [
        AppComponent, NumericEditor, DropdownEditor, DropdownRenderer, DeclarationEditor, TypeaheadEditor, NumberOnlyDirective,
        DynamicFormComponent, DynamicFormQuestionComponent, TreeReactiveComponent, FaderComponent, AddressAutocompleteComponent,
      LoginComponent, GuestComponent, NewParcel2Component, InvoiceComponentComponent, HomeComponent, PaymentComponent, PaymentResultComponent, DiscountComponentComponent, RegistrationComponent,
        ModalMapComponent, MapComponent, DeliverySelectionComponent, SenderReceiverComponent, //EstimatorComponent, ModalEstimatorComponent,
      NewMoneyComponent, DeliverySelectionMoneyComponent, SignatureComponent, PopupEstimatorComponent
    //ScanditPickerComponent
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, ReactiveFormsModule, HttpClientModule, FormsModule,
    AgGridModule.withComponents([NumericEditor, DropdownEditor, DropdownRenderer, DeclarationEditor]),
      ButtonsModule.forRoot(), AccordionModule.forRoot(), BsDatepickerModule.forRoot(), TimepickerModule.forRoot(), ModalModule.forRoot(), TooltipModule.forRoot(), CarouselModule.forRoot(), TypeaheadModule.forRoot(),
      TreeModule, AngularMultiSelectModule, MyDatePickerModule, CodeInputModule, routes
  ],
    providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }, DatePipe, DataService, MessengerService, QuestionService, NavigationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    //ScanditService, ScanditConfigProvider("ATHcQwy2EPy2IbZGcTx9VmwTvCL0BrRJ6FGteVp3ZxgtIZDtdEsL2ncfHYWDe9HZEnTNPh92ySykV/ujBElgr4YY3qBbKZq7eUl/EVkVpnfoG1EXQQaRdWcGt0Ty6aPlpmqxsvbuSZjT7pTnSZli0DEYF/mRxnpkVCoe/uxrTMNKgyK2KsSzOZIQiQxZy2znRJbAGRyKqJU2PmWAxULPt5IGE2VpazKRAUo4Gw3njftVaVkFcdLYDJDN/cYD/W6qr4tbOogzvH9Jj0L1bm25FNDkPa72emcXLfQ8qoHIIs7e7IJ+d2C6qbZSJboUvRX+X7S0iKIi59Md1jUv4266vdSjxlleQ9E4nYoIEsgfjBHwUkD7ReQUjfKuAfUID+3Tuk47WMONqz63exyjdcH6uSYxOzofF+SQROIH9P7KaQVtHf9uB2mPZWZkeyRjx7LABqeTtO7e4HQnItM+GOPji5UnHiAZ3Hsm0e28r848DQXzDmU2DyfoPbKwCSe+8LB7xsvDUguWLdffkjaTh8f98sjUCcPXCNVKLphaCrB3I82XwGCvLmgbOeTd6YmRiQvzjdj4Z/jnnasWK4rKg58uizh8Ax5NG8jl/JomF4LOHmgupMKHvaF6W5JQdn350FlkGtNAdi1pwVmm9EqtQE2Jb40mpARzP5uQQze2owQYxEolCXo4EjNNM2oOEg8hoI0Qnqa5SvzEaqSar60fxES/S9GBorAfQ9FXOikmEmMvJ5YPV+MDNzEFHqzH4gVN+ymnMQXbpnQgS2B/Nioee/l8k6UcVWi0wMNbnM8/", "/MeestPortal/assets")
  ],
    entryComponents: [ModalMapComponent, TypeaheadEditor],
  bootstrap: [AppComponent]
})
export class AppModule { }
