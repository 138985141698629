import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, EventEmitter, OnChanges, SimpleChange  } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, zip } from 'rxjs';
import * as Card from 'card';

import { CreditCard, CreditCardResponse } from './creditCard';
import * as FileSaver from 'file-saver';
declare var CollectCheckout: any;

import { DataService } from './../data.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styles: [`
      .newparcel {
        margin: 10px 10px 10px 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-y: auto;
      }
      .roundBorder {
        border: 1px solid black;
        border-radius: 5px;
        margin-top: 8px;
      }
        .pl0 { padding-left: 0 !important }
        .jp-card-container { margin: 0 !important }
        input.jp-card-invalid
        {
            border-color: red;
        }
        input.jp-card-valid
        {
            border-color: green;
        }
        label { padding: 7px 0 0 15px; }
        .ccApproved span { color:forestgreen;font-weight:bold; }
        .ccDeclined span { color:red;font-weight:bold; }
        .center-button {
            margin: 30px auto;
            display: block;
            min-width: 158px;
        }

        .font-montserrat {
            font-family: Montserrat, "Open Sans", Verdana, Tahoma !important;
        }
        .interac {
            width: 40px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
}
  `]

})
export class PaymentComponent implements OnInit {
  @Input() parcelid: number;
    parcel: any;
    isMoney: number = 0;
    @Input() paymet: string;
    @Input() useBillingAddress: boolean = false;
  payMethods: any[];
  paycode: string;
    billAddress: any;
  errorMessage: string;
  panelClass: string = 'col-sm-12 col-md-12 col-lg-8';

  private baseURL: string;
  ccjs: any;
  cc: CreditCard = new CreditCard();
  ccresp: CreditCardResponse = new CreditCardResponse();
  paid: boolean;
  loading: boolean;
    cancelText: string = 'Cancel';
    payText: string = 'Pay';
    @Output() onPaid = new EventEmitter<string>();

    @ViewChild('ccNum', { static: false }) elNum: ElementRef;
    @ViewChild('ccName', { static: false }) elName: ElementRef;
    @ViewChild('ccExp', { static: false }) elExp: ElementRef;
    @ViewChild('ccCVC', { static: false }) elCVC: ElementRef;

    @ViewChild('smModal', { static: false }) smModal;
    @ViewChild('smModalEdited', { static: false }) smModalEdited;
    @ViewChild('smModalMoney', { static: false }) smModalMoney;
    @ViewChild('smModalWarn', { static: false }) smModalWarn;

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        if (!this.paymet) {
            this.route.paramMap.subscribe(params => {
                this.parcelid = Number(params.get('id'));
                var ism = params.get('isMoney');
                if (ism != null)
                    this.isMoney = Number(ism);

                this.init();
            });
        }
        else {
            this.paycode = this.paymet;
            this.init();
        }
    }

    init() {
        this.cc.Amount = 0;
        this.paid = false;
        this.loading = false;
        this.cancelText = 'Cancel';

        if (!this.dataService.userData)
            this.dataService.userData = {
                ID: this.isMoney, Branch: { id: 1, currency: 'CAD' }, UserData: { u_type: 'CUSTOMER' } };
        this.errorMessage = '';
        this.dataService.getSingleParcel(this.parcelid, this.isMoney > 0).subscribe((res: any) => {
            this.parcel = res;
            this.cc.Amount = this.isMoney ? this.parcel.TotalCustomerOwe_CAD : this.parcel.cust_own;
            this.cc.UserInvoice = this.parcel.code_a;

            if (this.isMoney > 1) {
                this.paycode = 'PSI';
                //this.doPayment();   //  Do this on the server side when we receive SUCCESS callback
                this.smModalMoney.show();
                return;
            }

            if (!this.paymet) {
                if (!this.dataService.userData.Branch)
                    this.dataService.userData.Branch = this.dataService.branches.find(x => x.id == this.parcel.branchID);

                if (!this.dataService.isGuest && (this.dataService.agent_drop && this.dataService.userData.UserData.u_type == 'CUSTOMER')) {
                    this.dataService.agent_drop = false;
                    this.payMethods = [{ Code: 'AGENT_DROPOFF', Method: "Pay at Agent's Location" }];
                    //if (this.dataService.isGuest)
                    //    this.payMethods.push({ Code: 'ONLINE_PAYMENT', Method: "Credit Card" });
                    this.payText = 'Print Confirmation';
                    this.paycode = this.payMethods[0].Code;
                    this.methodChange(null);
                }
                else
                    this.dataService.getPaymentMethods(this.isMoney ? 'MONEY' : 'PARCEL').subscribe((ui: any[]) => {
                        this.payMethods = ui;
                        //this.payMethods.forEach(x => x.Method = this.sanitizer.bypassSecurityTrustHtml(x.Method));
                        if (ui.length > 0) {
                            this.paycode = ui[0].Code;
                            this.methodChange(null);
                        }
                    });
            }
            else
                this.methodChange(null);
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }

    methodChange(evt) {
        if (!this.paymet)
            this.panelClass = this.paycode == 'ONLINE_PAYMENT' ? 'col-sm-12 col-md-12 col-lg-8 newparcel sunken' : 'col-sm-12 col-md-12 col-lg-8 newparcel sunken';
        else
            this.panelClass = '';

    setTimeout(() =>
      this.ccjs = this.paycode == 'ONLINE_PAYMENT' ? new Card({
        form: '#ccForm',
        container: '.card-wrapper'
      }) : null
    );
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        let changedProp = changes['paymet'].currentValue;
        if (changedProp) {
            this.paycode = changedProp;
            this.methodChange(null);
        }
    }

  doPayment() {
    this.errorMessage = '';
    this.cc.Method = this.paycode;
    if (this.paycode == 'ONLINE_PAYMENT') {
      //this.cc.Name = "Tester";
      //this.cc.Number = "4242424242424242";
      //this.cc.Expiration = "1708";
      this.cc.Number = this.cc.Number.replace(/\s/gi, '');
      this.cc.Currency = 'CAD';

      this.errorMessage = "";
    }
    else if (this.paycode == 'INTERAC') {
        if (this.isMoney) {
            this.loading = true;
            this.dataService.directPay({ id: this.parcelid, Amount: this.parcel.TotalCustomerOwe_CAD }).subscribe((res: any) => {
                this.loading = false;
                if (res.Status == 'AVAILABLE') {
                    window.location.assign(res.PaymentURL);
                }
                else
                    this.errorMessage = 'Payment failed - ' + res.status;
            },
                (err: any) => {
                    this.errorMessage = this.dataService.processError(err);
                    this.loading = false;
                });
        }
        else
            window.location.assign(`${this.dataService.siteURL}Money/DoMobilePayment/${this.parcelid}?partial=${this.dataService.userData.Branch.id}-${this.isMoney ? 'MONEY' : 'PARCELS'}`);
        return;
    }

    if (this.paycode == 'AGENT_DROPOFF') {
        this.dataService.agent_drop = false;
        this.paymentcomplete();
        return;
    }

    this.loading = true;
      this.dataService.savePaymentGuest(this.parcelid, this.cc, this.useBillingAddress, this.useBillingAddress ? this.billAddress : null).subscribe((res: CreditCardResponse) => {
      this.ccresp = res;
      this.paid = true;
        this.loading = false;

        if (this.cc.CVC)
            this.cc.CVC = ("000" + this.cc.CVC).slice(-3);

        if (this.paycode != 'ONLINE_PAYMENT') {
            this.createCPShipment();
            this.onPaid.emit('label');
        }
        else if (res.isApproved) {
            this.cancelText = 'Print Label';
            this.onPaid.emit('label');
        }
        else {
            this.cancelText = 'Try Again';
            this.onPaid.emit('error');
        }
    },
      (err: any) => {
        this.errorMessage = this.dataService.processError(err);
        this.loading = false;
      });
  }

  isValid() {
    return this.cc.Amount > 0 && this.checkValid(this.elNum) && this.checkValid(this.elExp) && this.checkValid(this.elCVC)
  }

    disabledPay() {
        return (this.paid && this.ccresp.isApproved) || (this.paycode == 'ONLINE_PAYMENT' && (!this.isValid() || this.loading) || (this.paycode != 'ONLINE_PAYMENT' && this.paycode != 'INTERAC' && this.loading));
    }

  private checkValid(el: ElementRef) {
    if (!el)
      return false;
    for (let c of el.nativeElement.classList) {
      if (c === 'jp-card-valid') return true;
    }
    return false;
    }

    usPayment() {
        //let param = {
        //    lineItems: [{
        //        sku: "0001",
        //        quantity: 1
        //    }],
        //    type: "sale",
        //    collectShippingInfo: true,
        //    customerVault: {
        //        addCustomer: true
        //    },
        //    successUrl: `${this.dataService.siteURL}Agent/CheckoutPayment/?transid={TRANSACTION_ID}`,
        //    cancelUrl: `${this.dataService.siteURL}Agent/CheckoutPayment/`,
        //    receipt: {
        //        showReceipt: true,
        //        redirectToSuccessUrl: true
        //    }
        //};

        //CollectCheckout.redirectToCheckout(param)
        //.then((error) => {
        //    console.log(error);
        //});
        //let param = `security_key=m7kSPr632KdCfJdkADGNP5JfR5U33ZSX&ccnumber=${this.cc.Number}&ccexp=${this.cc.Expiration}&cvv=${this.cc.CVC}&amount=${this.cc.Amount}&firstname=Tester&address1=1 Main St.&zip=12345`;
        this.loading = true;
        this.dataService.usPayment(this.cc).subscribe((ui: any) => {
            this.loading = false;
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
                this.loading = false;
            });
    }

  cancelOK() {
      if (this.cancelText.startsWith('Print '))
          this.createCPShipment();
      else if (this.cancelText == 'Try Again') {
          this.paid = false;
          this.cancelText = 'Cancel';
          this.cc = new CreditCard();
          this.cc.Amount = this.isMoney ? this.parcel.TotalCustomerOwe_CAD : this.parcel.cust_own;
          this.cc.UserInvoice = this.parcel.code_a;
          this.methodChange(null);
      }
    else
      setTimeout(() => { this.router.navigate(['home']); });
    }

    createCPShipment() {
        if (this.isMoney) {
            this.paymentcompleteImpl();
            return;
        }

        this.loading = true;
        this.dataService.getCPShipment(this.parcelid).subscribe((cph: any) => {
            if (!cph) {
                this.loading = false;
                this.paymentcomplete();
            }
            else {
                this.dataService.createCPShipment(cph).subscribe((ui: any) => {
                    this.loading = false;
                    if (ui.Error)
                        this.errorMessage = ui.Message;
                    else {
                        this.paymentcomplete();
                    }
                },
                    (err: any) => {
                        this.errorMessage = this.dataService.processError(err);
                        this.loading = false;
                    });
            }
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
                this.loading = false;
            });
    }

    paymentcomplete() {
        this.dataService.sendDWShipment(this.parcelid).subscribe((ui: any) => {
            this.paymentcompleteImpl();
        });
    }

    paymentcompleteImpl() {
        this.loading = true;
        if (this.dataService.userData.UserData.u_type != 'CUSTOMER') {
            this.confirmClosed();
            return;
        }

        this.dataService.sendCompleteEmail(this.parcelid, this.parcel.status, this.isMoney > 0).subscribe((response) => {
            this.loading = false;
            setTimeout(() => {
                if (this.isMoney)
                    this.smModalMoney.show();
                //if (this.parcel.status == 'PREPAID')
                //    this.smModalEdited.show();
                //else
                //    this.smModal.show();
                else {
                    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    if (this.paycode == 'AGENT_DROPOFF') {
                        //if (this.dataService.isMobileDevice) {
                            //if (!isSafari)
                            //    window.open(`${this.dataService.siteURL}Agent/PrintParcelNarrow/${this.parcelid}?partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 0}-${this.dataService.userData.ID}`, '_blank');
                            this.router.navigate(["invoice", this.parcelid, 0]);
                            //this.router.navigate(['home']);
                        //}
                        //else
                        //    this.dataService.downloadInvoiceAsync(this.parcelid, this.isMoney)
                        //        .subscribe((response) => { // download file
                        //            var blob = new Blob([response], { type: 'application/pdf' });
                        //            var filename = `${this.cc.UserInvoice}.pdf`;
                        //            FileSaver.saveAs(blob, filename);
                        //            setTimeout(() => { this.router.navigate(['home']); });
                        //        });
                    }
                    else {
                        //if (this.dataService.isMobileDevice) {
                            if (!isSafari)
                                window.open(`${this.dataService.siteURL}Agent/PrintPostLabel/${this.parcelid}?partial=${this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 0}-${this.dataService.userData.ID}`, '_blank');
                            this.smModalWarn.show();
                            ////////this.router.navigate(["invoice", this.parcelid, 0]);
                            //this.router.navigate(['home']);
                        //}
                        //else
                        //    this.dataService.getCPLabel({ ShipmentID: this.parcelid })
                        //        .subscribe((response) => { // download file
                        //            var lbl = new Blob([response], { type: response.type });
                        //            var lblfilename = `${this.cc.UserInvoice}_ShippingLabel.pdf`;
                        //            FileSaver.saveAs(lbl, lblfilename);
                        //            setTimeout(() => { this.router.navigate(['home']); });
                        //        });
                    }
                }
            });
        },
            (err: any) => {
                this.errorMessage = 'Confirmation email is not sent';
                this.loading = false;
            });
    }

    printPostLabel() {
        this.router.navigate(["invoice", this.parcelid, 0]);
    }

    confirmClosed() {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari || this.paycode == 'AGENT_DROPOFF')
            setTimeout(() => { this.router.navigate(['home']); });
        else
            //zip(this.dataService.downloadInvoiceAsync(this.parcelid), this.dataService.getCPLabel({ ShipmentID: this.parcelid }))
            this.dataService.downloadInvoiceAsync(this.parcelid, this.isMoney > 0)
                .subscribe((response) => { // download file
                    var blob = new Blob([response], { type: 'application/pdf' });
                    var filename = `${this.cc.UserInvoice}.pdf`;
                    FileSaver.saveAs(blob, filename);

                    //if (response[1].type != 'text/plain') {
                    //    var lbl = new Blob([response[1]], { type: response[1].type });
                    //    var lblfilename = `${this.cc.UserInvoice}_ShippingLabel.pdf`;
                    //    FileSaver.saveAs(lbl, lblfilename);
                    //}

                    setTimeout(() => { this.router.navigate(['home']); });
                });
    }
}
