import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

declare var $: any;
import '../../../node_modules/jquery/dist/jquery.min.js';
import "../../../node_modules/jsignature/src/jSignature.js";
//import "../../../node_modules/jsignature/libs/jSignature.min.js";

import { DataService } from './../data.service';
import { MessengerService } from './../messenger.service';

@Component({
  selector: 'app-invoice-component',
  templateUrl: './invoice-component.component.html',
    styleUrls: ['./invoice-component.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class InvoiceComponentComponent implements OnInit {
  errorMessage: string;
  @Input() invoiceid: number;
  parcelCode: string;
  public parcel: any;
    dataInvoice: any = '';
  public isMoney = false;
  loaded = false;
  //showFaderStr: string = 'false';
    @ViewChild('invoiceContainer', { static: false }) invoiceContainer: ElementRef;
  @Output() onSigned = new EventEmitter<number>();
  sigButton: any;
    @ViewChild('signModal', { static: false }) signModal;
    topHeader = '<tr><td colspan="3" class="InvoiceInfoName" style="font-size: 1.2em; text-align:center">SHIPMENT SUMMARY</td></tr><tr><td colspan="3" class="InvoiceInfoName" style="padding-bottom: 10px; font-size: 1.2em; text-align:center">Thank you for shipping with MEEST</td></tr>';
    @ViewChild('gbModal', { static: false }) gbModal;
    @ViewChild('guestModal', { static: false }) guestModal;

    constructor(private dataService: DataService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, private messengerService: MessengerService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      console.log(params.get('id'));
      this.invoiceid = Number(params.get('id'));
        var ism = params.get('isMoney');
        if (ism != null)
            this.isMoney = ism == '1';

      this.errorMessage = '';
        this.dataService.getSingleParcel(this.invoiceid, this.isMoney).subscribe((res: any) => {
            this.parcel = res;
            this.parcelCode = res.code_a;
            if (!res.CN23_Signature || res.CN23_Signature == '')
                this.topHeader = '<tr><td colspan="3" class="InvoiceInfoName" style="font-size: 1.5em; text-align:left; font-weight:bold">REVIEW AND SIGN</td></tr><tr><td colspan="3" class="InvoiceInfoName" style="padding-bottom: 10px; font-size: 1.2em; text-align:center"><br/></td></tr>';
            this.loadInvoice();
      },
        (err: any) => {
          this.errorMessage = this.dataService.processError(err);
        });
    });
  }

  loadInvoice() {
    this.loaded = false;
    //this.showFaderStr = 'false';
    this.errorMessage = '';
      this.dataService.getInvoice(this.invoiceid, this.isMoney).subscribe((ui: string) => {
      this.loaded = true;
          this.dataInvoice = this.sanitizer.bypassSecurityTrustHtml(ui);
          if (this.dataService.isGuest)
              this.dataService.guestInvoice = true;

      setTimeout(() => {
        this.InitScripts(this.dataService.siteURL);

        var t = $('#invoiceContainer').text();
        let scripts = this.invoiceContainer.nativeElement.getElementsByTagName('script') as any[];
        //var js = '';
        for (var n = 0; n < scripts.length; n++) {
          //js += scripts[n].innerHTML;
          try {
            eval(scripts[n].innerHTML); //run script inside div
            //eval(js); //run script inside div
          }
          catch (Error) { }
        }

        this.sigButton = null;
        let invoice = this.invoiceContainer.nativeElement.getElementsByTagName('table')[0];
        var inps = this.invoiceContainer.nativeElement.getElementsByTagName('input');
        if (inps) {
          for (var i = 0; i < inps.length; i++) {
            if (inps[i].id == 'ParcelSignature_' + this.invoiceid) {
              this.sigButton = inps[i];
              break;
            }
          }
          }

          $('#inv' + this.invoiceid + '>tbody').prepend(this.topHeader);

        //if (this.dataService.isMobileDevice) {
          //if ((this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 1) != 1) {
          //    var self = this;
          //    var clone = document.getElementById('invNavButtonsSource').cloneNode(true);

          //    var tr = document.createElement("tr");
          //    tr.setAttribute('align', 'center');
          //    while (clone.firstChild) {
          //        if (clone.firstChild.nodeName && clone.firstChild.nodeName.toUpperCase() == "BUTTON") {
          //            var td = document.createElement("td");
          //            td.setAttribute('valign', 'top');
          //            tr.appendChild(td);
          //            var but = td.insertBefore(clone.firstChild, null);

          //            but.addEventListener('click', but.textContent.indexOf('CN23') >= 0 ? function () { self.printCN23(self); } : function () { self.printLabel(self); });
          //        }
          //        else
          //          clone.removeChild(clone.firstChild);
          //    }
          //    document.getElementById('invNavButtons').parentNode.appendChild(tr);
          //    if ((this.dataService.userData.Branch ? this.dataService.userData.Branch.id : 1) == 2) {
          //        if (this.parcel.skid)
          //            $('.remove-us').remove();
          //        else
          //          document.getElementById('invNavButtons').remove();
          //    }
          //}

          if (this.dataService.isGuest)
              this.guestModal.show();
      });
    },
      (err: any) => {
        this.errorMessage = this.dataService.processError(err);
        this.loaded = true;
      });
  }

    gohomeback(isProceed: boolean) {
        if (this.dataService.isGuest)
            this.guestModal.hide();
        else
            this.gbModal.hide();
        if (isProceed) {
            //if (this.parcel.status == "INVOICED" && this.parcel.paymentID == 0) {
            //    this.errorMessage = '';
            //    this.dataService.deleteParcel(this.parcel.id).subscribe((res: any) => {
            //        this.router.navigate(["home"]);
            //    },
            //        (err: any) => {
            //            this.errorMessage = this.dataService.processError(err);
            //        });
            //}
            //else
            this.router.navigate(["newParcel"]);
        }
        else
            this.router.navigate(["home"]);
    }

    printLabel(self: any) {
        if (self.dataService.userData.Branch.id == 4)
            window.open(`${this.dataService.siteURL}Agent/PrintLabel/${self.invoiceid}?is4x8=false&partial=${self.dataService.userData.Branch ? self.dataService.userData.Branch.id : 0}-${self.dataService.userData.UserData.id}`, '_blank');
        else
            window.open(`${self.dataService.siteURL}Agent/PrintPostLabel/${self.invoiceid}?partial=${self.dataService.userData.Branch ? self.dataService.userData.Branch.id : 0}-${self.dataService.userData.ID}`, '_blank');
    }

    printCN23(self: any) {
        window.open(`${self.dataService.siteURL}Agent/PrintParcel/${self.invoiceid}?cn23=1&partial=${self.dataService.userData.Branch ? self.dataService.userData.Branch.id : 0}-${self.dataService.userData.UserData.id}`, '_blank');
    }

  triggerSignature() {
    if (this.sigButton)
      this.sigButton.click();
  }

  InitScripts(siteUrl) {
    var _this00 = this;

    if (this.parcel.CN23_Signature) {
      var data = this.parcel.CN23_Signature;
      $('#signature_area_' + this.parcel.id).attr('src', data);
        $('#signature_area_' + this.parcel.id).show();
        $('#ParcelSignature_' + this.parcel.id).hide();
    }
    //$('#ParcelSignature_' + this.parcel.id).hide();

    $('#signature-dialog').dialog({
      autoOpen: false,
      modal: true,
      width: 720,
      resizable: false,
      height: 'auto',
      open: function (event, ui) { $(".ui-dialog-titlebar-close", ui.dialog | ui).hide(); }
    });

    $('#ParcelInvoceButton').off('click').on('click', function (e) {
      var id = $('#invoiceid').val();
      window.open(siteUrl + 'Agent/PrintParcelNarrow/' + id, '_blank');
      return false;
    });

    $('#ParcelLabelButton').off('click').on('click', function (e) {
      var id = $('#invoiceid').val();
      window.open(siteUrl + 'Agent/PrintLabel/' + id, '_blank');
      return false;
    });

      $('#start-over').off('click').on('click', function (e) {
          _this00.gbModal.show();
          return false;
      });

      $('#inv-newparcel').off('click').on('click', function (e) {
          _this00.router.navigate(["newParcel"]);
          return false;
      });

      $('#inv-printlabel').off('click').on('click', function (e) {
          _this00.printLabel(_this00);
          return false;
      });

      $('#inv-ordhist').off('click').on('click', function (e) {
          _this00.router.navigate(["home"]);
          return false;
      });

      $('#inv-printinvoice').off('click').on('click', function (e) {
          window.open(`${_this00.dataService.siteURL}Agent/PrintParcel/${_this00.invoiceid}?partial=${_this00.dataService.userData.Branch ? _this00.dataService.userData.Branch.id : 0}-${_this00.dataService.userData.UserData.id}`, '_blank');
          return false;
      });

    var Signature_Add = function (sigdata) {
      var id = $('#invoiceid').val();
      var parCode = $('#parcelCode').val();
      var url = siteUrl + 'Agent/AddSignature';
      $.ajax({
        type: "POST",
        beforeSend: function (request) {
          request.setRequestHeader("TargetService", "MeestPortal");
        },
        url: url,
        data: { id: id, signature: sigdata, action: window.opener ? 'email' : '' },
        success: function (data) {
          $('#signature_area_' + id).attr('src', sigdata);
          $('#signature_area_' + id).show();
          $('#ParcelSignature_' + id).hide();
          $('#ParcelSignatureDate_' + id).text(data.date);
          $("#ParcelInvoceButton").attr("disabled", false);
          $("#ParcelLabelButton").attr("disabled", false);

          //_this00.onSigned.emit(id);
          _this00.messengerService.statusChanged({ topic: 'InvoiceSigned', value: id, text: parCode });
        }
      });
    }

    $('[id^=ParcelSignature]').off('click').on('click', function (e) {
      var id = this.id.split('_')[1];
      $("#signature-dialog").dialog('option', 'title', 'Signature');
      $("#signature-dialog").dialog('open');

      // Initialize jSignature
      var $sigdiv = $("#signature").jSignature({ 'UndoButton': false });
      $("#signature").jSignature('reset');

        $("#clickSignature").attr("disabled", true);

        $("#signature").bind('change', function (e) {
            $("#clickSignature").attr("disabled", false);
        });

      $('#clickSignature').off('click').on('click', function (e) {
          $("#signature").unbind('change');
        // Get response of type image
        var data = $sigdiv.jSignature('getData', 'default');
        $("#signature-dialog").dialog('close');
        $("#signature").empty();
        Signature_Add(data);
      });
      $('#cancelSignature').off('click').on('click', function (e) {
          $("#signature").unbind('change');
        $("#signature-dialog").dialog('close');
        $("#signature").empty();
      });
      return false;
    });
  }
}
